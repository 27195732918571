import {
  FETCH_CREWS,
  FETCH_CREWS_SUCCESS,
  FETCH_CREWS_FAILURE,
  FETCH_CREW_BY_PARENT,
  FETCH_CREW_BY_PARENT_SUCCESS,
  FETCH_CREW_BY_PARENT_FAILURE,
  FETCH_ADONIS_CREWS,
  FETCH_ADONIS_CREWS_SUCCESS,
  FETCH_ADONIS_CREWS_FAILURE
} from '../actions/crews'

const defaultState = {
  page: 1,
  limit: 20,
  offset: 0,
  orderBy: 'name',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function crewsReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CREW_BY_PARENT:
    case FETCH_CREWS: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case FETCH_CREWS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        entities: action.normalized.entities.crew || {}
      }
    }
    case FETCH_CREW_BY_PARENT_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        parentTeamId: action.ParentId,
        entities: { ...state.entities, [action.ParentId]: action.response.entities }
      }
    }
    case FETCH_CREWS_FAILURE:
    case FETCH_CREW_BY_PARENT_FAILURE:
    case FETCH_ADONIS_CREWS: {
      return { ...state, isFetching: true }
    }
    case FETCH_ADONIS_CREWS_SUCCESS: {
      return { ...state, isFetching: false }
    }
    case FETCH_ADONIS_CREWS_FAILURE: {
      return { ...state, isFetching: false, error: action.error }
    }
    default:
      return state
  }
}
