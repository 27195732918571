import React from 'react'
import { FormGroup, HelpBlock } from 'react-bootstrap'
import VirtualizedSelect from 'react-virtualized-select'
import MaterialIcon from 'material-icons-react'
import { createFilter } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async'
import ApiRequest from '../../helpers/apiHelper'

export const renderValue = (val) =>
  val || (
    <small className="text-muted">
      <i>missing</i>
    </small>
  )

export const renderAddress = (address) => {
  if (!address) return renderValue()
  let addressString = ''
  const missingFields = []

  if (address.street) {
    addressString = address.street
  } else {
    missingFields.push('Street')
  }

  if (address.address2) {
    if (addressString.length > 0) {
      addressString = `${addressString}, ${address.address2}`
    } else {
      addressString = address.address2
    }
  }

  if (address.postcode) {
    if (addressString.length > 0) {
      addressString = `${addressString}, ${address.postcode}`
    } else {
      addressString = address.postcode
    }
  } else {
    missingFields.push('Postcode')
  }

  if (address.address3) {
    if (addressString.length > 0) {
      addressString = `${addressString}, ${address.address3}`
    } else {
      addressString = address.address3
    }
  } else {
    missingFields.push('City')
  }

  if (address.country) {
    if (addressString.length > 0) {
      addressString = `${addressString}, ${address.country}`
    } else {
      addressString = address.country
    }
  } else {
    missingFields.push('Country')
  }

  if (missingFields.length > 0) {
    addressString = (
      <span>
        {addressString}{' '}
        <small className="text-muted">
          <i>missing {missingFields.join(', ')}</i>
        </small>
      </span>
    )
  }

  return addressString
}

export const renderCountrySelect = (countryCode, onChange, required = true, disabled = false) => {
  let isLoading = true
  const loadCountries = () =>
    ApiRequest('/api/countries').then((res) => {
      isLoading = false
      return res.json.map(entity => ({
        // TODO refactor: use entity.code instead, backend: no longer use getCountryCode
        value: entity.label,
        label: entity.label
      }))
    })

  const valid = countryCode || !required

  return (
    <FormGroup validationState={valid ? null : 'error'}>
      <AsyncSelect
        isClearable
        name="form-field-country"
        value={countryCode ? { value: countryCode, label: countryCode } : ''}
        filterOption={createFilter()}
        loadOptions={loadCountries}
        defaultOptions
        cacheOptions
        onChange={evt => onChange(evt ? evt.value : '')}
        required={required}
        isDisabled={disabled}
        isLoading={isLoading}
      />
      <HelpBlock className={valid ? 'hidden' : null}>
        <MaterialIcon size="16" icon="error" />
      mandatory field
      </HelpBlock>

    </FormGroup>
  )
}

const zipPlaces = {}

export const renderZipPlaceSelect = (value, country, placeholder, onChange, required = true, disabled = false) => {
  zipPlaces[country] = zipPlaces[country] || []
  const selectedZipPlace = value ? { value: value, label: value } : null

  const loadZipPlaces = () => {
    let loading = false

    if (country === undefined) {
      return Promise.resolve([])
    }

    // Avoid hitting the API more than once
    if (zipPlaces[country].length || loading) {
      return Promise.resolve(zipPlaces[country])
    }

    loading = true
    return ApiRequest(`/api/zipPlaces/${country}`).then((res) => {
      loading = false
      zipPlaces[country] = res.json.map(entity => ({
        value: entity.ZIP,
        zip: entity.ZIP,
        place: entity.Place,
        label: `${entity.ZIP} ${entity.Place}`
      }))
      return zipPlaces[country]
    })
  }

  // Disable form control if no valid country is given
  const isDisabled = (country === undefined || country.trim().length === 0) ? true : disabled

  return (
    <VirtualizedSelect
      async
      isClearable
      selectComponent={AsyncCreatableSelect}
      createOptionPosition="last"
      showNewOptionAtTop={false}
      allowCreateWhileLoading={false}
      formatCreateLabel={inputVal => `Use "${inputVal}"`}
      placeholder={placeholder}
      loadOptions={loadZipPlaces}
      filterOption={createFilter()}
      onChange={onChange}
      value={selectedZipPlace}
      required={required}
      isDisabled={isDisabled}
    />
  )
}
