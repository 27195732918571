import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import {Link, useLocation} from 'react-router-dom'
import { Image, Navbar, Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { USER_TOKEN, ROLES } from '../constants'
import { ModalTypes } from './shared/modal'
import {useNavigate} from "react-router";

const NavbarComp = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  function handleLogout() {
    props.modalsActions.showModal(ModalTypes.confirm, {
      question: 'Logout?',
      onConfirm: () => {
        props.userActions.logout().then(() => {
          localStorage.setItem(USER_TOKEN, null)
          props.modalsActions.hideModal();
          navigate('/');
        })
      }
    })
  }

  if (!props.user.loggedIn) {
    return (
      <Navbar fixedTop>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <Image src="/assets/images/logo.png" alt="logo" className="img-centered nav-logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            <LinkContainer to="/login">
              <NavItem>Login</NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }

  const { role } = props.user.user

  if (!props.user.user.Right) {
    return null
  }

  const { privileges } = props.user.user.Right

  return (
    <Navbar fixedTop collapseOnSelect>
      <Navbar.Header>
        <Link to="/">
          <Image src="/assets/images/logo.png" alt="logo" className="img-centered nav-logo" />
        </Link>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse >
        <Nav pullRight>
          { privileges.teams !== 0 && (
            <LinkContainer to="/teams"  active={location.pathname.startsWith('/teams')}>
              <NavItem>Teams</NavItem>
            </LinkContainer>
          )}
          { privileges.content !== 0 && (
            <LinkContainer to="/content" active={location.pathname.startsWith('/content')}>
              <NavItem>Content</NavItem>
            </LinkContainer>
          )}
          {role === ROLES.ADMIN ? (
            <Fragment>
              <LinkContainer to="/profiles" active={location.pathname.startsWith('/profiles')}>
                <NavItem>Crew Members</NavItem>
              </LinkContainer>
              <LinkContainer to="/customers" active={location.pathname.startsWith('/customers')}>
                <NavItem>Customers</NavItem>
              </LinkContainer>
              <LinkContainer to="/agencies" active={location.pathname.startsWith('/agencies')}>
                <NavItem>Agencies</NavItem>
              </LinkContainer>
              <LinkContainer to="/vessels" active={location.pathname.startsWith('/vessels')}>
                <NavItem>Vessels</NavItem>
              </LinkContainer>
              <LinkContainer to="/users" active={location.pathname.startsWith('/users')}>
                <NavItem>Users</NavItem>
              </LinkContainer>
              <LinkContainer to="/academy" active={location.pathname.startsWith('/academy')}>
                <NavItem>sea chefs Academy</NavItem>
              </LinkContainer>
            </Fragment>
          ) : null }
          <NavItem onClick={handleLogout}>Logout</NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

NavbarComp.propTypes = {
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired, // eslint-disable-line
  modalsActions: PropTypes.object.isRequired
}

export default NavbarComp
