import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

export const formType = 'FORM_MODAL'

class FormModal extends React.Component {
  constructor() {
    super()
    this.state = {
      show: true
    }
  }

  close = () => {
    this.setState({ show: false })
    this.props.onExited()
  }

  isFormValid = () => {
    const validationFunctionNotDefined = !this.formcomp.isValid
    return validationFunctionNotDefined ? true : this.formcomp.isValid()
  }

  submit = (evt) => {
    evt.preventDefault()

    const formValues = this.formcomp.formValues ? this.formcomp.formValues() : this.formcomp.state

    if (this.isFormValid()) {
      this.setState({ show: false })
      this.props.onSubmit(formValues)
      this.props.onExited()
    } else if (this.formcomp.touchAllFields) {
      this.formcomp.touchAllFields()
    }
  }

  show = () => this.setState({ show: true })

  render() {
    const { formcomp: Form } = this.props
    return (
      <Modal show={this.state.show} onHide={this.close} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{this.props.headline}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form onSubmit={this.submit} {...this.props.formprops} ref={(el) => { this.formcomp = el }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.submit} bsStyle="primary">{this.props.submitButtonText}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

FormModal.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  formcomp: PropTypes.func.isRequired,
  formprops: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string
}

FormModal.defaultProps = {
  headline: 'Form',
  submitButtonText: 'Save'
}

export default FormModal
