import React from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from 'material-icons-react'
import Select from 'react-select'
import { Form, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap'
import { checkIsEmail, isValidPassword } from '../../common/helpers/validators'
import ApiRequest from '../helpers/apiHelper'

class CreateUserForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: {
        value: '',
        touched: false
      },
      password: {
        value: '',
        touched: false
      },
      firstName: {
        value: '',
        touched: false
      },
      lastName: {
        value: '',
        touched: false
      },
      email: {
        value: '',
        touched: false
      },
      CustomerId: {
        value: '',
        touched: false
      },
      VesselIds: {
        value: [],
        touched: false
      },
      AgencyId: {
        value: '',
        touched: false
      },
      PIN: {
        value: '',
        touched: false
      }
    }
  }

  componentDidMount = async () => {
    const agencies =
      await ApiRequest('/api/agencies?orderBy=name&sort=asc').then(res => res.json.entities)
    const customers =
      await ApiRequest('/api/customers?orderBy=name&sort=asc').then(res => res.json.entities)
    const vessels =
      await ApiRequest('/api/vessels?orderBy=name&sort=asc').then(res => res.json.entities)
    this.setState({
      agencies, customers, vessels
    })
  }

  formValues = () => ({
    username: this.state.username.value,
    password: this.state.password.value,
    firstName: this.state.firstName.value,
    lastName: this.state.lastName.value,
    email: this.state.email.value,
    RightId: this.props.role.value,
    type: this.props.type,
    CustomerId: this.state.CustomerId.value,
    VesselIds: this.state.VesselIds && this.state.VesselIds.map && this.state.VesselIds.map(item => item.value),
    AgencyId: this.state.AgencyId.value.value
  })

  isValid = () => {
    const { firstName, lastName, username, email, password } = this.state

    if (!firstName.value || !lastName.value) {
      return false
    }


    if (!this.isLinkedValid()) {
      return false
    }

    if (!checkIsEmail(email.value)) {
      return false
    }

    return isValidPassword(password.value) && username.value.length > 0
  }

  touchAllFields = () => {
    this.setState({
      username: {
        touched: true,
        value: this.state.username.value
      },
      password: {
        touched: true,
        value: this.state.password.value
      },
      firstName: {
        touched: true,
        value: this.state.firstName.value
      },
      lastName: {
        touched: true,
        value: this.state.lastName.value
      },
      email: {
        touched: true,
        value: this.state.email.value
      },
      CustomerId: {
        touched: true,
        value: this.state.CustomerId.value
      },
      VesselIds: {
        touched: true,
        value: this.state.VesselIds.value
      },
      AgencyId: {
        touched: true,
        value: this.state.AgencyId.value
      }
    })
  }

  onValueChange = (event) => {
    const { name, value } = event.target

    this.setState({
      [name]: {
        ...this.state[name],
        value: value,
        touched: true
      }
    })
  }

  onLinkedChange = (value, label) => {
    const { type } = this.props

    if (type.value === 'ROLE_VESSEL') {
      this.setState({ [label]: value })
    } else {
      const field = this.state[label]
      field.value = value
      this.setState(field)
    }
  };

  onTouched = (event) => {
    const { name } = event.target

    this.setState({
      [name]: {
        ...this.state[name],
        touched: true
      }
    })
  }

  isFirstNameValid = () => (this.state.firstName.touched ? this.state.firstName.value !== '' : true)
  isLastNameValid = () => (this.state.lastName.touched ? this.state.lastName.value !== '' : true)
  isEmailValid = () => (this.state.email.touched ? (this.state.email.value && checkIsEmail(this.state.email.value)) : true)
  isUsernameValid = () => (this.state.username.touched ? this.state.username.value.length : true)
  isPasswordValid = () => (this.state.password.touched ? isValidPassword(this.state.password.value) : true)
  isLinkedValid = () => {
    const { AgencyId, CustomerId, VesselIds } = this.state

    switch (this.props.type.value) {
      case 'ROLE_AGENCY':
        return AgencyId.touched ? AgencyId.value !== '' : true
      case 'ROLE_CUSTOMER':
        return CustomerId.touched ? CustomerId.value !== '' : true
      case 'ROLE_VESSEL':
        return VesselIds.touched ? VesselIds.value.length !== 0 : true
      default:
        return true
    }
  }

  sortDropdownOptions = (array) => {
    if (array) {
      return array.sort((a, b) => {
        return a.label.localeCompare(b.label)
      }) 
    }
  }

  isPredefinedRoleSelected = () => {
    const { role } = this.state

    return role && role.value && role.value.predefined
  }

  getTypeValueByRoleName = (name) => this.userTypes.find((userType) => {
    return (userType.label.toLowerCase() === name.toLowerCase()) ||
           (userType.value === 'ROLE_AGENCY' && name.toLowerCase() === 'agency')
  })

  createLinkedOption = (options) => {
    return (
      options &&
      options.map((option) => {
        return { label: option.name, value: option.id }
      })
    )
  }

  getLinkedOptions = () => {
    const { crewmembers, vessels, customers, agencies } = this.state
    const { type } = this.props

    if (!type || !type.value) return { label: '', values: [] }

    switch (type.value) {
      case 'ROLE_AGENCY':
        return { label: 'AgencyId', values: this.createLinkedOption(agencies) }
      case 'ROLE_CUSTOMER':
        return { label: 'CustomerId', values: this.createLinkedOption(customers) }
      case 'ROLE_CREWMEMBER':
        return { label: 'PIN', values: this.createLinkedOption(crewmembers) }
      case 'ROLE_VESSEL':
        return { label: 'VesselIds', values: this.createLinkedOption(vessels) }
      default:
        return { label: '', values: [] }
    }
  }

  getLinkedValueForRole = () => {
    const { AgencyId, CustomerId, VesselIds } = this.state
    const { type } = this.props

    if (!type || !type.value) return ''

    switch (type.value) {
      case 'ROLE_CUSTOMER':
        return CustomerId.value
      case 'ROLE_AGENCY':
        return AgencyId.value
      case 'ROLE_VESSEL':
        return VesselIds.value
      default:
        return ''
    }
  }

  render() {
    const { username, password, PIN, firstName, lastName, email } = this.state
    const { type } = this.props
    const { label, values } = this.getLinkedOptions()
    const isMulti = type.value === 'ROLE_VESSEL'

    return (
      <Form>
        <FormGroup validationState={this.isFirstNameValid() ? null : 'error'}>
          <ControlLabel>First name</ControlLabel>
          <FormControl
            type="text"
            placeholder="First name"
            value={firstName.value}
            name="firstName"
            onChange={this.onValueChange}
            onBlur={this.onTouched}
          />
          <HelpBlock className={this.isFirstNameValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
            First name is required
          </HelpBlock>
        </FormGroup>
        <FormGroup validationState={this.isLastNameValid() ? null : 'error'}>
          <ControlLabel>Last name</ControlLabel>
          <FormControl
            type="text"
            placeholder="Last name"
            value={lastName.value}
            name="lastName"
            onChange={this.onValueChange}
            onBlur={this.onTouched}
          />
          <HelpBlock className={this.isLastNameValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
            Last name is required
          </HelpBlock>
        </FormGroup>
        <FormGroup validationState={this.isEmailValid() ? null : 'error'}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            autoComplete="email"
            placeholder="Email"
            value={email.value || ''}
            name="email"
            onChange={this.onValueChange}
            onBlur={this.onTouched}
          />
          <HelpBlock className={this.isEmailValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
            Invalid email address
          </HelpBlock>
        </FormGroup>
        <FormGroup validationState={this.isUsernameValid() ? null : 'error'}>
          <ControlLabel>Username</ControlLabel>
          <FormControl
            type="text"
            placeholder="Username"
            value={username.value}
            name="username"
            onChange={this.onValueChange}
            onBlur={this.onTouched}
          />
          <HelpBlock className={this.isUsernameValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
            Username is required
          </HelpBlock>
        </FormGroup>
        <FormGroup validationState={this.isPasswordValid() ? null : 'error'}>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            placeholder="Initial Password"
            value={password.value}
            name="password"
            onChange={this.onValueChange}
            onBlur={this.onTouched}
          />
          <HelpBlock className={this.isPasswordValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
              Password does not match complexity requirements
          </HelpBlock>
        </FormGroup>
        { (
          type.value === 'ROLE_AGENCY' ||
          type.value === 'ROLE_CUSTOMER' ||
          type.value === 'ROLE_VESSEL') ? (
            <FormGroup validationState={this.isLinkedValid() ? null : 'error'}>
              <ControlLabel>Linked</ControlLabel>
              <Select
                className="select-dropdown"
                isMulti={isMulti}
                isClearable
                value={this.getLinkedValueForRole()}
                options={values}
                onChange={value => this.onLinkedChange(value, label)}
              />
              <HelpBlock className={this.isLinkedValid() ? 'hidden' : null}>
                <MaterialIcon size="16" icon="error" />
                  Please select an option
              </HelpBlock>
            </FormGroup>
          ) : null }
      </Form>
    )
  }
}

CreateUserForm.propTypes = {
  role: PropTypes.object,
  type: PropTypes.object
}

CreateUserForm.defaultProps = {
  role: {},
  type: {}
}

export default CreateUserForm
