import React from 'react'
import PropTypes from 'prop-types'
import { Table, Image } from 'react-bootstrap'
import shortId from 'shortid'

class TravelDocuments extends React.Component {
  constructor() {
    super()
  }

  renderTravelDocuments = (docs = {}) =>
    docs.map((doc) => (
      <tr key={shortId.generate()}>
        <td>
          <Image src={doc.base64} style={{ width: 25 }} />
        </td>
        <td>{doc.type}</td>
        <td>{doc.number}</td>
        <td>{doc.issueDate}</td>
        <td>{doc.expiryDate}</td>
        <td>{doc.issueCountry}</td>
        <td>{doc.issuingAuthority}</td>
      </tr>
    ))

  render() {
    const { travelDocuments } = this.props

    return (
      <div id="travel-documents">
        <h4>Travel Documents</h4>

        <Table responsive>
          <thead>
            <tr>
              <th>Preview</th>
              <th>Type</th>
              <th>Number</th>
              <th>Issue Date</th>
              <th>Expiry Date</th>
              <th>Issue Place</th>
              <th>Issued by</th>
            </tr>
          </thead>
          <tbody>
            {travelDocuments.length === 0 ? (
              <tr>
                <td>
                  <i>No attached files</i>
                </td>
              </tr>
            ) : (
              this.renderTravelDocuments(travelDocuments)
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

TravelDocuments.propTypes = {
  travelDocuments: PropTypes.array
}

TravelDocuments.defaultProps = {
  travelDocuments: []
}

export default TravelDocuments
