import {
  FETCH_VESSELS,
  FETCH_VESSELS_SUCCESS,
  FETCH_VESSELS_FAILURE,
  CREATE_VESSEL_USER,
  CREATE_VESSEL_USER_SUCCESS,
  CREATE_VESSEL_USER_FAILURE
} from '../actions/vessels'

const defaultState = {
  page: 1,
  limit: 10,
  offset: 0,
  orderBy: 'name',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function vesselsReducer(state = defaultState, action) {
  switch (action.type) {
    // REQUEST
    case FETCH_VESSELS:
    case CREATE_VESSEL_USER: {
      return { ...state, isFetching: true, didFetch: false, entities: {} }
    }

    case FETCH_VESSELS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        didFetch: true,
        entities: action.normalized.entities.vessel || {}
      }
    }

    // SUCCESS
    case CREATE_VESSEL_USER_SUCCESS: {
      return {
        ...state,
        didFetch: true,
        isFetching: false,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }

    // FAILURE
    case FETCH_VESSELS_FAILURE:
    case CREATE_VESSEL_USER_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }

    default:
      return state
  }
}
