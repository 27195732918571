import React, { Component } from 'react'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap/lib/Navbar'
import apiRequest from '../../helpers/apiHelper'
import { showNotificationFailed, showNotificationSuccess } from '../../helpers/shared/notification'
import './academy.css'

class Academy extends Component {
  state = {
    url: '',
    token: ''
  }

  componentDidMount = async () => {
    const { notificationsActions } = this.props
    await apiRequest('/api/academySettings')
      .then(res => res.json)
      .then(({ url, token }) => this.setState({ url, token }))
      .catch(() => showNotificationFailed(notificationsActions, 'Unable to load academy data.'))
  }

  onSubmit = async () => {
    const { url, token } = this.state
    const { notificationsActions } = this.props
    await apiRequest('/api/academySettings', 'PUT', {
      url, token
    })
      .then(() => showNotificationSuccess(notificationsActions, 'Successfully saved new data.'))
      .catch(() => showNotificationFailed(notificationsActions, 'Unable to save.'))
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  render() {
    const { url, token } = this.state

    return (
      <div className="wrapper">
        <h3 className="title">Moodle Settings</h3>
        <Form onSubmit={this.onSubmit} className="form">
          <FormGroup className="group">
            <ControlLabel>Moodle API Token</ControlLabel>
            <FormControl
              type="text"
              name="token"
              value={token}
              placeholder="Enter API Token"
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className="group">
            <ControlLabel>Moodle URL</ControlLabel>
            <FormControl
              type="text"
              name="url"
              value={url}
              placeholder="Enter URL"
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className="save-button">
            <button className="btn btn-primary" type="submit" onClick={this.onSubmit}>
              Save
            </button>
          </FormGroup>
        </Form>
      </div>
    )
  }
}

export default Academy
