import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, PageHeader } from 'react-bootstrap'
import { ModalTypes } from '../../components/shared/modal'
import { showNotificationFailed, showNotificationSuccess } from '../../helpers/shared/notification'
import {
  composeQuery,
  parseQuery,
  setUrlQueryString
} from '../../helpers/shared/dataTable/dataTableHelper'
import DataTable from '../../components/shared/dataTable/dataTable'
import RolePrivilegesForm from '../../components/rolePrivilegesForm'

import {
  CREATE_NEW_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_SUCCESS
} from '../../actions/userRoles'

class RolePrivileges extends React.Component {
  columns = [
    {
      Header: 'Role',
      className: 'd-flex align-items-center',
      accessor: 'name',
      id: 'name'
    },
    {
      accessor: (row) => (
        <div className="text-center">
          <Button onClick={() => this.handleCopyeExistingRole(row)}>Copy</Button>
        </div>
      ),
      id: 'copyId',
      width: 80,
      sortable: false
    },
    {
      accessor: (row) => (
        <div className="text-center">
          <Button onClick={() => this.handleEditRole(row)}>Edit</Button>
        </div>
      ),
      id: 'editId',
      width: 80,
      sortable: false
    },
    {
      accessor: (row) => (
        <div className="text-center">
          <Button onClick={() => this.handleDeleteRole(row)} disabled={row.predefined}>Delete</Button>
        </div>
      ),
      id: 'deleteId',
      width: 80,
      sortable: false
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      rolesPrivilegesList: [],
      pages: null,
      loading: true,
      parsedQuery: {}
    }
  }

  componentDidMount() {
    this.fetchRoles()
  }

  fetchRoles = (query = {}) => {
    const { userRolesActions, history } = this.props
    const { page, limit, sort } = this.props.userRoles
    const { parsedQuery } = this.state

    this.setState({
      loading: true,
      parsedQuery: {
        ...parsedQuery,
        ...parseQuery(query)
      }
    })

    userRolesActions.fetchUserRoles({
      page,
      limit,
      sort,
      ...parseQuery(query)
    })
      .then(({ response }) => {
        setUrlQueryString(history, response)
        this.setState({
          rolesPrivilegesList: response.entities,
          pages: Math.ceil(response.totalCount / limit) === Infinity ? 1 : Math.ceil(response.totalCount / limit),
          loading: false
        })
      })
      .catch(() => this.showFetchError())
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch roles')
    this.setState({
      loading: false
    })
  }

  handleFilter = (text) => {
    this.fetchRoles(composeQuery(text))
  }

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState({
      parsedQuery: {
        ...parsedQuery,
        filter: ''
      }
    }, () => this.fetchRoles())
  }

  handleCreateNewRole = () => {
    const { rolesPrivilegesList } = this.state

    this.props.modalsActions.showModal(ModalTypes.form, {
      headline: 'Create new role',
      formcomp: RolePrivilegesForm,
      formprops: {
        rolesPrivilegesList
      },
      onSubmit: (values) => {
        this.props.modalsActions.hideModal()
        this.props.userRolesActions.createNewRole({
          name: values.name,
          privileges: values.formData
        })
          .then((res) => {
            if (res.type === CREATE_NEW_USER_ROLE_SUCCESS) {
              showNotificationSuccess(this.props.notificationsActions, `Successfully created ${values.name} role`)
              this.fetchRoles()
            }
          })
          .catch((err) => showNotificationFailed(this.props.notificationsActions, `Unable to create ${values.name} role`))
      }
    })
  };

  handleDeleteRole = (data) => {
    this.props.modalsActions.showModal(ModalTypes.confirm, {
      question: `Are you sure you want to delete ${data.name} role?`,
      onConfirm: () => {
        this.props.modalsActions.hideModal()
        this.props.userRolesActions.deleteUserRole(data.id)
          .then((res) => {
            if (res.type === DELETE_USER_ROLE_SUCCESS) {
              showNotificationSuccess(this.props.notificationsActions, `Deleted ${data.name} role`)
              this.fetchRoles()
            }
          })
          .catch((response) => {
            const message = response.error ? response.error : `Unable to delete ${data.name} role`
            showNotificationFailed(this.props.notificationsActions, message)
          })
      }
    })
  };

  handleEditRole = (data) => {
    const { rolesPrivilegesList } = this.state

    this.props.modalsActions.showModal(ModalTypes.form, {
      headline: `Edit ${data.name} role`,
      formcomp: RolePrivilegesForm,
      formprops: {
        name: `${data.name}`,
        formData: {
          ...data.privileges
        },
        originalName: `${data.name}`,
        rolesPrivilegesList,
        isPredefined: data.predefined
      },
      onSubmit: (values) => {
        this.props.modalsActions.hideModal()
        this.props.userRolesActions.updateUserRole(data.id, {
          name: values.name,
          privileges: values.formData
        })
          .then((res) => {
            showNotificationSuccess(this.props.notificationsActions, `Successfully updated role ${res.response.entities[0].name || ''}`)
            this.fetchRoles()
          })
          .catch((err) => {
            showNotificationFailed(this.props.notificationsActions, `Unable to update role ${data.name}`)
          })
      }
    })
  };

  handleCopyeExistingRole = (data) => {
    const { rolesPrivilegesList } = this.state
    this.props.modalsActions.showModal(ModalTypes.form, {
      headline: `Copy ${data.name} role`,
      formcomp: RolePrivilegesForm,
      formprops: {
        name: `${data.name} copy`,
        formData: {
          ...data.privileges
        },
        rolesPrivilegesList
      },
      onSubmit: (values) => {
        this.props.modalsActions.hideModal()
        this.props.userRolesActions.createNewRole({
          name: values.name,
          privileges: values.formData
        })
          .then((res) => {
            if (res.type === CREATE_NEW_USER_ROLE_SUCCESS) {
              showNotificationSuccess(this.props.notificationsActions, `Successfully created ${values.name} role`)
              this.fetchRoles()
            }
          })
          .catch((err) => showNotificationFailed(this.props.notificationsActions, `Unable to create ${values.name} role`))
      }
    })
  };

  render() {
    const { userRoles } = this.props
    const { rolesPrivilegesList, pages, loading, parsedQuery: { filter } } = this.state

    return (
      <div id="restrictions-list">
        <PageHeader>
          <Link to="/users">Users</Link> / Role Privileges
          <ButtonGroup className="actions pull-right hidden-xs">
            <Button onClick={this.handleCreateNewRole}>
              Create new role
            </Button>
          </ButtonGroup>
          <ButtonGroup block vertical className="actions visible-xs-block">
            <Button onClick={this.handleCreateNewRole}>
              Create new role
            </Button>
          </ButtonGroup>
        </PageHeader>
        <DataTable
          filter={filter}
          loading={loading}
          data={rolesPrivilegesList}
          columns={this.columns}
          name="Role"
          tableState={{
            ...userRoles,
            pages
          }}
          isFiltered
          onFetch={this.fetchRoles}
          onFilter={this.handleFilter}
          onClearFilter={this.handleClearFilter}
          customEmptyResultsMsg="No user roles matching your search criteria"
          onUserFilter={this.fetchUsers}
        />
      </div>
    )
  }
}

RolePrivileges.propTypes = {
  notificationsActions: PropTypes.object.isRequired,
  userRoles: PropTypes.object.isRequired,
  userRolesActions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  modalsActions: PropTypes.object.isRequired
}

export default RolePrivileges
