export const USER_TOKEN = 'token'

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  CREWMEMBER: 'ROLE_CREWMEMBER',
  CUSTOMER: 'ROLE_CUSTOMER',
  AGENCY: 'ROLE_AGENCY',
  VESSEL: 'ROLE_VESSEL',
  CONTENT_EDITOR: 'ROLE_CONTENT_EDITOR'
}

export const RESTRICTION_ROLES = {
  ADMIN: 'ADMIN',
  CREWMEMBER: 'CREWMEMBER',
  CUSTOMER: 'CUSTOMER',
  AGENCY: 'AGENCY',
  VESSEL: 'VESSEL'
}

export const CONTENT_TYPES = {
  NEWS: 'NEWS',
  JOB_OFFER: 'JOB_OFFER',
  TRAINING_MATERIALS: 'TRAINING_MATERIALS',
  RULES_REGULATIONS: 'RULES_REGULATIONS',
  ITINERARIES: 'ITINERARIES'
}

export const AUDIENCE_TYPES = {
  ALL: 'ALL',
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL'
}

export const USER_PASS_REGEXP = /((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,80})/
