import React from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from 'material-icons-react'

class DocumentTypeCell extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { documentType } = this.props
    if (documentType) {
      return documentType.name.replace(`${documentType.code} - `, '')
    }
    return <MaterialIcon icon="remove" size="20" />
  }
}

DocumentTypeCell.defaultProps = {
  documentType: {}
}

DocumentTypeCell.propTypes = {
  documentType: PropTypes.object
}

export default DocumentTypeCell
