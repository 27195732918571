import {
  FETCH_VESSEL_CREW,
  FETCH_VESSEL_CREW_SUCCESS,
  FETCH_VESSEL_CREW_FAILURE
} from '../actions/vessels'

const defaultState = {
  page: 1,
  limit: 20,
  offset: 0,
  orderBy: 'PIN',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function vesselCrewMembersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_VESSEL_CREW: {
      return { ...state, isFetching: true }
    }
    case FETCH_VESSEL_CREW_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        didFetch: false,
        isFetching: false,
        entities: action.normalized.entities.team || {}
      }
    }
    case FETCH_VESSEL_CREW_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}
