import {
  FETCH_LOGGED_IN_USERS,
  FETCH_LOGGED_IN_USERS_SUCCESS,
  FETCH_LOGGED_IN_USERS_FAILURE
} from '../actions/users'

const defaultState = {
  page: 1,
  limit: 10,
  offset: 0,
  orderBy: 'loggedInAt',
  sort: 'DESC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function loggedInUsersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_LOGGED_IN_USERS: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case FETCH_LOGGED_IN_USERS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        error: null,
        entities: action.normalized.entities.users || {}
      }
    }
    case FETCH_LOGGED_IN_USERS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
