import React from 'react'
import MaterialIcon from 'material-icons-react'
import Select, { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Form, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap'
import ApiRequest from '../helpers/apiHelper'

class CreateUserSelectRoleForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      role: {
        value: '',
        touched: false
      },
      type: {
        value: '',
        touched: false
      },
      permissionRoles: []
    }
  }

  formValues = () => ({
    role: this.state.role.value,
    type: this.state.type.value
  })

  getUserRole = (role) => {
    switch (role) {
      case 'ROLE_ADMIN':
        return 'Admin'
      case 'ROLE_CUSTOMER':
        return 'Customer'
      case 'ROLE_AGENCY':
        return 'Manning Agent'
      case 'ROLE_CONTENT_EDITOR':
        return 'Content Editor'
      case 'ROLE_VESSEL':
        return 'Vessel'
      default:
        return role
    }
  }

  isValid = () => {
    const { role, type } = this.state

    if (!role.value || !type.value) {
      return false
    }

    return true
  }

  touchAllFields = () => {
    this.setState({
      role: {
        touched: true,
        value: this.state.role.value
      },
      type: {
        touched: true,
        value: this.state.type.value
      }
    })
  }

  onValueChange = (event) => {
    const { name, value } = event.target
    const role = value ? this.getRoleValueByTypeName(value) : null

    if (name === 'type') {
      this.setState({
        type: {
          value: value,
          touched: true
        },
        role: {
          value: role ? {
            label: role.name,
            value: role.id
          } : null,
          touched: true
        }
      })
    } else if (name === 'role') {
      this.setState({
        role: {
          value: value,
          touched: true
        }
      })
    }
  }

  onTouched = (event) => {
    const { name } = event.target

    this.setState({
      [name]: {
        ...this.state[name],
        touched: true
      }
    })
  }

  isTypeValid = () => (this.state.type.touched ? !!this.state.type.value : true)
  isRoleValid = () => (this.state.role.touched ? !!this.state.role.value : true)

  sortDropdownOptions = (array) => {
    if (array) {
      return array.sort((a, b) => {
        return a.label.localeCompare(b.label)
      }) 
    }
  }

  getRoleValueByTypeName = (type) => this.state.permissionRoles.find((permissionRole) => {
    return (permissionRole.name.toLowerCase() === type.label.toLowerCase()) ||
           (type.value === 'ROLE_AGENCY' &&
           (permissionRole.name.toLowerCase() === 'agency' || permissionRole.name.toLowerCase() === 'manning agent'))
  })

  loadRoles = () => {
    return new Promise(async (resolve) => {
      const permissionRoles = await ApiRequest('/api/rights').then(res => res.json.entities)
      const options = permissionRoles.map(permissionRole => ({
        label: permissionRole.name,
        value: permissionRole.id
      }))
      this.setState({
        permissionRoles
      })
      resolve(options)
    })
  }

  userTypes = [
    {
      label: 'Vessel',
      value: 'ROLE_VESSEL'
    },
    {
      label: 'Admin',
      value: 'ROLE_ADMIN'
    },
    {
      label: 'Content Editor',
      value: 'ROLE_CONTENT_EDITOR'
    },
    {
      label: 'Customer',
      value: 'ROLE_CUSTOMER'
    },
    {
      label: 'Manning Agent',
      value: 'ROLE_AGENCY'
    }
  ]

  render() {
    const { role, type } = this.state

    return (
      <Form>
        <FormGroup validationState={this.isTypeValid() ? null : 'error'}>
          <ControlLabel>Type</ControlLabel>
          <Select
            className="select-dropdown"
            isClearable
            value={type.value}
            options={this.sortDropdownOptions(this.userTypes)}
            onChange={selectedType => this.onValueChange({ target: { value: selectedType, name: 'type' } })}
          />
          <HelpBlock className={this.isTypeValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
              Please select type
          </HelpBlock>
        </FormGroup>
        <FormGroup validationState={this.isRoleValid() ? null : 'error'}>
          <ControlLabel>Role</ControlLabel>
          <AsyncSelect
            className="select-dropdown"
            isClearable
            defaultOptions
            value={role.value}
            loadOptions={this.loadRoles}
            filterOption={createFilter()}
            onChange={selectedType => this.onValueChange({ target: { value: selectedType, name: 'role' } })}
            onBlur={this.onTouched}
            name="role"
          />
          <HelpBlock className={this.isRoleValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
              Please select role
          </HelpBlock>
        </FormGroup>
      </Form>
    )
  }
}

export default CreateUserSelectRoleForm
