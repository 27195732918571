import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, ControlLabel, Button } from 'react-bootstrap'
import moment from 'moment'
import { renderValue, renderAddress } from '../shared/formHelper'

class PersonalDetails extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { crewMember } = this.props

    return (
      <div id="personal-details">
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>PIN</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.PIN}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Personal ID</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.personalId}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Title</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.Title.label}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Firstname</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.firstname}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Middlename</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.middlename}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Lastname</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.lastname}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Position</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(crewMember.position)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Date of Birth</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.dateOfBirth
              ? moment(crewMember.dateOfBirth).format('DD/MM/YYYY')
              : renderValue(crewMember.dateOfBirth)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Gender</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.Gender ? renderValue(crewMember.Gender.label) : renderValue()}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Nationality</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {crewMember.Nationality ? renderValue(crewMember.Nationality.label) : renderValue()}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Address</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderAddress(crewMember.address)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Mobile Phone</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(crewMember.mobilePhone || '')}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Phone</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(crewMember.phone || '')}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Email</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(crewMember.email)}
          </Col>
        </Row>
      </div>
    )
  }
}

PersonalDetails.propTypes = {
  crewMember: PropTypes.object.isRequired
}

export default PersonalDetails
