import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import shortId from 'shortid'
import {
  ButtonGroup,
  Button,
  Checkbox,
  PageHeader,
  Row,
  Col,
  Label,
  Panel,
  Table,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock
} from 'react-bootstrap'
import MaterialIcon from 'material-icons-react'
import ApiRequest from '../../helpers/apiHelper'
import { ROLES } from '../../constants'
import { ModalTypes } from '../../components/shared/modal'
import { DELETE_USER_SUCCESS, UPDATE_USER_SUCCESS, RESET_USER_SUCCESS } from '../../actions/users'
import { showNotificationSuccess, showNotificationFailed } from '../../helpers/shared/notification'
import ResetPasswordForm from '../../components/resetPasswordForm'
import VesselAssignment from '../../components/vesselAssignment'
import { checkIsEmail } from '../../../common/helpers/validators'

class UsersShow extends React.Component {
  constructor(props) {
    super(props)

    this.loadPermissionsRoles()
    this.state = {
      emailTouched: false,
      receivedEmail: false,
      permissionsRoles: [],
      permissionsRole: null
    }
  }

  componentDidMount() {
    this.props.usersActions.fetchUserById(this.props.match['*'])
      .then(data => {
        this.setState({ user: data.response, receivedEmail: !!data.response.email })
      })
      .then(() => this.initState(this.state.user))
      .catch(error => showNotificationFailed(this.props.notificationsActions, 'Unable to load user data'))
  }

  initState = (user) => {
    if (this.state.selectedTeams) return
    if (!this.state.permissionsRoles || !this.state.permissionsRoles.length) return
    if (!user) return

    const permissionsRole = {
      label: this.getPermissionsRoleByRightId(user.RightId).name,
      vale: this.getPermissionsRoleByRightId(user.RightId).id
    }
    let selectedTeams = [],
        selectedCrews = [],
        uploadToBasket = {}

    if (user && user.Teams && user.Teams.length > 0) {
      selectedTeams = user.Teams.map((team) => ({ value: team.id, label: team.name }))
    }

    if (user && user.Crews && user.Crews.length > 0) {
      selectedCrews = user.Crews.map((crew) => ({ value: crew.id, label: crew.name }))
    }

    uploadToBasket = (user && user.uploadToBasket) ? user.uploadToBasket : {
      travels: false,
      certificates: false,
      medicals: false,
      enclosed: false
    }

    this.setState({
      selectedTeams,
      selectedCrews,
      uploadToBasket,
      permissionsRole
    })
  }

  getUser = () => {
    return this.state.user || null
  }

  userType = () => {
    const user = this.getUser()

    if (!user) return null

    switch (user.role) {
      case ROLES.ADMIN:
        return 'Admin'
      case ROLES.CREWMEMBER:
        return 'Crew Member'
      case ROLES.CUSTOMER:
        return 'Customer'
      case ROLES.AGENCY:
        return 'Agent'
      case ROLES.CONTENT_EDITOR:
        return 'Content Editor'
      case ROLES.VESSEL:
        return 'Vessel'
      default:
        return null
    }
  }

  handleUserUpdate = () => {
    const userId = this.props.match['*']
    const teamsIds = this.state.selectedTeams.map((t) => t.value)
    const crewsIds = this.state.selectedCrews.map((c) => c.value)
    const { user: { email, firstName, lastName, username, vesselIds, RightId }, permissionsRole } = this.state

    if (!email) {
      return this.setState({ emailTouched: true })
    }

    this.props.usersActions.updateUserById(userId, {
      crewsIds,
      teamsIds,
      email,
      firstName,
      lastName,
      username,
      RightId: permissionsRole.value ? permissionsRole.value : RightId,
      VesselIds: (vesselIds && vesselIds.length) ? vesselIds.map(el => String(el)) : [],
      uploadToBasket: this.state.uploadToBasket
    }).then((res) => {
      if (res.type === UPDATE_USER_SUCCESS) {
        showNotificationSuccess(this.props.notificationsActions, 'Updated User')
        this.setState({ receivedEmail: true })
      } else {
        showNotificationFailed(this.props.notificationsActions, 'Unable to update User')
      }
    }).catch((error) => {
      if (error && error.type && error.type === 'UPDATE_USER_FAILURE') {
        showNotificationFailed(this.props.notificationsActions, 'User not found in Cognito, please reset the user')
      }
    })
  }

  onEmailTouched = () => {
    this.setState({
      emailTouched: true
    })
  }

  isEmailValid = () => 
    (this.state.emailTouched ? (this.state.user.email && checkIsEmail(this.state.user.email)) : true)

  renderUserEditForm = () => {
    const { user, receivedEmail } = this.state
    const disableControls = user.role === ROLES.CREWMEMBER

    return (
      <FormGroup>
        <FormGroup validationState={this.isEmailValid() ? null : 'error'}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            autoComplete="email"
            value={user.email || ''}
            name="email"
            onChange={event => this.setState({ user: { ...this.state.user, email: event.target.value } })}
            onBlur={this.onEmailTouched}
            disabled={disableControls && receivedEmail}
          />
          <HelpBlock className={this.isEmailValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
              Invalid email address
          </HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            type="text"
            value={user.firstName || ''}
            disabled={disableControls}
            onChange={event => this.setState({ user: { ...this.state.user, firstName: event.target.value } })}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Last Name</ControlLabel>
          <FormControl
            type="text"
            value={user.lastName || ''}
            disabled={disableControls}
            onChange={event => this.setState({ user: { ...this.state.user, lastName: event.target.value } })}
          />
        </FormGroup>
      </FormGroup>
    )
  }

  handleUserDelete = () =>
    this.props.modalsActions.showModal(ModalTypes.confirm, {
      question: 'Are you sure?',
      onConfirm: () => {
        const state = this.props.location.state || {}

        this.props.usersActions.deleteUser(this.props.match['*']).then((res) => {
          if (res.type === DELETE_USER_SUCCESS) {
            showNotificationSuccess(this.props.notificationsActions, 'Deleted User')

            if (state.from) {
              this.props.history(`${state.from}`, { refetch: true })
            } else {
              this.props.history('/users')
            }
          } else {
            showNotificationFailed(this.props.notificationsActions, 'Unable to delete User')
          }
        })
      }
    })

  handleUserReset = () => {
    const user = this.getUser()

    return this.props.modalsActions.showModal(ModalTypes.confirm, {
      question: 'Are you sure?',
      onConfirm: () => {
        if (user.role === ROLES.CREWMEMBER) {
          this.resetUser()
        } else {
          this.props.modalsActions.showModal(ModalTypes.form, {
            formcomp: ResetPasswordForm,
            onSubmit: (values) => {
              this.resetUser(values.password)
            }
          })
        }
      }
    })
  }

  resetUser = (password = null) => {
    const state = this.props.location.state || {}

    this.props.usersActions.resetUser(this.props.match['*'], { password }).then((res) => {
      if (res.type === RESET_USER_SUCCESS) {
        showNotificationSuccess(this.props.notificationsActions, 'User has been successfully reset')

        if (state.from) {
          this.props.history(`${state.from}`, { refetch: true })
        } else {
          this.props.history('/users')
        }
      } else {
        showNotificationFailed(this.props.notificationsActions, 'Unable to reset User')
      }
    })
  }

  renderTeamResponsibilitiesSelect = () => {
    const user = this.getUser()

    if (
      user.role === ROLES.CREWMEMBER ||
      user.role === ROLES.ADMIN ||
      user.role === ROLES.CONTENT_EDITOR ||
      user.role === ROLES.VESSEL
    ) {
      return null
    }

    const loadTeams = (params) =>
      ApiRequest(`/api/teamsbasic?filter=${params}`).then((res) => {
        const results = res.json.entities.map((entity) => ({
          value: entity.id,
          label: entity.name
        }))

        this.allTeams = results

        return results
      })

    return (
      <div>
        <ControlLabel>Responsible for Teams </ControlLabel>
        <Row>
          <Col xs={12} sm={10}>
            <AsyncSelect
              name="form-field-teams"
              key={shortId.generate()}
              loadOptions={loadTeams}
              value={this.state.selectedTeams}
              isMulti
              filterOption={createFilter()}
              defaultOptions
              cacheOptions
              onChange={(selectedTeams) => this.setState({ selectedTeams })}
            />
          </Col>
          <Col xs={12} sm={2}>
            <Button
              className="pull-right"
              onClick={() => this.setState({ selectedTeams: this.allTeams })}>
              Select All
            </Button>
          </Col>
        </Row>
        <br />
        <div>If no crew is specified below, this user ( {user.username} ) will see all members (in Agencies manning pool) for the selected teams</div>
        <br />
      </div>
    )
  }

  renderCrewResponsibilitiesSelect = () => {
    const user = this.getUser()

    if (
      user.role === ROLES.CREWMEMBER ||
      user.role === ROLES.ADMIN ||
      user.role === ROLES.CONTENT_EDITOR ||
      user.role === ROLES.VESSEL
    ) {
      return null
    }

    const selectedTeamIds = this.state.selectedTeams.map((t) => t.value)

    const loadCrews = (params) =>
      ApiRequest(`/api/crewsbasic?filter=${params}&teams=${selectedTeamIds.join(',')}`).then((res) => {
        const results = res.json.entities.map((entity) => ({
          value: entity.id,
          label: entity.name
        }))

        this.allCrews = results

        return results
      })

    return (
      <div>
        <ControlLabel>Responsible for Crews</ControlLabel>
        <Row>
          <Col xs={12} sm={10}>
            <AsyncSelect
              key={shortId.generate()}
              name="form-field-crews"
              loadOptions={loadCrews}
              isMulti
              filterOption={createFilter()}
              defaultOptions
              cacheOptions
              value={this.state.selectedCrews}
              onChange={(selectedCrews) => this.setState({ selectedCrews })}
            />
          </Col>
          <Col xs={12} sm={2}>
            <Button
              className="pull-right"
              onClick={() => this.setState({ selectedCrews: this.allCrews })}>
              Select All
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  renderUploadDestinationMatrix = () => {
    const user = this.getUser()

    return (
      <div>
        <ControlLabel>Upload documents to basket</ControlLabel>
        <Row>
          <Col xs={12} sm={2}>
            <Checkbox
              key={shortId.generate()}
              name="endpoint_travels"
              checked={this.state.uploadToBasket.travels}
              onChange={(evt) => this.updateUploadDestination('travels', evt.target.checked)}>
              Travel
            </Checkbox>

            <Checkbox
              key={shortId.generate()}
              name="endpoint_certificates"
              checked={this.state.uploadToBasket.certificates}
              onChange={(evt) => this.updateUploadDestination('certificates', evt.target.checked)}>
              Certificates
            </Checkbox>

            <Checkbox
              key={shortId.generate()}
              name="endpoint_medicals"
              checked={this.state.uploadToBasket.medicals}
              onChange={(evt) => this.updateUploadDestination('medicals', evt.target.checked)}>
              Medicals
            </Checkbox>

            <Checkbox
              key={shortId.generate()}
              name="endpoint_enclosed"
              checked={this.state.uploadToBasket.enclosed}
              onChange={(evt) => this.updateUploadDestination('enclosed', evt.target.checked)}>
              Enclosed
            </Checkbox>
          </Col>
        </Row>
      </div>
    )
  }

  handleVesselsChange = (vesselIds) => {
    this.setState({
      user: {
        ...this.state.user,
        vesselIds
      }
    })
  }

  renderVesselsAssignment = () => { 
    if (this.getUser().role !== ROLES.VESSEL) {
      return
    }
    
    const vessels = this.state.user.Vessels.map(el => ({ label: el.name, value: el.id }))

    return (
      <VesselAssignment
        onVesselsChange={this.handleVesselsChange}
        initialVessels={vessels}
      />)
  }

  updateUploadDestination = (type, value) => {
    this.setState({
      uploadToBasket: {
        ...this.state.uploadToBasket,
        [type]: value
      }
    })
  }

  responsableFor = () => {
    const user = this.getUser()

    return (
      <td>
        {!this.props.users.isFetching
          ? this.state.selectedCrews.map((crew) => (
            <span key={shortId.generate()}>
              <Label>
                {crew.label}
              </Label>{' '}
            </span>
          ))
          : 'loading...'}
      </td>
    )
  }

  isFormInvalid = () => {
    return (this.state.user.vesselIds && this.state.user.vesselIds.length === 0)
  }

  loadPermissionsRoles = () => {
    return new Promise(async (resolve) => {
      const permissionsRoles = await ApiRequest('/api/rights').then(res => res.json.entities)
      const options = permissionsRoles
        .filter(permissionsRole => !(permissionsRole.name.toLowerCase() === 'admin' && this.userType() !== 'Admin'))
        .map(permissionsRole => ({
          label: permissionsRole.name,
          value: permissionsRole.id
        }))
      this.setState({
        permissionsRoles
      })
      resolve(options)
    })
  }

  handlePermissionsRoleChange = (selectedPermissionRole) => {
    this.setState({
      permissionsRole: {
        label: selectedPermissionRole.label,
        value: selectedPermissionRole.value
      }
    })
  }

  getPermissionsRoleByRightId = (RightId) => this.state.permissionsRoles.find(permissionsRole => permissionsRole.id === RightId)

  render() {
    const user = this.getUser()
    const loggedInUser = this.props.user.user
    const { permissionsRole } = this.state
    const permissionsRoleStaticLabel = permissionsRole ? permissionsRole.label : ''

    if (!user) {
      return null
    }

    if (!this.state.selectedCrews) {
      this.initState(user)
      return null
    }

    const hasResponsabilities = user.role === ROLES.AGENCY || user.role === ROLES.CUSTOMER

    return (
      <div id="users-show">
        <PageHeader>
          <Label bsStyle="info" className="pull-right">
            {this.userType()}
          </Label>
          <Link to="/users">Users</Link> / {user.username}
        </PageHeader>
        <Row>
          <Col xs={12}>
            <Panel className="table-responsive-overflow-x-visible">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Role</th>
                    {hasResponsabilities ? <th>Responsible for</th> : null}
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{user.username}</td>
                    <td>
                      { loggedInUser.role === ROLES.ADMIN && user.role !== ROLES.CREWMEMBER ?
                        <AsyncSelect
                          className="select-dropdown"
                          defaultOptions
                          value={permissionsRole}
                          loadOptions={this.loadPermissionsRoles}
                          filterOption={createFilter()}
                          onChange={selectedPermissionRole => this.handlePermissionsRoleChange(selectedPermissionRole)}
                          onBlur={this.onTouched}
                          name="role"
                        />
                        : permissionsRoleStaticLabel
                      }
                    </td>
                    {hasResponsabilities ? this.responsableFor() : null}
                    <td>{moment(user.createdAt).format('DD.MM.YYYY')}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <hr className="visible-xs-block" />
              {this.renderTeamResponsibilitiesSelect()}
              <br />
              <hr className="visible-xs-block" />
              {this.state.selectedTeams.length > 0 ? this.renderCrewResponsibilitiesSelect() : null}
              <br />
              <hr className="visible-xs-block" />
              {this.renderVesselsAssignment()}
              <br />
              <hr className="visible-xs-block" />
              {this.renderUploadDestinationMatrix()}
              <br />
              {this.renderUserEditForm()}
              <ButtonGroup className="pull-right">
                <Button bsSize="small" bsStyle="danger" onClick={this.handleUserDelete}>
                  Delete
                </Button>
                <Button bsSize="small" bsStyle="warning" onClick={this.handleUserReset}>
                  Reset User
                </Button>
                <Button bsSize="small" bsStyle="primary" onClick={this.handleUserUpdate} disabled={this.isFormInvalid()}>
                  Update
                </Button>
              </ButtonGroup>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}

UsersShow.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  usersActions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  modalsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default UsersShow
