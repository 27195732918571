import {
  FETCH_CREW_MEMBERS,
  FETCH_CREW_MEMBERS_SUCCESS,
  FETCH_CREW_MEMBERS_FAILURE,
  FETCH_CREW_MEMBER,
  FETCH_CREW_MEMBER_SUCCESS,
  FETCH_CREW_MEMBER_FAILURE,
  CREATE_CREW_MEMBER_USER,
  CREATE_CREW_MEMBER_USER_SUCCESS,
  CREATE_CREW_MEMBER_USER_FAILURE,
  FETCH_ADONIS_CREW_MEMBERS,
  FETCH_ADONIS_CREW_MEMBERS_SUCCESS,
  FETCH_ADONIS_CREW_MEMBERS_FAILURE,
  FETCH_CREW_MEMBERS_RESET,
  FETCH_CREW_MEMBER_REGISTRATION_TOKEN,
  FETCH_CREW_MEMBER_REGISTRATION_TOKEN_SUCCESS,
  FETCH_CREW_MEMBER_REGISTRATION_TOKEN_FAILURE
} from '../actions/crewMembers'

const defaultState = {
  page: 1,
  limit: 40,
  offset: 0,
  orderBy: 'PIN',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function crewMembersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CREW_MEMBERS:
    case FETCH_CREW_MEMBER:
    case CREATE_CREW_MEMBER_USER: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case FETCH_CREW_MEMBERS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        entities: action.normalized.entities.crewMembers || {}
      }
    }
    case CREATE_CREW_MEMBER_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false
      }
    }
    case FETCH_CREW_MEMBER_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        entities: { ...state.entities, [action.response.PIN]: action.response }
      }
    }
    case FETCH_CREW_MEMBER_REGISTRATION_TOKEN_SUCCESS: {
      const entityWithToken = {
        ...state.entities[action.response.PIN],
        token: action.response.token
      }

      return {
        ...state,
        isFetching: false,
        entities: { ...state.entities, [action.response.PIN]: { ...entityWithToken } }
      }
    }
    case FETCH_CREW_MEMBERS_FAILURE:
    case FETCH_CREW_MEMBER_FAILURE:
    case CREATE_CREW_MEMBER_USER_FAILURE:
    case FETCH_ADONIS_CREW_MEMBERS_FAILURE:
    case FETCH_CREW_MEMBER_REGISTRATION_TOKEN_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    case FETCH_ADONIS_CREW_MEMBERS:
    case FETCH_CREW_MEMBER_REGISTRATION_TOKEN: {
      return { ...state, isFetching: true }
    }
    case FETCH_ADONIS_CREW_MEMBERS_SUCCESS: {
      return { ...state, isFetching: false }
    }
    case FETCH_CREW_MEMBERS_RESET: {
      return {
        ...state,
        didFetch: false
      }
    }
    default: {
      return state
    }
  }
}
