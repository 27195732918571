import {
  FETCH_TEAM_MEMBERS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  SET_SELECTED_CREWS
} from '../../actions/shared/teams'

const defaultState = {
  page: 1,
  limit: 20,
  offset: 0,
  orderBy: 'PIN',
  sort: 'DESC',
  selectedCrews: [],
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function teamMembersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_TEAM_MEMBERS: {
      return { ...state, isFetching: true }
    }
    case FETCH_TEAM_MEMBERS_SUCCESS: {
      const {
        page, limit, offset, orderBy, sort, totalCount, filter
      } = action.response.CrewMembers
      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        didFetch: false,
        isFetching: false,
        entities: action.normalized.entities.team[action.normalized.result].CrewMembers.entities || {}
      }
    }
    case FETCH_TEAM_MEMBERS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    case SET_SELECTED_CREWS: {
      return {
        ...state,
        selectedCrews: action.selectedCrews
      }
    }
    default:
      return state
  }
}
