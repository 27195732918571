import { schema } from 'normalizr'
import { CALL_API } from '../../middleware/apiMiddleware'

const team = new schema.Entity('team', {}, { idAttribute: 'id' })
const teamList = new schema.Array(team)

/**
 * Async Action Creators
 */

export const FETCH_TEAMS = 'FETCH_TEAMS'
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS'
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE'

export function fetchTeams(queryParams) {
  let endpoint = '/api/teams'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: teamList,
      normalizeKey: 'entities',
      types: [FETCH_TEAMS, FETCH_TEAMS_SUCCESS, FETCH_TEAMS_FAILURE]
    }
  }
}

export function fetchTeamsIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.teams.isFetching && !state.teams.didFetch) {
      return dispatch(fetchTeams(queryParams))
    }
  }
}

export const FETCH_BASIC_TEAMS = 'FETCH_BASIC_TEAMS'
export const FETCH_BASIC_TEAMS_SUCCESS = 'FETCH_BASIC_TEAMS_SUCCESS'
export const FETCH_BASIC_TEAMS_FAILURE = 'FETCH_BASIC_TEAMS_FAILURE'

export function fetchBasicTeams(queryParams) {
  let endpoint = '/api/teamsbasic'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: teamList,
      normalizeKey: 'entities',
      types: [FETCH_BASIC_TEAMS, FETCH_BASIC_TEAMS_SUCCESS, FETCH_BASIC_TEAMS_FAILURE]
    }
  }
}

export function fetchBasicTeamsIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.teams.isFetching && !state.teams.didFetch) {
      return dispatch(fetchBasicTeams(queryParams))
    }
  }
}

export const FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS'
export const FETCH_TEAM_MEMBERS_SUCCESS = 'FETCH_TEAM_MEMBERS_SUCCESS'
export const FETCH_TEAM_MEMBERS_FAILURE = 'FETCH_TEAM_MEMBERS_FAILURE'

export function fetchTeamMembersById(id, queryParams) {
  let endpoint = `/api/teams/${id}/members`

  if (queryParams.selectedCrews) {
    queryParams.selectedCrews.join(',')
  }

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: team,
      types: [FETCH_TEAM_MEMBERS, FETCH_TEAM_MEMBERS_SUCCESS, FETCH_TEAM_MEMBERS_FAILURE]
    }
  }
}

export const FETCH_TEAM = 'FETCH_TEAM'
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS'
export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE'

export function fetchTeamById(id) {
  const endpoint = `/api/teams/${id}`
  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: team,
      types: [FETCH_TEAM, FETCH_TEAM_SUCCESS, FETCH_TEAM_FAILURE]
    }
  }
}

export const FETCH_ADONIS_TEAMS = 'FETCH_ADONIS_TEAMS'
export const FETCH_ADONIS_TEAMS_SUCCESS = 'FETCH_ADONIS_TEAMS_SUCCESS'
export const FETCH_ADONIS_TEAMS_FAILURE = 'FETCH_ADONIS_TEAMS_FAILURE'

export function fetchTeamsFromAdonis() {
  const endpoint = '/api/sync/teams'

  return {
    [CALL_API]: {
      endpoint,
      method: 'put',
      types: [FETCH_ADONIS_TEAMS, FETCH_ADONIS_TEAMS_SUCCESS, FETCH_ADONIS_TEAMS_FAILURE]
    }
  }
}

export const SET_TEAM_MEMBER_PAGE = 'SET_TEAM_MEMBER_PAGE'

export function setTeamMemberPage(page) {
  return { type: SET_TEAM_MEMBER_PAGE, page }
}

export const CLEAR_TEAM_DETAIL = 'CLEAR_TEAM_DETAIL'

export function clearTeamDetail(teamId) {
  return { type: CLEAR_TEAM_DETAIL, teamId }
}

export const SELECTED_TEAM = 'SELECTED_TEAM'
export function setSelectedTeam(selectedTeam) {
  return (dispatch, getState) => {
    const state = getState()
    return dispatch({ type: SELECTED_TEAM, selectedTeam })
  }
}

export const INCLUDE_DIRECT_CREW = 'INCLUDE_DIRECT_CREW'

export function includeDirectCrew(teamId) {
  return { type: INCLUDE_DIRECT_CREW, teamId }
}

export const EXCLUDE_DIRECT_CREW = 'EXCLUDE_DIRECT_CREW'

export function excludeDirectCrew(teamId) {
  return { type: EXCLUDE_DIRECT_CREW, teamId }
}

export const SET_SELECTED_CREWS = 'SET_SELECTED_CREWS'

export function setSelectedCrews(selectedCrews) {
  return { type: SET_SELECTED_CREWS, selectedCrews }
}

