import { SHOW_NOTIFICATION, RESET_NOTIFICATIONS } from '../actions/notifications'

const defaultState = {
  autoDismiss: 8,
  dismissible: true,
  level: '',
  message: '',
  position: 'tc',
  showNotification: false,
  title: ''
}

export default function notificationReducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return Object.assign({}, state, action.notification, {
        showNotification: true
      })
    }
    case RESET_NOTIFICATIONS: {
      return defaultState
    }
    default: {
      return state
    }
  }
}
