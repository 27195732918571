import React from 'react'
import PropTypes from 'prop-types'
import { PageHeader, Button, ButtonGroup } from 'react-bootstrap'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import {
  composeQuery,
  getFetchQuery,
  parseQuery,
  setUrlQueryString
} from '../../../helpers/shared/dataTable/dataTableHelper'
import DataTable from '../../../components/shared/dataTable/dataTable'

class TeamsList extends React.Component {
  static columns = [
    {
      Header: 'Name',
      accessor: 'name'
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true,
      parsedQuery: {}
    }
  }

  componentDidMount() {
    this.fetchTeams()
  }

  fetchTeams = (query = {}) => {
    const { teamsActions, location, history } = this.props
    const { page, limit, sort, orderBy, filter } = this.props.teams
    const { parsedQuery } = this.state

    this.setState({
      loading: true,
      parsedQuery: {
        ...parsedQuery,
        ...parseQuery(query)
      }
    }, () => {
      teamsActions.fetchBasicTeams({
        page,
        limit,
        sort,
        orderBy,
        filter,
        ...getFetchQuery(this.state.parsedQuery, location.search)
      })
        .then(({ response }) => {
          setUrlQueryString(history, response)
          this.setState({
            data: response.entities,
            pages: Math.ceil(response.totalCount / limit),
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  handleFilter = (text) => {
    this.fetchTeams(composeQuery(text))
  }

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState({
      parsedQuery: {
        ...parsedQuery,
        filter: ''
      }
    }, () => this.fetchTeams())
  }

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/teams/${rowInfo.original.id}`)
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Teams')
    this.setState({
      loading: false
    })
  }

  render() {
    const { teams } = this.props
    const { data, pages, loading, parsedQuery: { filter } } = this.state

    return (
      <div id="teams-list">
        <PageHeader>
          Teams
          <ButtonGroup className="actions pull-right hidden-xs">
            <Button onClick={() => this.fetchTeams()}>Reload from Server</Button>
          </ButtonGroup>
          <ButtonGroup block vertical className="actions visible-xs-block">
            <Button onClick={() => this.fetchTeams()}>Reload from Server</Button>
          </ButtonGroup>
        </PageHeader>
        <DataTable
          filter={filter}
          loading={loading}
          data={data}
          columns={TeamsList.columns}
          name="Team"
          tableState={{
            ...teams,
            pages
          }}
          isFiltered
          onFetch={this.fetchTeams}
          onFilter={this.handleFilter}
          onClearFilter={this.handleClearFilter}
          onRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

TeamsList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.func,
  teams: PropTypes.object,
  teamsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
}

TeamsList.defaultProps = {
  teams: { entities: [] }
}

export default TeamsList
