import React from 'react'
import {Route, useLocation, useParams} from 'react-router-dom'
import Show from './show'
import List from './list'
import {Routes, useNavigate} from "react-router";

const Customers = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const match = useParams();

    return (
        <div id="cutomers">
            <Routes>
                <Route exact path="/" element={<List {...props} location={location} history={navigate}/>}/>
                <Route exact path="/:id" element={<Show {...props} location={location} history={navigate} match={match}/>}/>
            </Routes>
        </div>
    )
}


export default Customers
