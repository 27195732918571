import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {
    PageHeader,
    Row,
    Col,
    Panel,
    Button,
    ButtonGroup,
    ControlLabel,
    FormControl
} from 'react-bootstrap'
import LoadingSpinner from '../../components/shared/loadingSpinner'
import {ModalTypes} from '../../components/shared/modal'
import CrewMember from '../../components/crewMember'
import {showNotificationSuccess, showNotificationFailed} from '../../helpers/shared/notification'
import ApiRequest from '../../helpers/apiHelper'

class CrewMembersShow extends React.Component {
    constructor() {
        super()

        this.state = {
            permissionRoles: []
        }
    }

    componentDidMount() {
        this.loadPermissionRoles()
    }

    loadPermissionRoles = () => {
        return new Promise(async (resolve) => {
            const permissionRoles = await ApiRequest('/api/rights').then(res => res.json.entities)
            this.setState({
                permissionRoles
            })
            resolve(permissionRoles)
        })
    }

    getCrewMember = () => {
        return this.props.crewMembers.entities[this.props.match['*']] || null
    }

    handleCreateUser = () => {
        const rightId = this.state.permissionRoles.find(permissionRole => permissionRole.name.toLowerCase() === 'crew member')
        this.props.modalsActions.showModal(ModalTypes.form, {
            formcomp: CrewMember.Forms.CrewMemberUser,
            onSubmit: (values) => {
                values.email = this.getCrewMember().email
                values.RightId = rightId.id
                this.props.crewMembersActions.createCrewMemberUser(this.props.match['*'], values)
                    .then(() => showNotificationSuccess(this.props.notificationsActions, 'Crew Member User created'))
                    .catch(res => showNotificationFailed(this.props.notificationsActions, res.error))
            }
        });
    }

    generateRegisterLink = () => {
        this.props.crewMembersActions.fetchCrewMemberRegistrationToken(this.props.match['*'])
    }

    copyToClipboard = (evt) => {
        const input = evt.target
        input.select()

        try {
            const successful = document.execCommand('copy')

            if (!successful) {
                showNotificationFailed(this.props.notificationsActions, 'Something went wrong.')
                return
            }

            showNotificationSuccess(this.props.notificationsActions, 'Copied Registration-Link')
        } catch (err) {
            showNotificationFailed(this.props.notificationsActions, 'Something went wrong.')
        }
    }

    renderActions = (mobile) => {
        const className = mobile ? 'visible-xs-inline' : 'pull-right hidden-xs'

        return (
            <ButtonGroup className={className}>
                <Link to={-1} className="btn btn-default">
                    Back
                </Link>
            </ButtonGroup>)
    }

    render() {
        const {isFetching, error} = this.props.crewMembers
        const crewMember = this.getCrewMember()
        const hasAccessToViewTeams = this.props.user.user.Right && this.props.user.user.Right.privileges.teams !== 0

        let webPortalHost = ''
        switch (process.env.NODE_ENV) {
            case 'dev':
                webPortalHost = 'http://dev-webportal-ugc.s3-website.eu-central-1.amazonaws.com'
                break
            case 'staging':
                webPortalHost = 'http://staging-webportal-ugc.s3-website.eu-central-1.amazonaws.com'
                break
            case 'production':
                webPortalHost = 'http://prod-webportal-ugc.s3-website.eu-central-1.amazonaws.com'
                break
            default:
                webPortalHost = 'localhost:3333'
        }

        if (!crewMember && !isFetching && !error) {
            this.props.crewMembersActions.fetchCrewMemberByPIN(this.props.match['*'])
            return null
        }

        if (!crewMember) {
            return (
                <LoadingSpinner text="Loading profile"/>
            )
        }

        return (
            <div id="crew-members-show">
                <PageHeader>
                    {this.renderActions(false)}
                    <Link to="/profiles">Crew Members</Link> / {crewMember.PIN}
                </PageHeader>
                {this.renderActions(true)}
                <Row>
                    <Col xs={12}>
                        <Panel>
                            <CrewMember.PersonalDetails crewMember={crewMember}/>
                            <hr/>
                            <CrewMember.NextOfKin crewMember={crewMember}/>
                            <hr/>
                            <CrewMember.Airports crewMember={crewMember}/>
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Panel>
                            <h4>Team</h4>
                            {crewMember.Crew && crewMember.Crew.Team ? (
                                <Fragment>
                                    {hasAccessToViewTeams ? (
                                        <Link to={`/teams/${crewMember.Crew.TeamId}`}>{crewMember.Crew.Team.name}</Link>
                                    ) : crewMember.Crew.Team.name}
                                </Fragment>
                            ) : (
                                <span>no Team</span>
                            )}
                            <hr/>
                            <h4>Crew</h4>
                            {crewMember.Crew ? (
                                <Fragment>
                                    {hasAccessToViewTeams ? (
                                        <Link to={`/teams/${crewMember.Crew.TeamId}/${crewMember.Crew.id}`}>
                                            {crewMember.Crew.name}
                                        </Link>
                                    ) : crewMember.Crew.name}
                                </Fragment>
                            ) : (
                                <span>no Crew</span>
                            )}
                            <hr/>
                            <h4>Vessel</h4>
                            {crewMember.Crew && crewMember.Crew.Vessel ? (
                                crewMember.Crew.Vessel.name
                            ) : (
                                <span>no Vessel</span>
                            )}
                            <hr/>
                            <h4>Department</h4>
                            {crewMember.Crew && crewMember.Crew.Department ? (
                                crewMember.Crew.Department.name
                            ) : (
                                <span>no Vessel</span>
                            )}
                            <hr/>
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Panel>
                            {crewMember.User ? null : (
                                <div>
                                    <ButtonGroup className="pull-right hidden-xs">
                                        <Button onClick={this.handleCreateUser}>Create User</Button>
                                        <Button onClick={this.generateRegisterLink}>Generate register link</Button>
                                    </ButtonGroup>
                                    <ButtonGroup block vertical className="visible-xs-block">
                                        <Button onClick={this.handleCreateUser}>Create User</Button>
                                        <Button onClick={this.generateRegisterLink}>Generate register link</Button>
                                    </ButtonGroup>
                                </div>
                            )}
                            <h4>User</h4>
                            {crewMember.User ? (
                                <Link to={`/users/${crewMember.User.id}`}>{crewMember.User.username}</Link>
                            ) : (
                                <Row>
                                    <Col xs={12}>
                                        <p className="lead">no User</p>
                                        <ControlLabel>Registration Link (click to copy):</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="no registrer link"
                                            readOnly
                                            onClick={crewMember.token ? this.copyToClipboard : null}
                                            value={crewMember.token ? `${webPortalHost}/t#${crewMember.token}` : ''}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Panel>
                    </Col>
                </Row>
            </div>
        )
    }
}

CrewMembersShow.propTypes = {
    crewMembers: PropTypes.object.isRequired,
    crewMembersActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    modalsActions: PropTypes.object.isRequired,
    notificationsActions: PropTypes.object.isRequired,
    history: PropTypes.func,
    user: PropTypes.object.isRequired
}

CrewMembersShow.defaultProps = {
    history: {}
}

export default CrewMembersShow
