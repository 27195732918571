// local configuration
export const development = {
  Auth: {
    identityPoolId: 'eu-central-1:f7023564-5d7a-453b-9d72-03735fb185d2',
    userPoolId: 'eu-central-1_DHRlD0U0y',
    userPoolWebClientId: 'ki4p2gl8ung75oakhgogidj30',
    region: 'eu-central-1'
  }
}

export const dev = {
  Auth: {
    identityPoolId: 'eu-central-1:f7023564-5d7a-453b-9d72-03735fb185d2',
    userPoolId: 'eu-central-1_DHRlD0U0y',
    userPoolWebClientId: 'ki4p2gl8ung75oakhgogidj30',
    region: 'eu-central-1'
  }
}

export const staging = {
  Auth: {
    identityPoolId: 'eu-central-1:6694e3c1-93aa-41c4-9cb5-3a53748e4b03',
    userPoolId: 'eu-central-1_Ht4s6TXQo',
    userPoolWebClientId: '4dsj31lu3jsvmvu74irtpp8vfr',
    region: 'eu-central-1'
  }
}

export const production = {
  Auth: {
    identityPoolId: 'eu-central-1:aedd0595-74f1-44e8-8638-969171d83458',
    userPoolId: 'eu-central-1_HeaQUBJYP',
    userPoolWebClientId: '6ipd275ck1qvn70hleh76t0fdd',
    region: 'eu-central-1'
  }
}
