import React from 'react'
import PropTypes from 'prop-types'
import { Form, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

class EditRestrictionsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      restrictions: this.props.restrictions,
      role: this.props.role
    }
  }

  changeValue = (role, value) => {
    this.setState({ restrictions: { ...this.state.restrictions, [role]: value } })
  }

  render() {
    const { role } = this.state
    
    return (
      <Form>
        <h5>{role.value.label} can</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="restrictions_admin"
            defaultValue={this.state.restrictions[role.value.value]}
            onChange={(value) => this.changeValue(role.value.value, value)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </Form>
    )
  }
}

EditRestrictionsForm.propTypes = {
  restrictions: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired
}

export default EditRestrictionsForm
