import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'

class DocumentTypeRestrictionCell extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { restrictions } = this.props
    if (typeof restrictions !== 'number') {
      return <Label bsStyle="danger">missing</Label>
    }

    switch (restrictions) {
      case 'undefined':
        return <Label>unkown role</Label>
      case 0:
        return <Label>none</Label>
      case 1:
        return <Label bsStyle="warning">view</Label>
      case 2:
        return <Label bsStyle="success">upload</Label>
      default:
        return <Label>unknown state</Label>
    }
  }
}

DocumentTypeRestrictionCell.defaultProps = {
  restrictions: {}
}

DocumentTypeRestrictionCell.propTypes = {
  restrictions: PropTypes.object
}

export default DocumentTypeRestrictionCell
