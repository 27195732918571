import React from 'react'
import PropTypes from 'prop-types'

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="loader fixed">
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10" />
        </svg>
        <h5>{this.props.text}</h5>
      </div>
    )
  }
}

LoadingSpinner.defaultProps = {
  text: ''
}

LoadingSpinner.propTypes = {
  text: PropTypes.string
}

export default LoadingSpinner
