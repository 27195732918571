import {
  FETCH_CONTENTS,
  FETCH_CONTENTS_SUCCESS,
  FETCH_CONTENTS_FAILURE,
  FETCH_CONTENT,
  FETCH_CONTENT_SUCCESS,
  FETCH_CONTENT_FAILURE,
  CREATE_CONTENT,
  CREATE_CONTENT_SUCCESS,
  CREATE_CONTENT_FAILURE,
  UPDATE_CONTENT,
  UPDATE_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  DELETE_CONTENT,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE
} from '../actions/contents'

const defaultState = {
  page: 1,
  limit: 10,
  offset: 0,
  orderBy: 'createdAt',
  sort: 'DESC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function customersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CONTENT:
    case FETCH_CONTENTS:
    case CREATE_CONTENT: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case DELETE_CONTENT: {
      const { entities } = state
      delete entities[action.id]
      return { ...state, isFetching: true, didFetch: true, entities }
    }
    case UPDATE_CONTENT: {
      return {
        ...state,
        isFetching: true,
        didFetch: true,
        entities: { ...state.entities, [action.id]: action.contentData }
      }
    }
    case FETCH_CONTENTS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        entities: action.normalized.entities.contents || {}
      }
    }
    case FETCH_CONTENT_SUCCESS:
    case UPDATE_CONTENT_SUCCESS:
    case CREATE_CONTENT_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }
    case DELETE_CONTENT_SUCCESS: {
      const { entities } = state

      delete entities[action.id]

      return {
        ...state,
        entities,
        didFetch: false,
        isFetching: false
      }
    }
    case CREATE_CONTENT_FAILURE:
    case UPDATE_CONTENT_FAILURE:
    case FETCH_CONTENT_FAILURE:
    case FETCH_CONTENTS_FAILURE:
    case DELETE_CONTENT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
