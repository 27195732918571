import React from 'react'
import { Button, ButtonGroup, PageHeader, Row, Col } from 'react-bootstrap'
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types'

const host = window.location.hostname === 'localhost' ? 'admin-crewcheck-staging.seachefs.com' : window.location.hostname

class AdonisSync extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      startDisabled: false,
      scrolled: false,
      output: []
    }
    this.outputEndRef = React.createRef()
  }

  setupWebsocketHandlers = (ws) => {
    ws.onopen = () => {
      this.printMessage('Connected to Synchronization Service')
    }

    ws.onclose = () => {
      this.printMessage('Disconnecting from Synchronization Service...')
      this.setState({
        startDisabled: false
      }, () => {
        this.printMessage('Disconnected from Synchronization Service')
      })
    }

    ws.onmessage = (message) => {
      this.printMessage(message.data)
    }

    ws.onerror = () => {
      this.printMessage('Connection Error!')
      this.setState({
        startDisabled: false
      })
    }
  };

  printMessage = (message) => {
    this.setState({
      output: [...this.state.output, message]
    }, () => {
      const { scrolled } = this.state

      if (!scrolled) {
        this.scrollToBottom()
      }
    })
  }

  scrollToBottom = () => {
    this.outputEndRef.current.scrollIntoView({ behavior: 'smooth' })
  };

  handleStartSync = () => {
    const { adonisSyncActions } = this.props

    this.setState({
      output: ['Acquiring authentication token...'],
      startDisabled: true
    })

    adonisSyncActions.fetchToken()
      .then(response => {
        const { response: token } = response
        const ws = new WebSocket(`wss://${host}/sync/ws/sync`, token.token)

        this.printMessage('Acquired authentication token')
        this.printMessage('Connecting to Synchronization Service...')
        this.setupWebsocketHandlers(ws)
      })
      .catch(() => {
        this.printMessage('Failed to acquire authentication token')
        this.setState({
          startDisabled: false
        })
      })
  };

  handleWheel = () => {
    this.setState({
      scrolled: true
    })
  };

  render() {
    const { startDisabled, output } = this.state

    return (
      <div id="adonis-sync">
        <PageHeader>
          Adonis Synchronization
          <ButtonGroup className="actions pull-right">
            <Button onClick={this.handleStartSync} disabled={startDisabled}>Start</Button>
          </ButtonGroup>
        </PageHeader>
        <Row>
          <Col xs={12}>
            <div className="output-wrapper">
              <div className="output" onWheel={() => this.handleWheel}>
                <table>
                  <tbody>
                    {output.map((line, index) => {
                      return (
                        <tr className="output-line" key={uuid()}>
                          <td className="line-number">{index + 1}</td>
                          <td className="line-content">{line}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <span className="output-end" ref={this.outputEndRef} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

AdonisSync.propTypes = {
  adonisSyncActions: PropTypes.object.isRequired
}

export default AdonisSync
