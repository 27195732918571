import React from 'react'
import {Switch, Route, useParams, useLocation, useHistory, useRouteMatch} from 'react-router-dom'
import PropTypes from 'prop-types'
import router, { extendWithProps } from '../../../router'
import TeamShow from './teamShow'
import CrewShow from './crewShow'
import TeamsList from "./list";
import {Routes, useMatch, useNavigate} from "react-router";

const Teams = (props) => {
  if (!props.user.user.Right) {
    return null
  }

  const { privileges } = props.user.user.Right;
  let location = useLocation();
  let navigate = useNavigate();
  const match = useParams();

  return (
    <div id="teams">
      { privileges.teams !== 0 && (
        <Routes>
          <Route exact path="/"  element={<TeamsList {...props} location={location} history={navigate}/>}/>
          <Route path="/:id"  element={<TeamShow {...props} location={location} history={navigate} match={match}/>}/>
          <Route path="/:teamId/:id" element={<CrewShow {...props} location={location} history={navigate} match={useParams()}/>} />
        </Routes>
      )}
    </div>
  )
}

Teams.propTypes = {
  user: PropTypes.object.isRequired
}

export default Teams
