import React from 'react'
import PropTypes from 'prop-types'
import shortId from 'shortid'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { AUDIENCE_TYPES } from '../../constants'

const AudienceSelector = (props) => {
  const audiences = [
    { value: AUDIENCE_TYPES.ALL, label: 'All' },
    { value: AUDIENCE_TYPES.INTERNAL, label: 'Internal' },
    { value: AUDIENCE_TYPES.EXTERNAL, label: 'External' }
  ]

  return (
    <form>
      <FormGroup controlId="contentTypeSelect">
        <ControlLabel>Audience</ControlLabel>
        <FormControl
          componentClass="select"
          placeholder="select"
          onChange={(evt) => props.onChange(evt.target.value)}
          value={props.value}>
          {audiences.map((audience) => (
            <option key={shortId.generate()} value={audience.value}>
              {audience.label}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    </form>
  )
}

AudienceSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}

AudienceSelector.defaultProps = {
  value: AUDIENCE_TYPES.ALL,
  onChange: () => null
}

export default AudienceSelector
