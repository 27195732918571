import React from 'react'
import PropTypes from 'prop-types'
import { Col, ControlLabel, Panel, Row } from 'react-bootstrap'
import Select from 'react-select'
import ApiRequest from '../../../helpers/apiHelper'

class UserInputFilter extends React.Component {
  constructor() {
    super()
    this.state = {
      dropdownData: {},
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      permissionRole: '',
      role: '',
      CustomerId: '',
      VesselIds: '',
      AgencyId: '',
      customers: [],
      vessels: [],
      agencies: []
    }
  }

  componentDidMount = async () => {
    const customers =
      await ApiRequest('/api/customers?orderBy=name&sort=asc').then(res => res.json.entities)
    const vessels =
      await ApiRequest('/api/vessels?orderBy=name&sort=asc').then(res => res.json.entities)
    const agencies =
      await ApiRequest('/api/agencies?orderBy=name&sort=asc').then(res => res.json.entities)
    const dropdownData = await ApiRequest('/api/users/autocomplete-fields').then(res => res.json)
    dropdownData.rolePrivileges = await ApiRequest('/api/rights/').then(res => res.json.entities)
    this.setState({
      dropdownData, customers, agencies, vessels
    })
  };

  getUserRole = (role) => {
    switch (role) {
      case 'ROLE_ADMIN':
        return 'Admin'
      case 'ROLE_CREWMEMBER':
        return 'Crew Member'
      case 'ROLE_CUSTOMER':
        return 'Customer'
      case 'ROLE_AGENCY':
        return 'Manning Agent'
      case 'ROLE_CONTENT_EDITOR':
        return 'Content Editor'
      case 'ROLE_VESSEL':
        return 'Vessel'
      default:
        return role
    }
  }

  onSubmitFilter = (query, clear = true) => {
    const {
      email, firstName, lastName, permissionRole, role, username, VesselIds, CustomerId, AgencyId
    } = this.state
    const { onChange } = this.props
    const data = {
      'filters': {
        username: username && (username.value || ''),
        email: email && (email.value || ''),
        firstName: firstName && (firstName.value || ''),
        lastName: lastName && (lastName.value || ''),
        RightId: permissionRole && (permissionRole.value || ''),
        role: role && (role.value || ''),
        VesselIds: Array.isArray(VesselIds) && VesselIds.length && (VesselIds.map(vessel => vessel.value) || ''),
        CustomerId: Array.isArray(CustomerId) && CustomerId.length && (CustomerId.map(vessel => vessel.value) || ''),
        AgencyId: Array.isArray(AgencyId) && AgencyId.length && (AgencyId.map(vessel => vessel.value) || '')
      }
    }
    onChange(query, data, clear)
  }

  onClearFilters = () => {
    this.setState({
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      permissionRole: '',
      role: '',
      VesselIds: '',
      CustomerId: '',
      AgencyId: ''
    })
  }

  onExcelExport = () => {
    const {
      email, firstName, lastName, permissionRole, role, username, VesselIds, CustomerId, AgencyId
    } = this.state

    const data = {
      'filters': {
        username: username && (username.value || ''),
        email: email && (email.value || ''),
        firstName: firstName && (firstName.value || ''),
        lastName: lastName && (lastName.value || ''),
        RightId: permissionRole && (permissionRole.value || ''),
        role: role && (role.value || ''),
        VesselIds: VesselIds && (VesselIds.value || ''),
        CustomerId: CustomerId && (CustomerId.value || ''),
        AgencyId: AgencyId && (AgencyId.value || '')
      },
      'format': 'xls'
    }

    const anchor = document.createElement('a')

    const token = localStorage.getItem('token')
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')

    return fetch('/api/users/filtered', { method: 'POST', body: JSON.stringify(data), headers })
      .then(response => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby)
        anchor.href = objectUrl
        anchor.download = 'users.xlsx'
        anchor.dispatchEvent(new MouseEvent('click'))
        window.URL.revokeObjectURL(objectUrl)

        return 'users.xlsx'
      })
  }

  onChange = (e, value) => {
    if (value === 'role') {
      this.setState({
        AgencyId: '',
        CustomerId: '',
        VesselIds: ''
      })
    }

    this.setState({ [value]: e })
  };

  createDropdownOption = (options) => {
    return (
      options &&
      options.map((option) => {
        return { label: this.getUserRole(option), value: option }
      })
    )
  };

  createDropdownOptionsForRolePrivileges = (options) => {
    return (
      options &&
      options.map((option) => {
        return { label: option.name, value: option.id }
      })
    )
  };

  sortDropdownOptions = (array) => {
    if (array) {
      return array.sort((a, b) => {
        return a.label.localeCompare(b.label)
      }) 
    }
  }

  createLinkedOption = (options) => {
    return (
      options &&
      options.map((option) => {
        return { label: option.name, value: option.id }
      })
    )
  }

  getLinkedOptions = () => {
    const {
      role, vessels, customers, agencies
    } = this.state

    if (!role) return { label: '', values: [] }

    switch (role.value) {
      case 'ROLE_CUSTOMER':
        return { label: 'CustomerId', values: this.createLinkedOption(customers) }
      case 'ROLE_AGENCY':
        return { label: 'AgencyId', values: this.createLinkedOption(agencies) }
      case 'ROLE_VESSEL':
        return { label: 'VesselIds', values: this.createLinkedOption(vessels) }
      default:
        return { label: '', values: [] }
    }
  }

  getLinkedValueForRole = () => {
    const {
      role, CustomerId, AgencyId, VesselIds
    } = this.state

    if (!role) return ''

    switch (role.value) {
      case 'ROLE_CUSTOMER':
        return CustomerId
      case 'ROLE_AGENCY':
        return AgencyId
      case 'ROLE_VESSEL':
        return VesselIds
      default:
        return ''
    }
  }

  render() {
    const types = [
      'ROLE_VESSEL',
      'ROLE_ADMIN',
      'ROLE_CONTENT_EDITOR',
      'ROLE_CUSTOMER',
      'ROLE_CREWMEMBER',
      'ROLE_AGENCY'
    ]
    const {
      dropdownData: {
        usernames, firstNames, lastNames, emails, rolePrivileges
      },
      username,
      email,
      firstName,
      lastName,
      permissionRole,
      role
    } = this.state

    const { label, values } = this.getLinkedOptions()
    return (
      <Panel style={{ marginBottom: 10 }}>
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Username</ControlLabel>
            <Select
              value={username}
              isClearable
              options={this.createDropdownOption(usernames)}
              onChange={value => this.onChange(value, 'username')}
            />
          </Col>
          <Col xs={12} sm={3}>
            <ControlLabel>Type</ControlLabel>
            <Select isClearable value={role} options={this.sortDropdownOptions(this.createDropdownOption(types))} onChange={value => this.onChange(value, 'role')} />
          </Col>
          <Col xs={12} sm={3}>
            <ControlLabel>Role</ControlLabel>
            <Select isClearable value={permissionRole} options={this.createDropdownOptionsForRolePrivileges(rolePrivileges)} onChange={value => this.onChange(value, 'permissionRole')} />
          </Col>
          <Col xs={12} sm={3}>
            <ControlLabel>Linked</ControlLabel>
            <Select
              isMulti
              isDisabled={values.length === 0}
              isClearable
              value={this.getLinkedValueForRole()}
              options={values}
              onChange={value => this.onChange(value, label)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Last Name</ControlLabel>
            <Select
              isClearable
              value={lastName}
              options={this.createDropdownOption(lastNames)}
              onChange={value => this.onChange(value, 'lastName')}
            />
          </Col>
          <Col xs={12} sm={3}>
            <ControlLabel>First Name</ControlLabel>
            <Select
              isClearable
              value={firstName}
              options={this.createDropdownOption(firstNames)}
              onChange={value => this.onChange(value, 'firstName')}
            />
          </Col>
          <Col xs={12} sm={3}>
            <ControlLabel>Email</ControlLabel>
            <Select isClearable value={email} options={this.createDropdownOption(emails)} onChange={value => this.onChange(value, 'email')} />
          </Col>
        </Row>
      </Panel>
    )
  }
}

UserInputFilter.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  dropdownData: PropTypes.object
}

UserInputFilter.defaultProps = {
  value: '',
  disabled: false,
  dropdownData: {}
}

export default UserInputFilter
