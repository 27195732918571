import React from 'react'
import moment from 'moment'
import MaterialIcon from 'material-icons-react'
import { Button, ButtonGroup, PageHeader } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { showNotificationFailed } from '../../helpers/shared/notification'
import {
  composeQuery,
  getFetchQuery,
  parseQuery, setUrlQueryString
} from '../../helpers/shared/dataTable/dataTableHelper'
import UserRoleBadge from '../../components/shared/dataTable/cells/userRoleBadge'
import DataTable from '../../components/shared/dataTable/dataTable'
import DateCell from '../../components/shared/dataTable/cells/dateCell'

class UsersLoggedInList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true
    }
  }

  componentDidMount() {
    this.fetchLoggedInUsers()
  }

  fetchLoggedInUsers = (query = {}) => {
    const { usersActions, location, history } = this.props
    const { page, limit, sort } = this.props.loggedInUsers
    const parsedQuery = parseQuery(query)

    this.setState({
      loading: true
    })

    usersActions.fetchLoggedInUsers({
      page,
      limit,
      sort,
      ...getFetchQuery(parsedQuery, location.search),
      orderBy: this.getOrderByValue(parsedQuery)
    })
      .then(({ response }) => {
        setUrlQueryString(history, response)
        this.setState({
          data: response.entities,
          pages: Math.ceil(response.totalCount / limit),
          loading: false
        })
      })
      .catch(() => this.showFetchError())
  }

  getOrderByValue = (parsedQuery) => {
    const { location } = this.props
    const fetchQueryOrderBy = getFetchQuery(parsedQuery, location.search).orderBy
    const { loggedInUsersOrderBy } = this.props.loggedInUsers

    if (fetchQueryOrderBy && fetchQueryOrderBy === 'RightId') {
      return 'username'
    } else if (loggedInUsersOrderBy && loggedInUsersOrderBy === 'RightId') {
      return 'username'
    } else if (fetchQueryOrderBy) {
      return fetchQueryOrderBy
    } else if (loggedInUsersOrderBy) {
      return loggedInUsersOrderBy
    }

    return ''
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Users')
    this.setState({
      loading: false
    })
  }

  renderLoggedInIcon = (row) => {
    const sessionLength = 60 // In minutes
    const isSessionValid = row && moment(row.loggedInAt).add(sessionLength, 'minutes').isAfter(moment())

    return (row.loggedIn && isSessionValid ?
      <MaterialIcon icon="check" size="tiny" /> :
      <MaterialIcon icon="close" size="tiny" />)
  }


  handleFilter = (text) => {
    this.fetchLoggedInUsers(composeQuery(text))
  }

  render() {
    const { loggedInUsers } = this.props
    const { data, pages, loading } = this.state
    const columns = [
      {
        Header: 'Username',
        accessor: 'username',
        className: 'left'
      },
      {
        Header: 'First Name',
        className: 'left',
        id: 'firstName',
        accessor: row => row.User.firstName
      },
      {
        Header: 'Last Name',
        className: 'left',
        id: 'lastName',
        accessor: row => row.User.lastName
      },
      {
        Header: 'Email',
        id: 'email',
        className: 'left',
        accessor: row => row.User.email
      },
      {
        Header: 'Type',
        id: 'role',
        accessor: row => <UserRoleBadge user={row.User || {}} />,
        sortable: false
      }, {
        Header: 'Last active',
        id: 'loggedInAt',
        accessor: row => <DateCell date={row.loggedInAt} withTime />
      }, {
        Header: 'Currently logged in',
        id: 'loggedIn',
        accessor: row => this.renderLoggedInIcon(row),
        style: { textAlign: 'center' },
        sortable: false
      }
    ]

    return (
      <div id="users-logged-in-list">
        <PageHeader>
          Logged in Users
          <ButtonGroup className="pull-right hidden-xs">
            <Button onClick={() => this.fetchLoggedInUsers()}>Reload from Server</Button>
          </ButtonGroup>
        </PageHeader>
        <DataTable
          loading={loading}
          data={data}
          columns={columns}
          name="User"
          tableState={{
            ...loggedInUsers,
            pages
          }}
          isFiltered
          onFilter={this.handleFilter}
          onFetch={this.fetchLoggedInUsers}
          configOverrides={{
            className: '-striped data-table'
          }}
          customEmptyResultsMsg="No logged in users"
        />
      </div>
    )
  }
}

UsersLoggedInList.propTypes = {
  loggedInUsers: PropTypes.object.isRequired,
  usersActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default UsersLoggedInList
