import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

class Activities extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { activities } = this.props

    return (
      <div id="activities">
        <h4>Activities</h4>

        <Table responsive>
          <thead>
            <tr>
              <th>Type</th>
              <th>Code</th>
              <th>Code Text</th>
              <th>Vessel</th>
              <th>Position</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Estimated end date</th>
              <th>Disembarking Reason</th>
            </tr>
          </thead>
          <tbody>
            {activities.length === 0 ? (
              <tr>
                <td>
                  <i>No entries</i>
                </td>
              </tr>
            ) : (
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

Activities.propTypes = {
  activities: PropTypes.array
}

Activities.defaultProps = {
  activities: []
}

export default Activities
