import React from 'react'
import MaterialIcon from 'material-icons-react'
import { Form, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap'

import { isValidPassword } from '../helpers/validators'

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: {
        value: '',
        touched: false
      }
    }
  }

  isValid = () => {
    return isValidPassword(this.state.password.value)
  }

  formValues = () => ({
    password: this.state.password.value
  })

  touchAllFields = () => {
    this.setState({
      password: {
        touched: true,
        value: this.state.password.value
      }
    })
  }

  isPasswordValid = () => (this.state.password.touched ? isValidPassword(this.state.password.value) : true)


  onValueChange = (event) => {
    const { name, value } = event.target

    this.setState({
      [name]: {
        ...this.state[name],
        value: value
      }
    })
  }

  onTouched = (event) => {
    const { name } = event.target

    this.setState({
      [name]: {
        ...this.state[name],
        touched: true
      }
    })
  }

  render() {
    const { password } = this.state

    return (
      <Form>
        <FormGroup validationState={this.isPasswordValid() ? null : 'error'}>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="text"
            placeholder="Initial Password"
            value={password.value}
            name="password"
            onChange={this.onValueChange}
            onBlur={this.onTouched}
          />
          <HelpBlock className={this.isPasswordValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
              Password does not match complexity requirements
          </HelpBlock>
        </FormGroup>
      </Form>
    )
  }
}

export default ResetPasswordForm
