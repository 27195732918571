import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PageHeader } from 'react-bootstrap'
import { ModalTypes } from '../../components/shared/modal'
import EditRestrictionsForm from '../../components/editRestrictionsForm'
import { showNotificationFailed, showNotificationSuccess } from '../../helpers/shared/notification'
import {
  composeQuery,
  getFetchQuery,
  parseQuery, setUrlQueryString
} from '../../helpers/shared/dataTable/dataTableHelper'
import { RESTRICTION_ROLES } from '../../constants'
import DataTable from '../../components/shared/dataTable/dataTable'
import DocumentTypeRestrictionCell from '../../components/shared/dataTable/cells/documentTypeRestrictionCell'
import DocumentTypeCell from '../../components/shared/dataTable/cells/documentTypeCell'
import apiRequest from '../../helpers/apiHelper'

class ManageRestrictions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true,
      parsedQuery: {},
      role: {
        value: '',
        touched: false
      }
    }
  }


  componentDidMount() {
    this.fetchDocumentTypes()
  }

  fetchDocumentTypes = (query = {}) => {
    const { docTypesActions, location, history } = this.props
    const { page, limit, sort, orderBy, filter } = this.props.docTypes
    const { parsedQuery } = this.state

    this.setState({
      loading: true,
      parsedQuery: {
        ...parsedQuery,
        ...parseQuery(query)
      }
    }, () => {
      docTypesActions.fetchDocTypes({
        page,
        limit,
        sort,
        orderBy,
        filter,
        ...getFetchQuery(this.state.parsedQuery, location.search)
      })
        .then(({ response }) => {
          setUrlQueryString(history, response)
          this.setState({
            data: response.entities,
            pages: Math.ceil(response.totalCount / limit),
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch document types')
    this.setState({
      loading: false
    })
  }

  onValueChange = (event) => {
    const { name, value } = event.target

    this.setState({
      role: {
        value: value,
        touched: true
      }
    })
  }

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (!this.state.role.value) {
          showNotificationFailed(this.props.notificationsActions, 'Please select role to change permission')
          return
        }
        if (event.target.href) {
          return
        }
        this.props.modalsActions.showModal(ModalTypes.form, {
          headline: `Edit ${rowInfo.original.name} restrictions`,
          formcomp: EditRestrictionsForm,
          formprops: {
            // Use sensible defaults if doc code lacks restrictions config
            role: this.state.role,
            restrictions: rowInfo.original.restrictions || {
              [RESTRICTION_ROLES.ADMIN]: 2,
              [RESTRICTION_ROLES.AGENCY]: 2,
              [RESTRICTION_ROLES.VESSEL]: 2,
              [RESTRICTION_ROLES.CUSTOMER]: 1,
              [RESTRICTION_ROLES.CREWMEMBER]: 1
            }
          },
          onSubmit: (values) => {
            this.props.docTypesActions.updateRestrictions(rowInfo.original.id, values.restrictions)
              .then((response) => {
                if (response.type === 'UPDATE_RESTRICTIONS_SUCCESS') {
                  showNotificationSuccess(this.props.notificationsActions, 'Updated restrictions')
                  this.fetchDocumentTypes()
                } else {
                  showNotificationFailed(this.props.notificationsActions, 'Could not update restrictions')
                }
              })
          }
        })
      }
    }
  }

  handleFilter = (text) => {
    this.fetchDocumentTypes(composeQuery(text))
  }

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState({
      parsedQuery: {
        ...parsedQuery,
        filter: ''
      }
    }, () => this.fetchDocumentTypes())
  }

  loadRoles = () => {
    return new Promise(async (resolve) => {
      const permissionRoles = await apiRequest('/api/rights').then(res => res.json.entities)
      const options = permissionRoles.map(permissionRole => ({
        label: permissionRole.name,
        value: permissionRole.id
      }))
      resolve(options)
    })
  }

  render() {
    const { docTypes } = this.props
    const { data, pages, loading, parsedQuery: { filter } } = this.state

    const columns = [{
      Header: 'Code',
      accessor: 'code'
    }, {
      Header: 'Name',
      id: 'name',
      accessor: row => <DocumentTypeCell documentType={row} />,
      minWidth: 200
    }, {
      Header: 'Type',
      accessor: 'type'
    }, {
      Header: 'Purpose',
      id: 'purpose',
      accessor: row => row.purpose || '-'
    }]

    if (this.state.role.value) {
      columns.push({
        Header: this.state.role.value.label,
        id: `restriction_${this.state.role.value.label}`,
        accessor: row => <DocumentTypeRestrictionCell restrictions={row.restrictions ? row.restrictions[this.state.role.value.value] : undefined} />,
        sortable: false
      })
    }

    return (
      <div id="restrictions-list">
        <PageHeader>
          <Link to="/users">Users</Link> / Manage restrictions
        </PageHeader>
        <DataTable
          enableDropdown
          loadDropdownItems={this.loadRoles}
          dropdownState={this.state.role}
          onDropdownChange={this.onValueChange}
          filter={filter}
          loading={loading}
          data={data}
          columns={columns}
          name="Code"
          tableState={{
            ...docTypes,
            pages
          }}
          isFiltered
          onFetch={this.fetchDocumentTypes}
          onFilter={this.handleFilter}
          onClearFilter={this.handleClearFilter}
          onRowClick={this.handleRowClick}
          customEmptyResultsMsg="No document types matching your criteria"
        />
      </div>
    )
  }
}

ManageRestrictions.propTypes = {
  notificationsActions: PropTypes.object.isRequired,
  docTypes: PropTypes.object.isRequired,
  docTypesActions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  modalsActions: PropTypes.object.isRequired
}

export default ManageRestrictions
