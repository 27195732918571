import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'

class PublishedBadge extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { published } = this.props
    return published ?
      (<Label bsStyle="success">Published</Label>) :
      (<Label bsStyle="warning">not Published</Label>)
  }
}

PublishedBadge.defaultProps = {
  published: false
}

PublishedBadge.propTypes = {
  published: PropTypes.bool
}

export default PublishedBadge
