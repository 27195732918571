import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'

class ManningPoolBadge extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { manningPool } = this.props
    return manningPool ?
      (<Label bsStyle="info">{manningPool.name}</Label>) :
      (<Label bsStyle="warning">no Manning pool</Label>)
  }
}

ManningPoolBadge.defaultProps = {
  manningPool: {}
}

ManningPoolBadge.propTypes = {
  manningPool: PropTypes.object
}

export default ManningPoolBadge
