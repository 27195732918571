import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, PageHeader, Button, ButtonGroup, Panel } from 'react-bootstrap'
import ContentForm, { ContentTypeSelector, AudienceSelector } from '../../components/contentForm'
import { CREATE_CONTENT_SUCCESS } from '../../actions/contents'
import { CONTENT_TYPES, AUDIENCE_TYPES } from '../../constants'
import { showNotificationSuccess } from '../../helpers/shared/notification'
import LoadingSpinner from '../../components/shared/loadingSpinner'
import {
  renderCustomersSelect,
  renderAgenciesSelect,
  renderTeamsSelect,
  renderCrewsSelect,
  renderVesselsSelect
} from './index'

class ContentsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      saving: false,
      contentType: CONTENT_TYPES.NEWS,
      audience: AUDIENCE_TYPES.ALL,
      selectedCustomers: [],
      selectedAgencies: [],
      selectedTeams: [],
      selectedCrews: [],
      selectedVessels: []
    }
  }

  handleContentSave = (published = false) => {
    const body = new FormData()
    body.append('published', published)
    body.append('contentType', this.state.contentType)
    body.append('audience', this.state.audience)
    body.append('customersIds', this.state.selectedCustomers.map((c) => c.value))
    body.append('agenciesIds', this.state.selectedAgencies.map((a) => a.value))
    body.append('teamsIds', this.state.selectedTeams.map((a) => a.value))
    body.append('crewsIds', this.state.selectedCrews.map((a) => a.value))
    body.append('vesselsIds', this.state.selectedVessels.map((a) => a.value))

    const formContent = this.form.getContent()
    Object.keys(formContent).forEach((key) => {
      if (key === 'content') {
        body.append(key, JSON.stringify(formContent[key]))
      } else {
        body.append(key, formContent[key])
      }
    })

    this.setState({ saving: true })
    this.props.contentsActions.createContent(body).then((res) => {
      this.setState({ saving: false })
      if (res.type === CREATE_CONTENT_SUCCESS) {
        showNotificationSuccess(this.props.notificationsActions, 'Created Content Post')
        this.props.history('/content')
      }
    })
  }

  handleCancel = () => {
    const { history } = this.props
    history('/content')
  }

  render() {
    return (
      <div id="contents-new">
        {this.state.saving && (
          <LoadingSpinner text="Saving content & uploading file" />
        )}
        <PageHeader>
          <Link to="/content">Content</Link> / New
        </PageHeader>
        <Row>
          <Col xs={12}>
            <Panel>
              <Row>
                <Col xs={12} sm={3}>
                  <ContentTypeSelector
                    className="ContentTypeSelector"
                    value={this.state.contentType}
                    onChange={(contentType) => this.setState({ contentType })}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <AudienceSelector
                    className="AudienceSelector"
                    value={this.state.audience}
                    onChange={(audience) => this.setState({ audience })}
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          {renderCustomersSelect(this.state, this.setState.bind(this))}
          {renderAgenciesSelect(this.state, this.setState.bind(this))}
          {renderTeamsSelect(this.state, this.setState.bind(this))}
          {renderCrewsSelect(this.state, this.setState.bind(this))}
          {renderVesselsSelect(this.state, this.setState.bind(this))}
        </Row>
        <Row>
          <Col xs={12}>
            <ContentForm
              ref={(el) => (this.form = el)}
              operation="New"
              contentType={this.state.contentType}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ButtonGroup className="pull-right hidden-xs">
              <Button onClick={() => this.handleContentSave(true)}>Create and Publish</Button>
              <Button bsStyle="primary" onClick={() => this.handleContentSave()}>
                Create
              </Button>
            </ButtonGroup>
            <ButtonGroup block vertical className="pull-right visible-xs-block">
              <Button onClick={() => this.handleContentSave(true)}>Create and Publish</Button>
              <Button bsStyle="primary" onClick={() => this.handleContentSave()}>
                Create
              </Button>
              <Button bsStyle="warning" onClick={this.handleCancel}>Cancel</Button>
            </ButtonGroup>
            <Button className="hidden-xs" bsStyle="warning" onClick={this.handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </div>
    )
  }
}

ContentsNew.propTypes = {
  history: PropTypes.object.isRequired,
  contentsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default ContentsNew
