import {
  FETCH_CREW,
  FETCH_CREW_SUCCESS,
  FETCH_CREW_FAILURE
} from '../actions/crews'

const defaultState = {
  page: 1,
  limit: 20,
  offset: 0,
  orderBy: 'PIN',
  sort: 'DESC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function crewReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CREW: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case FETCH_CREW_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response.CrewMembers
      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        didFetch: false,
        isFetching: false,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }
    case FETCH_CREW_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}
