import {createStore, compose, applyMiddleware} from 'redux'
import {thunk} from 'redux-thunk'
import rootReducer from './reducers'
import apiMiddleware from './middleware/apiMiddleware'

export default function configureStore(initialState) {
    const finalCreateStore = compose(
        applyMiddleware(thunk, apiMiddleware),
        typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    )(createStore)

    return finalCreateStore(rootReducer, initialState)
}
