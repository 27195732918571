import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PageHeader, Button, ButtonGroup } from 'react-bootstrap'
import {
  composeQuery,
  getFetchQuery,
  parseQuery,
  setUrlQueryString
} from '../../helpers/shared/dataTable/dataTableHelper'
import { showNotificationFailed } from '../../helpers/shared/notification'
import DataTable from '../../components/shared/dataTable/dataTable'
import PublishedBadge from '../../components/shared/dataTable/cells/publishedBadge'
import DateCell from '../../../../shared/components/dataTable/cells/dateCell'
import ContentTypeBadge from '../../components/shared/dataTable/cells/contentTypeBadge'

class ContentsList extends React.Component {
  static columns = [
    {
      Header: 'Id',
      accessor: 'id'
    }, {
      Header: 'Headline',
      accessor: 'headline'
    }, {
      Header: 'Intro',
      accessor: 'lead'
    }, {
      Header: 'Published',
      id: 'published',
      accessor: row => <PublishedBadge published={row.published} />
    }, {
      Header: 'Content Type',
      id: 'contentType',
      accessor: row => <ContentTypeBadge contentType={row.contentType} />
    }, {
      Header: 'Audience',
      accessor: 'audience'
    }, {
      Header: 'Created at',
      id: 'createdAt',
      accessor: row => <DateCell date={row.createdAt} />
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true,
      parsedQuery: {}
    }
  }

  componentDidMount() {
    this.fetchContents()
  }

  fetchContents = (query = {}) => {
    const { contentsActions, location, history } = this.props
    const { page, limit, sort, orderBy, filter } = this.props.contents
    const { parsedQuery } = this.state

    this.setState({
      loading: true,
      parsedQuery: {
        ...parsedQuery,
        ...parseQuery(query)
      }
    }, () => {
      contentsActions.fetchContents({
        page,
        limit,
        sort,
        orderBy,
        filter,
        ...getFetchQuery(this.state.parsedQuery, location.search)
      })
        .then(({ response }) => {
          setUrlQueryString(history, response)
          this.setState({
            data: response.entities,
            pages: Math.ceil(response.totalCount / limit),
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  handleFilter = (text) => {
    this.fetchContents(composeQuery(text))
  }

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState({
      parsedQuery: {
        ...parsedQuery,
        filter: ''
      }
    }, () => this.fetchContents())
  }

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/content/${rowInfo.original.id}/edit`)
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Contents')
    this.setState({
      loading: false
    })
  }

  render() {
    const { contents } = this.props
    const { data, pages, loading, parsedQuery: { filter } } = this.state

    if (!this.props.user.user.Right) {
      return null
    }

    const { privileges } = this.props.user.user.Right

    return (
      <div id="contents-list">
        <PageHeader>
          Content
          <ButtonGroup className="actions pull-right hidden-xs">
            <Button bsStyle="info" onClick={() => this.fetchContents()}>
              Reload from Server
            </Button>
            {privileges.content === 2 && (
              <Link to="/content/new" className="btn btn-primary">
                Create New
              </Link>
            )}
          </ButtonGroup>
          <ButtonGroup vertical block className="actions visible-xs-block">
            <Button bsStyle="info" onClick={() => this.fetchContents()}>
              Reload from Server
            </Button>
            {privileges.content === 2 && (
              <Link to="/content/new" className="btn btn-primary">
                Create New
              </Link>
            )}
          </ButtonGroup>
        </PageHeader>
        <DataTable
          filter={filter}
          loading={loading}
          data={data}
          columns={ContentsList.columns}
          name="Content"
          tableState={{
            ...contents,
            pages
          }}
          isFiltered
          onFetch={this.fetchContents}
          onFilter={this.handleFilter}
          onClearFilter={this.handleClearFilter}
          onRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

ContentsList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  contents: PropTypes.object.isRequired,
  contentsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default ContentsList
