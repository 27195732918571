import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, ControlLabel } from 'react-bootstrap'
import { renderValue } from '../shared/formHelper'

class Airports extends React.Component {
  constructor() {
    super()
  }

  buildAirportName = (airport) => {
    if (!airport) return null

    return (
      <span>
        {airport.name} <span className="bold">{airport.iata}</span>
      </span>
    )
  }

  render() {
    const { Airport, AirportSecondary } = this.props.crewMember

    return (
      <div id="airports">
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Primary Airport</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(this.buildAirportName(Airport))}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Secondary Airport</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(this.buildAirportName(AirportSecondary))}
          </Col>
        </Row>
      </div>
    )
  }
}

Airports.propTypes = {
  crewMember: PropTypes.object.isRequired
}

export default Airports
