import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, FormControl, FormGroup } from 'react-bootstrap'
import * as Cognito from '../../../helpers/shared/cognito/cognito'
import { showNotificationSuccess, showNotificationFailed } from '../../../helpers/shared/notification'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

class MfaSms extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phoneNumber: '',
      phoneInput: '',
      verificationCode: '',
      verificationCodeSent: false,
      isSubmitting: false
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handlePhoneNumber = () => (status, value, countryData, number, id) => {
    this.setState({phoneNumber: number.replace(/[()-\s]/g, ''), phoneInput: value})
  };
  
  startNumberVerification = () => {
    const { phoneNumber } = this.state
    const { user: { user: { cognitoUser } } } = this.props

    this.setState({
      isSubmitting: true
    })

    if (this.validatePhoneNumber(phoneNumber)) {
      const attributes = { 'phone_number': phoneNumber }

      Cognito.setAttributes(attributes)
        .then(() => {
          Cognito.verifyAttribute(cognitoUser, 'phone_number')
            .then(() => {
              this.setState({
                verificationCodeSent: true
              })
            })
            .catch(() => {
              this.setState({
                verificationCodeSent: true
              }, () => {
                showNotificationFailed(this.props.notificationsActions, 'Invalid phone number format')
              })
            })
            .finally(() => {
              this.setState({
                isSubmitting: false
              })
            })
        })
        .catch(() => {
          showNotificationFailed(this.props.notificationsActions, 'Invalid phone number format')
        })
        .finally(() => {
          this.setState({
            isSubmitting: false
          })
        })
    } else {
      showNotificationFailed(this.props.notificationsActions, 'Invalid phone number format')
      this.setState({
        isSubmitting: false
      })
    }
  }

  completeNumberVerification = () => {
    const { verificationCode } = this.state
    const {
      setMfaType,
      userActions,
      user: {
        user,
        user: { cognitoUser }
      },
      mfaTypes
    } = this.props

    this.setState({
      isSubmitting: true
    })

    if (verificationCode) {
      Cognito.completeAttributeVerification(cognitoUser, 'phone_number', verificationCode)
        .then(() => {
          setMfaType(mfaTypes.SMS)
            .then((response) => {
              const success = !!response
              userActions.setSession()
                .then(() => {
                  userActions.setUser({
                    ...user,
                    requiresMfaSetup: !success
                  }, success)
                })
            })
        })
        .catch((res) => {
          showNotificationFailed(this.props.notificationsActions, 'Invalid verification code')
        })
        .finally(() => {
          this.setState({
            isSubmitting: false
          })
        })
    } else {
      showNotificationFailed(this.props.notificationsActions, 'Verification code is required')
      this.setState({
        isSubmitting: false
      })
    }
  }

  validatePhoneNumber = (phoneNumber) => {
    const isNumber = /^[+\d]+(?:[\d-.\s()]*)$/
    return isNumber.test(phoneNumber)
  }

  render() {
    const { verificationCodeSent, isSubmitting } = this.state

    if (verificationCodeSent) {
      return (
        <div>
          <Alert bsStyle="warning">
            <p>Please input the verification code from the SMS message below</p>
            <p>In case you have not received the SMS message, please check the following before contacting customer support:</p>
            <ul className="mfa-options-list">
              <li>Wait at least 10 minutes.</li>
              <li>Check if your phone's memory is not full.</li>
              <li>Check if your network coverage is good.</li>
              <li>If using roaming check if your carrier is not blocking the SMS message delivery. Also, if on pre-paid, check if you have sufficient funds on your account (some carrier charge a fee for delivering an SMS message while using roaming).</li>
            </ul>
          </Alert>
          <FormGroup>
            <FormControl
              type="input"
              value={this.state.verificationCode}
              placeholder="Verification code"
              onChange={this.handleChange}
              name="verificationCode"
            />
          </FormGroup>
          <FormGroup>
            <Button type="button" bsSize="lg" className="fullwidth" onClick={this.completeNumberVerification} disabled={isSubmitting}>
              Verify phone number
            </Button>
          </FormGroup>
        </div>
      )
    }

    return (
      <div>
        <Alert bsStyle="warning">
          <p>In order to send you SMS messages we will need to verify your phone number.</p>
          <p>Please input your phone number below and click Send verification SMS button</p>
        </Alert>
        <FormGroup>
          <IntlTelInput
            containerClassName="intl-tel-input d-block"
            inputClassName="form-control"
            preferredCountries={['de']}
            onPhoneNumberChange={this.handlePhoneNumber()}
            value={this.state.phoneInput}
            fieldName="phoneNumber"
            nationalMode={false}
            separateDialCode={true}
            format={true}
          />
        </FormGroup>
        <FormGroup>
          <Button type="button" bsSize="lg" className="fullwidth" onClick={this.startNumberVerification} disabled={isSubmitting}>
            Send verification SMS
          </Button>
        </FormGroup>
      </div>
    )
  }
}

MfaSms.propTypes = {
  userActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  setMfaType: PropTypes.func.isRequired,
  mfaTypes: PropTypes.object.isRequired
}

export default MfaSms
