import React from 'react'
import PropTypes from 'prop-types'
import { PageHeader, Button, ButtonGroup } from 'react-bootstrap'
import moment from 'moment'

import ApiRequest from '../../helpers/apiHelper'
import { showNotificationSuccess, showNotificationFailed } from '../../helpers/shared/notification'
import { ModalTypes } from '../../components/shared/modal'
import CreateUserSelectRoleForm from '../../components/createUserSelectRoleForm'
import CreateUserForm from '../../components/createUserForm'
import {
  composeQuery,
  getFetchQuery,
  parseQuery,
  setUrlQueryString
} from '../../helpers/shared/dataTable/dataTableHelper'
import UserRoleBadge from '../../components/shared/dataTable/cells/userRoleBadge'
import UserTypeBadge from '../../components/shared/dataTable/cells/userTypeBadge'
import DataTable from '../../components/shared/dataTable/dataTable'

class UsersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      permissionRoles: [],
      pages: null,
      loading: true,
      parsedQuery: {}
    }
  }

  componentDidMount() {
    this.fetchUsers()
  }

  fetchUsers = (query = {}, body = {}, clear = false) => {
    const { usersActions, location, history } = this.props
    const { page, limit, sort, orderBy } = this.props.users
    const { parsedQuery } = this.state

    this.setState(
      {
        loading: true,
        parsedQuery: {
          ...parsedQuery,
          ...parseQuery(query)
        }
      },
      async () => {
        const options = await getFetchQuery(this.state.parsedQuery, location.search)
        const permissionRoles = await ApiRequest('/api/rights/').then(res => res.json.entities)
        if (clear) options.page = 1
        usersActions
          .fetchUsers({
            page,
            limit,
            sort,
            orderBy,
            ...body,
            ...options
          })
          .then(({ response }) => {
            setUrlQueryString(history, response)
            this.setState({
              permissionRoles,
              data: response.entities,
              pages: Math.ceil(response.totalCount / limit),
              loading: false
            })
          })
          .catch(() => this.showFetchError())
      }
    )
  };

  showFetchError = () => {
    showNotificationFailed(
      this.props.notificationsActions,
      'Unable to fetch Users'
    )
    this.setState({
      loading: false
    })
  };

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/users/${rowInfo.original.id}`)
      }
    }
  };

  getUserPrivilegesRole = (row) => {
    const { permissionRoles } = this.state
    const permissionRoleName = permissionRoles
      .find(permissionRole => permissionRole.id === row.RightId)

    return (<div>{ permissionRoleName ? permissionRoleName.name : '' }</div>)
  }

  handleUserPrivileges = () => {
    this.props.history('/users/role-privileges')
  };

  handleCreateUser = () => {
    this.props.modalsActions.showModal(ModalTypes.form, {
      headline: 'Create User',
      formcomp: CreateUserSelectRoleForm,
      submitButtonText: 'Next',
      onSubmit: (values) => {
        setTimeout(() => this.loadCreateUserForm(values.role, values.type), 500)
      }
    })
  };

  loadCreateUserForm = (role, type) => {
    this.props.modalsActions.showModal(ModalTypes.form, {
      headline: 'Create User',
      formcomp: CreateUserForm,
      formprops: {
        role: role,
        type: type
      },
      onSubmit: (values) => {
        const {
          agenciesActions,
          customersActions,
          usersActions,
          vesselsActions,
          notificationsActions
        } = this.props

        if (values.type.value === 'ROLE_ADMIN') {
          delete values.type
          delete values.AgencyId
          delete values.CustomerId
          delete values.VesselIds
          usersActions.createAdminUser(values)
            .then(() => {
              showNotificationSuccess(notificationsActions, 'Created Admin User')
            })
            .catch(res => showNotificationFailed(notificationsActions, res.error))
        } else if (values.type.value === 'ROLE_CONTENT_EDITOR') {
          delete values.type
          delete values.AgencyId
          delete values.CustomerId
          delete values.VesselIds
          usersActions.createContentEditorUser(values)
            .then(() => {
              showNotificationSuccess(notificationsActions, 'Created Content Editor User')
            })
            .catch(res => showNotificationFailed(notificationsActions, res.error))
        } else if (values.type.value === 'ROLE_CUSTOMER') {
          const payload = { ...values }
          delete payload.type
          delete payload.AgencyId
          delete payload.CustomerId
          delete payload.VesselIds
          customersActions.createCustomerUser(values.CustomerId.value, payload)
            .then(() => {
              showNotificationSuccess(notificationsActions, 'Created Customer User')
            })
            .catch(res => showNotificationFailed(notificationsActions, res.error))
        } else if (values.type.value === 'ROLE_VESSEL') {
          delete values.type
          delete values.AgencyId
          delete values.CustomerId
          vesselsActions.createVesselUser(values)
            .then(() => {
              showNotificationSuccess(notificationsActions, 'Created Vessel User')
            })
            .catch(res => showNotificationFailed(notificationsActions, res.error))
        } else if (values.type.value === 'ROLE_AGENCY') {
          delete values.type
          delete values.CustomerId
          delete values.VesselIds
          agenciesActions.createAgencyUser(values.AgencyId, values)
            .then(() => {
              showNotificationSuccess(notificationsActions, 'Agency User created')
            })
            .catch(res => showNotificationFailed(notificationsActions, res.error))
        }
      }
    })
  };

  handleManageRestrictions = () => {
    this.props.history('/users/manage-restrictions')
  };

  handleFilter = (text) => {
    this.fetchUsers(composeQuery(text))
  };

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState(
      {
        parsedQuery: {
          ...parsedQuery,
          filter: ''
        }
      },
      () => this.fetchUsers()
    )
  };

  render() {
    const columns = [
      {
        Header: 'Username',
        accessor: 'username'
      },
      {
        Header: 'Role',
        id: 'RightId',
        accessor: row => this.getUserPrivilegesRole(row)
      },
      {
        Header: 'Type',
        id: 'role',
        accessor: (row) => <UserRoleBadge user={row} />
      },
      {
        Header: 'Linked',
        id: 'linked',
        sortable: false,
        accessor: (row) => <UserTypeBadge user={row} />
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Date of Birth',
        id: 'dateOfBirth',
        accessor: d => {
          return d.CrewMember && moment(d.CrewMember.dateOfBirth)
            .local()
            .format('DD-MM-YYYY')
        }
      }
    ]
    const { users } = this.props
    const {
      data,
      pages,
      loading,
      parsedQuery: { filter }
    } = this.state

    return (
      <div id="users-list">
        <PageHeader>
          Users
          <ButtonGroup className="actions pull-right hidden-xs">
            <Button onClick={this.handleUserPrivileges}>
              Role privileges
            </Button>
            <Button bsStyle="primary" onClick={this.handleManageRestrictions}>
              Manage restrictions
            </Button>
            <Button bsStyle="info" onClick={this.handleCreateUser}>
              Create User
            </Button>
          </ButtonGroup>
          <ButtonGroup block vertical className="actions visible-xs-block">
            <Button onClick={this.handleUserPrivileges}>
              Role privileges
            </Button>
            <Button onClick={this.handleManageRestrictions}>
              Manage restrictions
            </Button>
            <Button bsStyle="info" onClick={this.handleCreateUser}>
              Create User
            </Button>
          </ButtonGroup>
        </PageHeader>
        <DataTable
          filter={filter}
          loading={loading}
          data={data}
          columns={columns}
          name="User"
          tableState={{
            ...users,
            pages
          }}
          isFiltered={false}
          isUserFiltered
          onFetch={this.fetchUsers}
          onFilter={this.handleFilter}
          onUserFilter={this.fetchUsers}
          onClearFilter={this.handleClearFilter}
          onRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

UsersList.propTypes = {
  notificationsActions: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  usersActions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  modalsActions: PropTypes.object.isRequired,
  agenciesActions: PropTypes.object.isRequired,
  customersActions: PropTypes.object.isRequired,
  vesselsActions: PropTypes.object.isRequired
}

export default UsersList
