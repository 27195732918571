import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

const customer = new schema.Entity('customers', {}, { idAttribute: 'id' })
const customersList = new schema.Array(customer)

/**
 * Async Action Creators
 */

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS'
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE'

export function fetchCustomers(queryParams) {
  let endpoint = '/api/customers'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: customersList,
      normalizeKey: 'entities',
      types: [FETCH_CUSTOMERS, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE]
    }
  }
}

export const CREATE_CUSTOMER_USER = 'CREATE_CUSTOMER_USER'
export const CREATE_CUSTOMER_USER_SUCCESS = 'CREATE_CUSTOMER_USER_SUCCESS'
export const CREATE_CUSTOMER_USER_FAILURE = 'CREATE_CUSTOMER_USER_FAILURE'

export function createCustomerUser(customerId, credentials) {
  const endpoint = `/api/customers/${customerId}/users`

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      body: credentials,
      types: [CREATE_CUSTOMER_USER, CREATE_CUSTOMER_USER_SUCCESS, CREATE_CUSTOMER_USER_FAILURE]
    }
  }
}

export function fetchCustomersIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.customers.isFetching && !state.customers.didFetch) {
      return dispatch(fetchCustomers(queryParams))
    }
  }
}

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER'
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE'

export function fetchCustomerById(id) {
  const endpoint = `/api/customers/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: customer,
      types: [FETCH_CUSTOMER, FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_FAILURE]
    }
  }
}

export const SET_CUSTOMER_USERS_PAGE = 'SET_CUSTOMER_USERS_PAGE'

export function setCustomerUsersPage(page) {
  return { type: SET_CUSTOMER_USERS_PAGE, page }
}

export const FETCH_ADONIS_CUSTOMERS = 'FETCH_ADONIS_CUSTOMERS'
export const FETCH_ADONIS_CUSTOMERS_SUCCESS = 'FETCH_ADONIS_CUSTOMERS_SUCCESS'
export const FETCH_ADONIS_CUSTOMERS_FAILURE = 'FETCH_ADONIS_CUSTOMERS_FAILURE'

export function fetchCustomerFromAdonis() {
  const endpoint = '/api/sync/customers'

  return {
    [CALL_API]: {
      endpoint,
      method: 'put',
      types: [FETCH_ADONIS_CUSTOMERS, FETCH_ADONIS_CUSTOMERS_SUCCESS, FETCH_ADONIS_CUSTOMERS_FAILURE]
    }
  }
}

/**
 * Sync Actions
 */

export const RESET_CUSTOMERS_STATUS = 'RESET_CUSTOMERS_STATUS'

export function reset() {
  return {
    type: RESET_CUSTOMERS_STATUS
  }
}
