import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, PageHeader, Button, ButtonGroup, Panel } from 'react-bootstrap'
import ContentForm, { ContentTypeSelector, AudienceSelector } from '../../components/contentForm'
import { UPDATE_CONTENT_SUCCESS, DELETE_CONTENT_SUCCESS } from '../../actions/contents'
import { CONTENT_TYPES, AUDIENCE_TYPES } from '../../constants'
import { ModalTypes } from '../../components/shared/modal'
import { showNotificationSuccess, showNotificationFailed } from '../../helpers/shared/notification'
import LoadingSpinner from '../../components/shared/loadingSpinner'
import {
  renderCustomersSelect,
  renderAgenciesSelect,
  renderTeamsSelect,
  renderCrewsSelect,
  renderVesselsSelect
} from './index'

class ContentsEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      saving: false
    }
  }

  justDeleted = false;

  initState = (content) => {
    if (this.state.contentType) return

    this.setState({
      contentType: content ? content.contentType : CONTENT_TYPES.NEWS,
      audience: content ? content.audience : AUDIENCE_TYPES.ALL,
      selectedCustomers: content && content.Customers
        ? content.Customers.map((c) => ({ value: c.id, label: c.name }))
        : [],
      selectedAgencies: content && content.Agencies
        ? content.Agencies.map((a) => ({ value: a.id, label: a.name }))
        : [],
      selectedTeams: content && content.Teams
        ? content.Teams.map((c) => ({ value: c.id, label: c.name }))
        : [],
      selectedCrews: content && content.Crews
        ? content.Crews.map((c) => ({ value: c.id, label: c.name }))
        : [],
      selectedVessels: content && content.Vessels
        ? content.Vessels.map((c) => ({ value: c.id, label: c.name }))
        : []
    })
  }

  handleContentUpdate = (published = false) => {
    const body = new FormData()
    body.append('published', published)
    body.append('contentType', this.state.contentType)
    body.append('audience', this.state.audience)
    body.append('customersIds', this.state.selectedCustomers.map((c) => c.value))
    body.append('agenciesIds', this.state.selectedAgencies.map((a) => a.value))
    body.append('teamsIds', this.state.selectedTeams.map((a) => a.value))
    body.append('crewsIds', this.state.selectedCrews.map((a) => a.value))
    body.append('vesselsIds', this.state.selectedVessels.map((a) => a.value))

    const formContent = this.form.getContent()
    Object.keys(formContent).forEach((key) => {
      if (key === 'content') {
        body.append(key, JSON.stringify(formContent[key]))
      } else {
        body.append(key, formContent[key])
      }
    })

    const id  = this.props.match['*'].split('/')[0]
    this.setState({ saving: true })
    this.props.contentsActions.updateContent(id, body).then((res) => {
      this.setState({ saving: false })
      if (res.type === UPDATE_CONTENT_SUCCESS) {
        const content = this.props.contents.entities[id] || null
        this.form.resetFileState(content ? content.files : [])
        showNotificationSuccess(this.props.notificationsActions, 'Updated Content Post')
      }
    })
  }

  handleDelete = () => {
    this.props.modalsActions.showModal(ModalTypes.confirm, {
      question: 'Are you sure?',
      onConfirm: () => {
        this.props.contentsActions.deleteContent(this.props.match['*'].split('/')[0]).then((res) => {
          if (res.type === DELETE_CONTENT_SUCCESS) {
            showNotificationSuccess(this.props.notificationsActions, 'Deleted Content Post')
            this.justDeleted = true;
            this.props.history('/content')
          } else {
            showNotificationFailed(this.props.notificationsActions, 'Unable to delete Content Post')
          }

          this.props.modalsActions.hideModal()
        })
      }
    })
  }

  handleCancel = () => {
    const { history } = this.props
    history('/content')
  }

  render() {
    const id = this.props.match['*'].split('/')[0];
    let content = null;
    try {
      content = this.props.contents.entities[id]
    }catch (err){
      console.log('ERR', err);
    }


    if (!content && !this.props.contents.didFetch && !this.justDeleted) {
      this.props.contentsActions.fetchContentById(id).then(() => {
        content = this.props.contents.entities[id] || null
        this.initState(content)
      }).catch((err) => {
        console.log('ERROR', err);
      })
      return null
    }

    if (!content) {
      return null
    }

    this.initState(content)

    return (
      <div id="contents-edit">
        {this.state.saving && (
          <LoadingSpinner text="Saving content & uploading file" />
        )}
        <PageHeader>
          <Link to="/content">Content</Link> / {content.headline} edit
        </PageHeader>
        <Row>
          <Col xs={12}>
            <Panel>
              <Row>
                <Col xs={12} sm={3}>
                  <ContentTypeSelector
                    className="ContentTypeSelector"
                    value={this.state.contentType}
                    onChange={(contentType) => this.setState({ contentType })}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <AudienceSelector
                    className="AudienceSelector"
                    value={this.state.audience}
                    onChange={(audience) => this.setState({ audience })}
                  />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          {renderCustomersSelect(this.state, this.setState.bind(this))}
          {renderAgenciesSelect(this.state, this.setState.bind(this))}
          {renderTeamsSelect(this.state, this.setState.bind(this))}
          {renderCrewsSelect(this.state, this.setState.bind(this))}
          {renderVesselsSelect(this.state, this.setState.bind(this))}
        </Row>
        <Row>
          <Col xs={12}>
            <ContentForm
              ref={(el) => (this.form = el)}
              operation="Edit"
              content={content}
              contentType={this.state.contentType}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ButtonGroup className="hidden-xs content-actions">
              <Button bsStyle="danger" onClick={this.handleDelete}>
                Delete
              </Button>
              {content.published ? (
                <Button onClick={() => this.handleContentUpdate(false)}>
                  Update and Unpublish
                </Button>
              ) : (
                <Button onClick={() => this.handleContentUpdate(true)}>Update and Publish</Button>
              )}
              <Button bsStyle="primary" onClick={() => this.handleContentUpdate(content.published)}>
                Update
              </Button>
            </ButtonGroup>
            <ButtonGroup block vertical className="visible-xs-block content-actions">
              <Button bsStyle="danger" onClick={this.handleDelete}>
                Delete
              </Button>
              {content.published ? (
                <Button onClick={() => this.handleContentUpdate(false)}>
                  Update and Unpublish
                </Button>
              ) : (
                <Button onClick={() => this.handleContentUpdate(true)}>Update and Publish</Button>
              )}
              <Button bsStyle="primary" onClick={() => this.handleContentUpdate(content.published)}>
                Update
              </Button>
              <Button bsStyle="warning" onClick={this.handleCancel}>Cancel</Button>
            </ButtonGroup>
            <div className="hidden-xs">
              <Button bsStyle="warning" onClick={this.handleCancel}>Cancel</Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

ContentsEdit.propTypes = {
  match: PropTypes.object.isRequired,
  contents: PropTypes.object.isRequired,
  contentsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  modalsActions: PropTypes.object.isRequired,
  history: PropTypes.func
}

export default ContentsEdit
