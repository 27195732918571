import React from 'react'
import PropTypes from 'prop-types'
import { CONTENT_TYPES } from '../../../../constants'

class ContentTypeBadge extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { contentType } = this.props

    return (
      <span>{ this.getHumanReadableContentType(contentType) }</span>
    )
  }

  getHumanReadableContentType = (contentType) => {
    switch (contentType) {
      case CONTENT_TYPES.JOB_OFFER:
        return 'Forms and documents'
      case CONTENT_TYPES.TRAINING_MATERIALS:
        return 'Training Materials'
      case CONTENT_TYPES.RULES_REGULATIONS:
        return 'Rules and Regulations'
      case CONTENT_TYPES.ITINERARIES:
        return 'Itineraries'
      default:
        return 'News'
    }
  }
}

ContentTypeBadge.propTypes = {
  contentType: PropTypes.string.isRequired
}

export default ContentTypeBadge
