import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

export const confirmType = 'CONFIRM_MODAL'

class ConfirmModal extends React.Component {
  constructor() {
    super()
    this.state = {
      show: true
    }
  }

  close = () => {
    this.setState({ show: false })
    this.props.onExited()
  }

  confirm = () => {
    this.setState({ show: false })
    this.props.onConfirm()
  }

  show = () => this.setState({ show: true })

  render() {
    return (
      <Modal show={this.state.show} onHide={this.close} bsSize="sm" {...this.props}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.question}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirm}>Ok</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

ConfirmModal.propTypes = {
  question: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onConfirm: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired
}

ConfirmModal.defaultProps = {
  question: 'Are you sure?'
}

export default ConfirmModal
