import {
  FETCH_DOC_TYPES,
  FETCH_DOC_TYPES_SUCCESS,
  FETCH_DOC_TYPES_FAILURE,
  UPDATE_RESTRICTIONS,
  UPDATE_RESTRICTIONS_SUCCESS,
  UPDATE_RESTRICTIONS_FAILURE
} from '../actions/docTypes'

const defaultState = {
  page: 1,
  limit: 40,
  offset: 0,
  orderBy: 'code',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function docTypesReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_RESTRICTIONS:
    case FETCH_DOC_TYPES: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case FETCH_DOC_TYPES_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        error: null,
        entities: action.normalized.entities.docTypes || {}
      }
    }

    case UPDATE_RESTRICTIONS_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        error: null,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }

    case UPDATE_RESTRICTIONS_FAILURE:
    case FETCH_DOC_TYPES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}
