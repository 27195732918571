import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

const crewMember = new schema.Entity('crewMembers', {}, { idAttribute: 'PIN' })
const crewMembersList = new schema.Array(crewMember)

/**
 * Async Action Creators
 */

export const FETCH_CREW_MEMBERS = 'FETCH_CREW_MEMBERS'
export const FETCH_CREW_MEMBERS_SUCCESS = 'FETCH_CREW_MEMBERS_SUCCESS'
export const FETCH_CREW_MEMBERS_FAILURE = 'FETCH_CREW_MEMBERS_FAILURE'

export function fetchCrewMembers(queryParams) {
  let endpoint = '/api/crewMembers'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: crewMembersList,
      normalizeKey: 'entities',
      types: [FETCH_CREW_MEMBERS, FETCH_CREW_MEMBERS_SUCCESS, FETCH_CREW_MEMBERS_FAILURE]
    }
  }
}

export function fetchCrewMembersIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.crewMembers.isFetching && !state.crewMembers.didFetch) {
      return dispatch(fetchCrewMembers(queryParams))
    }
  }
}

export const FETCH_CREW_MEMBER = 'FETCH_CREW_MEMBER'
export const FETCH_CREW_MEMBER_SUCCESS = 'FETCH_CREW_MEMBER_SUCCESS'
export const FETCH_CREW_MEMBER_FAILURE = 'FETCH_CREW_MEMBER_FAILURE'

export function fetchCrewMemberByPIN(pin) {
  const endpoint = `/api/crewMembers/${pin}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: crewMember,
      types: [FETCH_CREW_MEMBER, FETCH_CREW_MEMBER_SUCCESS, FETCH_CREW_MEMBER_FAILURE]
    }
  }
}

export const CREATE_CREW_MEMBER_USER = 'CREATE_CREW_MEMBER_USER'
export const CREATE_CREW_MEMBER_USER_SUCCESS = 'CREATE_CREW_MEMBER_USER_SUCCESS'
export const CREATE_CREW_MEMBER_USER_FAILURE = 'CREATE_CREW_MEMBER_USER_FAILURE'

export function createCrewMemberUser(crewMemberId, credentials) {
  const endpoint = `/api/crewMembers/${crewMemberId}/users`

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      body: credentials,
      types: [
        CREATE_CREW_MEMBER_USER,
        CREATE_CREW_MEMBER_USER_SUCCESS,
        CREATE_CREW_MEMBER_USER_FAILURE
      ]
    }
  }
}

export const FETCH_CREW_MEMBER_REGISTRATION_TOKEN = 'FETCH_CREW_MEMBER_REGISTRATION_TOKEN'
export const FETCH_CREW_MEMBER_REGISTRATION_TOKEN_SUCCESS =
  'FETCH_CREW_MEMBER_REGISTRATION_TOKEN_SUCCESS'
export const FETCH_CREW_MEMBER_REGISTRATION_TOKEN_FAILURE = 'FETCH_CREW_MEMBER_REGISTRATION_TOKEN'

export function fetchCrewMemberRegistrationToken(crewMemberId) {
  const endpoint = `/api/crewMembers/${crewMemberId}/registration-token`

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        FETCH_CREW_MEMBER_REGISTRATION_TOKEN,
        FETCH_CREW_MEMBER_REGISTRATION_TOKEN_SUCCESS,
        FETCH_CREW_MEMBER_REGISTRATION_TOKEN_FAILURE
      ]
    }
  }
}

export const FETCH_ADONIS_CREW_MEMBERS = 'FETCH_ADONIS_CREW_MEMBERS'
export const FETCH_ADONIS_CREW_MEMBERS_SUCCESS = 'FETCH_ADONIS_CREW_MEMBERS_SUCCESS'
export const FETCH_ADONIS_CREW_MEMBERS_FAILURE = 'FETCH_ADONIS_CREW_MEMBERS_FAILURE'
export const FETCH_CREW_MEMBERS_RESET = 'FETCH_CREW_MEMBERS_RESET'
