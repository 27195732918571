import {
  FETCH_AGENCIES,
  FETCH_AGENCIES_SUCCESS,
  FETCH_AGENCIES_FAILURE,
  FETCH_AGENCY,
  FETCH_AGENCY_SUCCESS,
  FETCH_AGENCY_FAILURE,
  UPDATE_AGENCY,
  UPDATE_AGENCY_SUCCESS,
  UPDATE_AGENCY_FAILURE,
  SET_AGENCY_USERS_PAGE,
  RESET_AGENCIES_STATUS,
  CREATE_AGENCY_USER,
  CREATE_AGENCY_USER_SUCCESS,
  CREATE_AGENCY_USER_FAILURE
} from '../actions/agencies'

const defaultState = {
  page: 1,
  limit: 10,
  offset: 0,
  orderBy: 'name',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null,
  // Agency Users Pagination
  agencyUsersPage: 1,
  agencyUsersLimit: 20
}

export default function agenciesReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_AGENCY:
    case FETCH_AGENCIES:
    case UPDATE_AGENCY:
    case CREATE_AGENCY_USER: {
      return { ...state, isFetching: true, didFetch: true }
    }

    case FETCH_AGENCIES_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        entities: action.normalized.entities.agencies || {}
      }
    }

    case FETCH_AGENCY_SUCCESS:
    case UPDATE_AGENCY_SUCCESS:
    case CREATE_AGENCY_USER_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        error: null,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }

    case FETCH_AGENCIES_FAILURE:
    case UPDATE_AGENCY_FAILURE:
    case FETCH_AGENCY_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: true,
        error: action.error
      }
    }

    case SET_AGENCY_USERS_PAGE: {
      const agencyUsersPage = action.page
      return { ...state, agencyUsersPage }
    }

    case CREATE_AGENCY_USER_FAILURE: {
      return { ...state, isFetching: false, didFetch: true, error: action.error }
    }

    case RESET_AGENCIES_STATUS: {
      return { ...state, didFetch: false, isFetching: false, error: null, entities: {} }
    }

    default: {
      return state
    }
  }
}
