import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

const vessel = new schema.Entity('vessel', {}, { idAttribute: 'id' })
const vesselList = new schema.Array(vessel)

/**
 * Async Action Creators
 */

export const FETCH_VESSELS = 'FETCH_VESSELS'
export const FETCH_VESSELS_SUCCESS = 'FETCH_VESSELS_SUCCESS'
export const FETCH_VESSELS_FAILURE = 'FETCH_VESSELS_FAILURE'

export function fetchVessels(queryParams) {
  let endpoint = '/api/vessels'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: vesselList,
      normalizeKey: 'entities',
      types: [FETCH_VESSELS, FETCH_VESSELS_SUCCESS, FETCH_VESSELS_FAILURE]
    }
  }
}

export function fetchVesselsIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.vessels.isFetching && !state.vessels.didFetch) {
      return dispatch(fetchVessels(queryParams))
    }
  }
}

export const FETCH_VESSEL = 'FETCH_VESSEL'
export const FETCH_VESSEL_SUCCESS = 'FETCH_VESSEL_SUCCESS'
export const FETCH_VESSEL_FAILURE = 'FETCH_VESSELS_FAILURE'

export function fetchVesselById(id) {
  const endpoint = `/api/vessels/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: vessel,
      types: [FETCH_VESSEL, FETCH_VESSEL_SUCCESS, FETCH_VESSEL_FAILURE]
    }
  }
}

export const FETCH_VESSEL_CREW = 'FETCH_VESSEL_CREW'
export const FETCH_VESSEL_CREW_SUCCESS = 'FETCH_VESSEL_CREW_SUCCESS'
export const FETCH_VESSEL_CREW_FAILURE = 'FETCH_VESSEL_CREW_FAILURE'

export function fetchVesselCrewById(id, queryParams) {
  let endpoint = `/api/vessels/${id}/crew`

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: vessel,
      types: [FETCH_VESSEL_CREW, FETCH_VESSEL_CREW_SUCCESS, FETCH_VESSEL_CREW_FAILURE]
    }
  }
}

export const CREATE_VESSEL_USER = 'CREATE_VESSEL_USER'
export const CREATE_VESSEL_USER_SUCCESS = 'CREATE_VESSEL_USER_SUCCESS'
export const CREATE_VESSEL_USER_FAILURE = 'CREATE_VESSEL_USER_FAILURE'

export function createVesselUser(credentials) {
  const endpoint = '/api/vessels/users'

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      body: credentials,
      types: [CREATE_VESSEL_USER, CREATE_VESSEL_USER_SUCCESS, CREATE_VESSEL_USER_FAILURE]
    }
  }
}
