import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PageHeader, Button, ButtonGroup } from 'react-bootstrap'
import { showNotificationFailed } from '../../helpers/shared/notification'
import {
  composeQuery,
  getFetchQuery,
  parseQuery,
  setUrlQueryString
} from '../../helpers/shared/dataTable/dataTableHelper'
import ManningPoolBadge from '../../../../shared/components/dataTable/cells/manningPoolBadge'
import DataTable from '../../components/shared/dataTable/dataTable'

class CrewMembersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true,
      parsedQuery: {}
    }
  }

  componentDidMount() {
    this.fetchCrewMembers()
  }

  fetchCrewMembers = (query = {}) => {
    const { crewMembersActions, location, history } = this.props
    const { page, limit, sort, orderBy, filter } = this.props.crewMembers
    const { parsedQuery } = this.state

    this.setState({
      loading: true,
      parsedQuery: {
        ...parsedQuery,
        ...parseQuery(query)
      }
    }, () => {
      crewMembersActions.fetchCrewMembers({
        page,
        limit,
        sort,
        orderBy,
        filter,
        ...getFetchQuery(this.state.parsedQuery, location.search)
      })
        .then(({ response }) => {
          setUrlQueryString(history, response)
          this.setState({
            data: response.entities,
            pages: Math.ceil(response.totalCount / limit),
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  handleFilter = (text) => {
    this.fetchCrewMembers(composeQuery(text))
  }

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState({
      parsedQuery: {
        ...parsedQuery,
        filter: ''
      }
    }, () => this.fetchCrewMembers())
  }

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/profiles/${rowInfo.original.PIN}`)
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Agencies')
    this.setState({
      loading: false
    })
  }

  renderTeamCell = (row, hasAccessToViewTeams) => {
    return hasAccessToViewTeams ? <Link to={`/teams/${row.Crew.TeamId}`}>{row.Crew.Team.name}</Link> : row.Crew.Team.name
  }
  renderCrewCell = (row, hasAccessToViewTeams) => {
    return hasAccessToViewTeams ? <Link to={`/teams/${row.Crew.TeamId}/${row.Crew.id}`}>{row.Crew.name}</Link> : row.Crew.name
  }

  render() {
    const { crewMembers } = this.props
    const { data, pages, loading, parsedQuery: { filter } } = this.state
    const hasAccessToViewTeams = this.props.user.user.Right && this.props.user.user.Right.privileges.teams !== 0
    const columns = [
      {
        Header: 'PIN',
        accessor: 'PIN'
      }, {
        Header: 'First name',
        accessor: 'firstname'
      }, {
        Header: 'Last name',
        accessor: 'lastname'
      }, {
        Header: 'Position',
        accessor: 'position'
      }, {
        Header: 'Department',
        id: 'DepartmentId',
        accessor: row => (row.Department ? row.Department.name : '-')
      }, {
        Header: 'Vessel',
        id: 'VesselId',
        accessor: row => (row.Vessel ? <Link to={`/vessels/${row.Vessel.id}`}>{row.Vessel.name}</Link> : '-')
      }, {
        Header: 'Nationality',
        id: 'nationality',
        accessor: row => (row.Nationality ? row.Nationality.label : '-'),
        sortable: false
      }, {
        Header: 'Manning pool',
        id: 'ManningPoolId',
        accessor: row => <ManningPoolBadge manningPool={row.ManningPool} />
      }, {
        Header: 'Team',
        id: 'TeamId',
        accessor: row => (row.Crew && row.Crew.Team ? this.renderTeamCell(row, hasAccessToViewTeams) : '-')
      }, {
        Header: 'Crew',
        id: 'CrewId',
        accessor: row => (row.Crew ? this.renderCrewCell(row, hasAccessToViewTeams) : '-')
      }
    ]

    return (
      <div id="crew-members-list">
        <PageHeader>
          Crew Members
          <ButtonGroup className="actions pull-right hidden-xs">
            <Button onClick={() => this.fetchCrewMembers()}>Reload from Server</Button>
          </ButtonGroup>
          <ButtonGroup block vertical className="actions visible-xs-block">
            <Button onClick={() => this.fetchCrewMembers()}>Reload from Server</Button>
          </ButtonGroup>
        </PageHeader>
        <DataTable
          filter={filter}
          loading={loading}
          data={data}
          columns={columns}
          name="Crew Member"
          tableState={{
            ...crewMembers,
            pages
          }}
          isFiltered
          onFetch={this.fetchCrewMembers}
          onFilter={this.handleFilter}
          onClearFilter={this.handleClearFilter}
          onRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

CrewMembersList.propTypes = {
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  crewMembers: PropTypes.object,
  crewMembersActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

CrewMembersList.defaultProps = {
  crewMembers: { entities: [] }
}

export default CrewMembersList
