import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import MaterialIcon from 'material-icons-react'
import { Button, Col, Form, FormControl, FormGroup, Image, Alert, InputGroup } from 'react-bootstrap'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import * as Cognito from '../../../helpers/shared/cognito/cognito'

class RequiresPasswordUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      oldPasswordHidden: true,
      newPasswordHidden: true,
      newPasswordConfirmHidden: true,
      isSubmitting: false
    }

    this.regex = /((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,80})/
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  toggleShowPassword = (value, state) => this.setState({ [value]: !state })


  validateForm = () => {
    const { oldPassword, newPassword, newPasswordConfirm } = this.state

    if (oldPassword === '' || newPassword === '' || newPasswordConfirm === '') {
      showNotificationFailed(this.props.notificationsActions, 'All fields are required')
      return false
    }

    if (!this.regex.test(newPassword)) {
      showNotificationFailed(this.props.notificationsActions, 'New password must contain at least one Number, one special character (!@#$%^&*) and must be at least 8 characters long')
      return false
    }

    if (newPassword !== newPasswordConfirm) {
      showNotificationFailed(this.props.notificationsActions, 'New password and repeated new password are not equal')
      return false
    }
    return true
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      isSubmitting: true
    })

    if (this.validateForm()) {
      const { oldPassword, newPassword } = this.state
      const { user: { user: { cognitoUser } }, userPasswordChangeSuccessAction } = this.props

      this.props.userActions.comparePass(this.props.user.user.id, oldPassword).then((response) => {
        if (response.response.ok === false) {
          this.setState({
            isSubmitting: false
          })
          showNotificationFailed(this.props.notificationsActions, 'Wrong old password.')
          return
        }

        Cognito.changeInitialPassword(cognitoUser, newPassword)
          .then((cognitoResponse) => {
            this.props.userActions.changePassword(oldPassword, newPassword).then((localResponse) => {
              if (localResponse.type === userPasswordChangeSuccessAction) {
                const requiresPasswordUpdate = false
                const requiresMfaSetup = true
                const user = {
                  ...localResponse.response,
                  requiresPasswordUpdate,
                  requiresMfaSetup,
                  cognitoUser: cognitoResponse
                }
                this.props.userActions.setUser(user)
                sessionStorage.setItem('initp', '')
              } else {
                showNotificationFailed(this.props.notificationsActions, 'Password could not be changed.')
                Cognito.changePassword(newPassword, oldPassword) // Revert password change
              }

              this.setState({
                isSubmitting: false
              })
            })
          })
          .catch((error) => {
            let errorMessage = error && error.message ? error.message : 'Wrong Password.'

            if (error && error.message) {
              errorMessage += ' Your password was NOT changed. Please refresh the page and try again with your initial credentials.'
            }

            this.setState({
              isSubmitting: false
            })

            showNotificationFailed(this.props.notificationsActions, errorMessage)
          })
      })
    }
    this.setState({
      isSubmitting: false
    })
  }

  render() {
    const { user, logo } = this.props
    const {
      isSubmitting, oldPasswordHidden, newPasswordHidden, newPasswordConfirmHidden
    } = this.state

    if (!user.user || !user.user.cognitoUser || user.loggedIn) {
      return <Redirect to="/" />
    }

    if (user && user.user && user.user.requiresMfaSetup) {
      return <Redirect to="/requires-mfa-setup" />
    }

    return (
      <div id="login">
        <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
          <Image src={logo} alt="logo" className="img-centered login-logo" />

          <Alert bsStyle="warning">
            Due to security reasons you are required to change your password.
          </Alert>

          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup controlId="formHorizontalOldPassword">
              <InputGroup>
                <FormControl
                  type={oldPasswordHidden ? 'password' : 'text'}
                  value={this.state.oldPassword}
                  placeholder="Old password"
                  onChange={this.handleChange}
                  name="oldPassword"
                />
                <InputGroup.Addon onClick={() => this.toggleShowPassword('oldPasswordHidden', oldPasswordHidden)}>
                  <MaterialIcon icon={oldPasswordHidden ? 'visibility_off' : 'visibility'} color="black" size="20" />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>

            <FormGroup controlId="formHorizontalNewPassword">
              <InputGroup>
                <FormControl
                  type={newPasswordHidden ? 'password' : 'text'}
                  value={this.state.newPassword}
                  placeholder="New password"
                  onChange={this.handleChange}
                  name="newPassword"
                />
                <InputGroup.Addon onClick={() => this.toggleShowPassword('newPasswordHidden', newPasswordHidden)}>
                  <MaterialIcon icon={newPasswordHidden ? 'visibility_off' : 'visibility'} color="black" size="20" />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>

            <FormGroup controlId="formHorizontalNewPasswordConfirm">
              <InputGroup>
                <FormControl
                  type={newPasswordConfirmHidden ? 'password' : 'text'}
                  value={this.state.newPasswordConfirm}
                  placeholder="Confirm new password"
                  onChange={this.handleChange}
                  name="newPasswordConfirm"
                />
                <InputGroup.Addon onClick={() => this.toggleShowPassword('newPasswordConfirmHidden', newPasswordConfirmHidden)}>
                  <MaterialIcon icon={newPasswordConfirmHidden ? 'visibility_off' : 'visibility'} color="black" size="20" />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Button type="submit" bsSize="lg" className="fullwidth" disabled={isSubmitting}>
                Change password
              </Button>
            </FormGroup>
            <div className="forgot-pwd-btn text-center">
              <span className="sign-in-problems">
                Problems with signing in? Contact: <a href="mailto:support.adonis@seachefs.com">support.adonis@seachefs.com</a>
              </span>
            </div>
          </Form>
        </Col>
      </div>
    )
  }
}

RequiresPasswordUpdate.propTypes = {
  userActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  userPasswordChangeSuccessAction: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
}

export default RequiresPasswordUpdate
