import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import MaterialIcon from 'material-icons-react'

import { Col, Row, ButtonGroup, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap'
import FilterInput from './filterInput'
import UserInputFilter from './userInputFilter'


class DataTable extends React.Component {
  constructor(props) {
    super(props)

    this.filterRef = React.createRef()
  }

  calculatePageSize = () => {
    const { data, tableState: { limit } } = this.props
    return Math.min(limit, data.length)
  }

  getDefaultConfig = pages => ({
    manual: true,
    showPagination: pages > 1,
    showPageSizeOptions: false,
    multiSort: false,
    showPageJump: false,
    className: '-striped -highlight -clickable data-table',
    noDataText: ''
  })
  
  renderExcelButton = () => (
    <Fragment>
      <button
        style={{ borderRadius: 8 }}
        onClick={async () => {
          await this.filterRef.current.onExcelExport()
        }}
        className="btn btn-primary">
          Export to XLSX
      </button>
    </Fragment>
  )

  renderExcelButtonLayout = () => (
    <div>
      <div className="pull-right hidden-xs">
        { this.renderExcelButton() }
      </div>
      <ButtonGroup block vertical className="visible-xs-block m-b-20">
        { this.renderExcelButton() }
      </ButtonGroup>
    </div>)

  renderUserFilter = () => {
    const {
      isUserFiltered,
      name,
      onFetch,
      onClearFilter,
      filter,
      tableState,
      loading,
      data
    } = this.props
    const filterString = !filter && filter !== '' ? tableState.filter : filter

    return isUserFiltered ? (
      <div>
        <UserInputFilter
          value={filterString}
          placeholder={`Search ${name}`}
          onChange={onFetch}
          onClear={onClearFilter}
          disabled={loading}
          data={data}
          ref={this.filterRef}
        />
        
        <Row style={{ margin: '22px 0' }} className="hidden-xs">
          <Col xs={6} style={{ paddingLeft: '0' }}>
            <button
              style={{ borderRadius: 8 }}
              className="btn btn-primary"
              onClick={() => this.filterRef.current.onClearFilters()}
            >
              Clear Filters
            </button>
            <button
              style={{ marginLeft: '10px', borderRadius: 8 }}
              className="btn btn-primary load-button"
              onClick={() => this.filterRef.current.onSubmitFilter()}
            >
              Load
            </button> 
          </Col>
          <Col xs={6} style={{ paddingRight: '0' }}>
            {this.renderExcelButtonLayout()}
          </Col>
        </Row>
        <Row className="visible-xs" style={{ padding: '22px 0' }}>
          <ButtonGroup block vertical>
            <button
              style={{ borderRadius: 8 }}
              className="btn btn-primary"
              onClick={() => this.filterRef.current.onClearFilters()}
            >
              Clear Filters
            </button>
            <button
              style={{ margin: '5px 0', borderRadius: 8 }}
              className="btn btn-primary load-button"
              onClick={() => this.filterRef.current.onSubmitFilter()}
            >
              Load
            </button> 
          </ButtonGroup>
          {this.renderExcelButtonLayout()}
        </Row>
      </div>
    ) : null
  }

  isRoleValid = () => (this.props.dropdownState.touched ? !!this.props.dropdownState.value : true)

  renderFilter = () => {
    const {
      isFiltered,
      name,
      onFilter,
      onClearFilter,
      filter,
      tableState,
      additionalButtons,
      loading
    } = this.props
    const filterString = !filter && filter !== '' ? tableState.filter : filter
    const { dropdownState } = this.props

    return isFiltered ? (
      <Row>
        <Col sm={6} xs={12}>
          <FilterInput
            value={filterString}
            placeholder={`Search ${name}`}
            onChange={onFilter}
            onClear={onClearFilter}
            disabled={loading}
          />
        </Col>
        <Col sm={6} xs={12}>
          {additionalButtons}
        </Col>
        {this.props.enableDropdown && 
        <Col sm={6} xs={12}>
          <AsyncSelect
            placeholder="Select role..."
            className="select-dropdown"
            isClearable
            defaultOptions
            value={dropdownState.value}
            loadOptions={this.props.loadDropdownItems}
            filterOption={createFilter()}
            onChange={selectedType => this.props.onDropdownChange({ target: { value: selectedType, name: 'role' } })}
            onBlur={this.onTouched}
            name="role"
          />
          <HelpBlock className={this.isRoleValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="error" />
              Please select role
          </HelpBlock>
        </Col>}
      </Row>
    ) : null
  }

  render() {
    const {
      loading,
      data,
      columns,
      name,
      tableState: {
        pages, page, orderBy, sort
      },
      onFetch,
      onRowClick,
      isUserFiltered,
      configOverrides,
      customEmptyResultsMsg
    } = this.props
    const trProps = {}

    if (onRowClick) {
      trProps.getTrProps = onRowClick
    }
    if (isUserFiltered) {
      return (
        <div className="data-table-container">
          { this.renderFilter() }
          { this.renderUserFilter() }
          <Row>
            <Col xs={12}>
              { data.length === 0 && !loading ? (
                <div className="empty-results">{customEmptyResultsMsg || `No ${name.toLowerCase()}s matching your criteria`}</div>
              ) : (
                <ReactTable
                  {...this.getDefaultConfig(pages)}
                  columns={columns}
                  data={data}
                  pages={pages}
                  page={page - 1}
                  loading={loading}
                  pageSize={this.calculatePageSize}
                  defaultSorted={[{
                    id: orderBy,
                    desc: sort.toLowerCase() === 'desc'
                  }]}
                  onPageChange={newPage => this.filterRef.current.onSubmitFilter({ page: newPage }, false)}
                  onSortedChange={sorted => this.filterRef.current.onSubmitFilter({ sorted }, false)}
                  {...trProps}
                  {...configOverrides}
                />
              )}
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div className="data-table-container">
        { this.renderFilter() }
        { this.renderUserFilter() }
        <Row>
          <Col xs={12}>
            { data.length === 0 && !loading ? (
              <div className="empty-results">{customEmptyResultsMsg || `No ${name.toLowerCase()}s matching your criteria`}</div>
            ) : (
              <ReactTable
                {...this.getDefaultConfig(pages)}
                columns={columns}
                data={data}
                pages={pages}
                page={page - 1}
                loading={loading}
                pageSize={this.calculatePageSize}
                defaultSorted={[{
                  id: orderBy,
                  desc: sort.toLowerCase() === 'desc'
                }]}
                onPageChange={newPage => onFetch({ page: newPage })}
                onSortedChange={sorted => onFetch({ sorted })}
                {...trProps}
                {...configOverrides}
              />
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

DataTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  name: PropTypes.string,
  filter: PropTypes.string,
  tableState: PropTypes.object.isRequired,
  isFiltered: PropTypes.bool,
  onFetch: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onFilter: PropTypes.func,
  onUserFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
  configOverrides: PropTypes.object,
  customEmptyResultsMsg: PropTypes.string,
  additionalButtons: PropTypes.object,
  isUserFiltered: PropTypes.bool,
  loadDropdownItems: PropTypes.func,
  dropdownState: PropTypes.object,
  onDropdownChange: PropTypes.func,
  enableDropdown: PropTypes.bool
}

DataTable.defaultProps = {
  data: [],
  name: '',
  filter: null,
  isFiltered: false,
  isUserFiltered: false,
  onRowClick: null,
  onFilter: null,
  onUserFilter: null,
  onClearFilter: null,
  configOverrides: {},
  customEmptyResultsMsg: null,
  additionalButtons: null,
  loadDropdownItems: null,
  dropdownState: {},
  onDropdownChange: null,
  enableDropdown: false
}

export default DataTable
