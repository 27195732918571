import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Checkbox } from 'react-bootstrap'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import { parseQuery } from '../../../helpers/shared/dataTable/dataTableHelper'
import DataTable from '../../../components/shared/dataTable/dataTable'

class CrewsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: null,
      loading: true,
      selectedCrews: []
    }
  }

  componentDidMount() {
    this.fetchCrews()
  }

  fetchCrews = (query = {}) => {
    const { crewsActions, teamId } = this.props
    const { page, limit, sort, orderBy } = this.props.crews
    const parsedQuery = parseQuery(query)

    this.setState({
      loading: true
    }, () => {
      crewsActions.fetchCrewByParentId(teamId, {
        page,
        limit,
        sort,
        orderBy,
        ...parsedQuery
      })
        .then(({ response }) => {
          this.setState({
            data: response.entities,
            pages: Math.ceil(response.totalCount / limit),
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  setSelectedCrew = (crewId) => {
    const { selectedCrews } = this.state
    const { teamsActions } = this.props
    let currentSelectedCrews = []


    if (selectedCrews.includes(crewId)) {
      const includedCrewIndex = selectedCrews.indexOf(crewId)
      currentSelectedCrews = [
        ...selectedCrews.slice(0, includedCrewIndex),
        ...selectedCrews.slice(includedCrewIndex + 1)
      ]
    } else {
      currentSelectedCrews = [
        ...selectedCrews,
        crewId
      ]
    }

    this.setState({
      selectedCrews: currentSelectedCrews
    }, () => {
      teamsActions.setSelectedCrews(currentSelectedCrews)
    })
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Crews')
    this.setState({
      loading: false
    })
  }

  renderCheckbox = (crew) => {
    const { selectedCrews } = this.state

    return (
      <Checkbox
        inline
        value={crew.id}
        bsClass="checkbox"
        style={{ float: 'right', marginRight: '5px' }}
        checked={selectedCrews.includes(crew.id)}
        onChange={() => this.setSelectedCrew(crew.id)}
      />
    )
  }

  render() {
    const { teamId, crews } = this.props
    const { data, pages, loading } = this.state
    const columns = [
      {
        Header: 'Name',
        id: 'name',
        accessor: row => <Link to={`/teams/${teamId}/${row.id}`}>{row.name}</Link>
      }, {
        Header: 'Filter by',
        id: 'filterBy',
        accessor: row => this.renderCheckbox(row),
        width: 60,
        sortable: false
      }
    ]

    return (
      <div id="crews-list">
        <h4>Crews</h4>
        <DataTable
          loading={loading}
          data={data}
          columns={columns}
          tableState={{
            ...crews,
            pages
          }}
          onFetch={this.fetchCrews}
          configOverrides={{
            className: '-striped data-table'
          }}
          customEmptyResultsMsg="This team has no crews defined"
        />
      </div>
    )
  }
}

CrewsList.propTypes = {
  teamId: PropTypes.string.isRequired,
  crews: PropTypes.object.isRequired,
  crewsActions: PropTypes.object.isRequired,
  teamsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default CrewsList
