import React from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from 'material-icons-react'
import { Button, Col, Form, FormControl, FormGroup, Image, InputGroup } from 'react-bootstrap'
import { showNotificationSuccess, showNotificationFailed } from '../helpers/shared/notification'
import {Navigate} from "react-router";

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      passwordHidden: true
    }
  }

  handleUsernameChange = (evt) => this.setState({ username: evt.target.value })

  handlePasswordChange = (evt) => this.setState({ password: evt.target.value })

  toggleShowPassword = () => this.setState({ passwordHidden: !this.state.passwordHidden })

  handleSubmit = (evt) => {
    const { userActions } = this.props

    evt.preventDefault()

    userActions.login(this.state.username, this.state.password)
      .then(() => {
        userActions.setSession()
          .then((res) => {
            showNotificationSuccess(this.props.notificationsActions, 'Logged in')
          })
      })
      .catch((res) => {
        console.log('RES ERROR', res);
        showNotificationFailed(this.props.notificationsActions, res.error)
      })
  }

  render() {
    const { user } = this.props
    const { passwordHidden } = this.state

    if (user.loggedIn) {
      return <Navigate to="/" />
    }

    return (
      <div id="login">
        <Col xs={10} xsOffset={1} sm={6} smOffset={3} md={4} mdOffset={4}>
          <Image src="/assets/images/logo.png" className="img-centered login-logo" />
          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup controlId="formHorizontalEmail">
              <FormControl
                type="input"
                placeholder="Username/ PIN number"
                onChange={this.handleUsernameChange}
              />
            </FormGroup>

            <FormGroup controlId="formHorizontalPassword">
              <InputGroup>
                <FormControl
                  type={passwordHidden ? 'password' : 'text'}
                  placeholder="Password"
                  onChange={this.handlePasswordChange}
                />
                <InputGroup.Addon onClick={() => this.toggleShowPassword()}>
                  <MaterialIcon icon={passwordHidden ? 'visibility_off' : 'visibility'} color="black" size="20" />
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Button type="submit" className="fullwidth" bsSize="lg">
                Sign in
              </Button>
            </FormGroup>
            <div className="forgot-pwd-btn text-center">
              <span className="sign-in-problems">
                Problems with signing in? Contact: <a href="mailto:support.adonis@seachefs.com">support.adonis@seachefs.com</a>
              </span>
            </div>
          </Form>
        </Col>
      </div>
    )
  }
}

Login.propTypes = {
  userActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default Login
