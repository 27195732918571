import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Route, Redirect, useLocation, useParams} from 'react-router-dom'
import {Row, Col, Well, Button, Grid} from 'react-bootstrap'
import {extendWithProps, PrivateRoute} from '../router'
import * as ContentsActions from '../actions/contents'
import * as ModalsAction from '../actions/shared/modals'
import * as UserActions from '../actions/user'
import * as CrewMembersActions from '../actions/crewMembers'
import * as CrewsActions from '../actions/crews'
import * as SyncActions from '../actions/sync'
import * as TeamsActions from '../actions/shared/teams'
import * as CustomersActions from '../actions/customers'
import * as AgenciesActions from '../actions/agencies'
import * as NotificationsActions from '../actions/notifications'
import * as UsersActions from '../actions/users'
import * as DocTypesActions from '../actions/docTypes'
import * as UserRolesActions from '../actions/userRoles'
import * as VesselsActions from '../actions/vessels'
import * as AdonisSyncActions from '../actions/adonisSync'
import {
  CrewMembers,
  Home,
  Admin,
  Login,
  Customers,
  Contents,
  Agencies,
  Vessels,
  Users,
  Teams,
  RequiresPasswordUpdate,
  RequiresMfaSetup,
  Mfa,
  AdonisSync,
  Academy
} from '../pages'
import Modals from '../components/shared/modal'
import Notifications from '../components/notifications'
import Navbar from '../components/navbar'
import {USER_TOKEN, ROLES} from '../constants'
import {Routes, useNavigate} from "react-router";

const Main = (props) =>  {

    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

  const handleLogout = () => {
    props.userActions.logout().then(() => {
      localStorage.setItem(USER_TOKEN, null);
      props.modalsActions.hideModal();
      navigate('/')
    })
  }
    const { loggedIn, user } = props.user

    if (loggedIn && user && user.role !== ROLES.ADMIN && user.role !== ROLES.CONTENT_EDITOR) {
      return (
        <div className="container">
          <Row>
            <br />
            <Col xs={12}>
              <Well>
                Not Allowed!{' '}
                <Button bsStyle="primary" onClick={handleLogout}>
                  Logout
                </Button>
              </Well>
            </Col>
          </Row>
        </div>
      )
    }

    if (!loggedIn) {
      const passwordUpdateRoute = '/requires-password-update'
      const mfaSetupRoute = '/requires-mfa-setup'
      const mfaRoute = '/mfa'
      const logo = '/assets/images/logo.png'

      if (user && user.requiresPasswordUpdate && props.location.pathname !== passwordUpdateRoute) {
        return <Redirect to={passwordUpdateRoute} />
      }

      if (user && user.requiresMfaSetup && props.location.pathname !== mfaSetupRoute) {
        return <Redirect to={mfaSetupRoute} />
      }

            if (user && user.requiresMfa && props.location?.pathname !== mfaRoute) {
                return <Redirect to={mfaRoute}/>
            }
            return (
                <div id="login-page">
                    <Grid>
                        <Routes>
                            <Route
                                exact
                                path={passwordUpdateRoute}
                                render={extendWithProps(RequiresPasswordUpdate, {
                                    ...props,
                                    user: props.user,
                                    userPasswordChangeSuccessAction: UserActions.USER_CHANGE_PWD_SUCCESS,
                                    logo
                                })}
                            />
                            <Route
                                exact
                                path={mfaSetupRoute}
                                render={extendWithProps(RequiresMfaSetup, {
                                    ...props,
                                    user:props.user,
                                    userUpdateMfaSuccessAction: UserActions.UPDATE_USER_MFA_SUCCESS,
                                    logo
                                })}
                                user={user}
                            />
                            <Route
                                exact
                                path={mfaRoute}
                                element={<Mfa logo={logo} user={props.user} userActions={props.userActions} notificationsActions={props.notificationsActions}/>}
                                user={user}
                            />
                            <Route path="/" element={<Login {...props} /> }/>
                        </Routes>
                        <Notifications
                            notification={props.notifications}
                            {...props.notificationsActions}
                        />
                    </Grid>
                </div>
            )
        }

    return (
      <Grid style={{ paddingTop: '60px' }}>
        <Navbar
          user={props.user}
          userActions={props.userActions}
          modalsActions={props.modalsActions}
          history={props.history}
        />

        {/* Routes START */}
        <Routes>
          <Route path="/login" element={<Login {...props} />} />
            <Route exact
                path="/"
                element={<PrivateRoute><Home {...props}/></PrivateRoute>}
            />
            <Route exact
                path="/admin"
                element={
                    <PrivateRoute>
                        <Admin {...props} />
                    </PrivateRoute>
                }
            />
            <Route exact
                path="/content/*"
                element={
                    <PrivateRoute>
                        <Contents {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/profiles/*"
                element={
                    <PrivateRoute>
                        <CrewMembers {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                path="/teams/*"
                element={
                    <PrivateRoute>
                        <Teams {...props}/>
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/customers/*"
                element={
                    <PrivateRoute>
                        <Customers {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/agencies/*"
                element={
                    <PrivateRoute>
                        <Agencies {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/vessels/*"
                element={
                    <PrivateRoute>
                        <Vessels {...props}  />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/users/*"
                element={
                    <PrivateRoute>
                        <Users {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/academy"
                element={
                    <PrivateRoute>
                        <Academy {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/adonis-sync"
                element={
                    <PrivateRoute>
                        <AdonisSync {...props} />
                    </PrivateRoute>
                }
            />
          <Route render={() => <Redirect to="/" />} />
        </Routes>
        {/* Routes END */}

                <Modals
                    modalType={props.modal.modalType}
                    modalProps={props.modal.modalProps}
                    onClose={() => {
                        // wait for transition
                        setTimeout(() => props.modalsActions.hideModal(), 300)
                    }}
                />
                <Notifications
                    notification={props.notifications}
                    {...props.notificationsActions}
                />
            </Grid>
        )
}

Main.propTypes = {
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    usersActions: PropTypes.object.isRequired,
    docTypesActions: PropTypes.object.isRequired,
    loggedInUsers: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    modalsActions: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,
    notificationsActions: PropTypes.object.isRequired,
    history: PropTypes.object,
    location: PropTypes.object
}

function mapStateToProps(state) {
  return {
    contents: state.contents,
    crewMembers: state.crewMembers,
    crew: state.crew,
    crews: state.crews,
    teams: state.teams,
    teamMembers: state.teamMembers,
    customers: state.customers,
    agencies: state.agencies,
    modal: state.modal,
    notifications: state.notifications,
    users: state.users,
    user: state.user,
    loggedInUsers: state.loggedInUsers,
    userRoles: state.userRoles,
    docTypes: state.docTypes,
    vessels: state.vessels,
    vesselCrewMembers: state.vesselCrewMembers,
    adonisSync: state.adonisSync,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    contentsActions: bindActionCreators(ContentsActions, dispatch),
    crewMembersActions: bindActionCreators(CrewMembersActions, dispatch),
    crewsActions: bindActionCreators(CrewsActions, dispatch),
    syncActions: bindActionCreators(SyncActions, dispatch),
    teamsActions: bindActionCreators(TeamsActions, dispatch),
    customersActions: bindActionCreators(CustomersActions, dispatch),
    agenciesActions: bindActionCreators(AgenciesActions, dispatch),
    modalsActions: bindActionCreators(ModalsAction, dispatch),
    notificationsActions: bindActionCreators(NotificationsActions, dispatch),
    usersActions: bindActionCreators(UsersActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    docTypesActions: bindActionCreators(DocTypesActions, dispatch),
    userRolesActions: bindActionCreators(UserRolesActions, dispatch),
    vesselsActions: bindActionCreators(VesselsActions, dispatch),
    adonisSyncActions: bindActionCreators(AdonisSyncActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
