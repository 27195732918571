import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Button, Col, Form, FormControl, FormGroup, Image, Alert } from 'react-bootstrap'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import * as Cognito from '../../../helpers/shared/cognito/cognito'

class Mfa extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mfaCode: ''
    }

    this.mfaTypes = {
      SMS: 'SMS',
      TOTP: 'TOTP'
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  validateForm = () => {
    const { mfaCode } = this.state

    if (mfaCode === '') {
      showNotificationFailed(this.props.notificationsActions, 'You need to input the MFA code')
      return false
    }
    return true
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.validateForm()) {
      const { mfaCode } = this.state
      const { userActions, user: { user, user: { cognitoUser, mfaType } } } = this.props
      const challengeName = {
        [this.mfaTypes.SMS]: 'SMS_MFA',
        [this.mfaTypes.TOTP]: 'SOFTWARE_TOKEN_MFA'
      }

      Cognito.completeMfaChallenge(cognitoUser, mfaCode, challengeName[mfaType])
        .then((cognitoResponse) => {
          const requiresMfa = false

          userActions.setSession()
            .then(() => {
              this.props.userActions.setUser({
                ...user,
                requiresMfa,
                cognitoUser: cognitoResponse
              }, true)
            })
        })
        .catch(() => {
          showNotificationFailed(this.props.notificationsActions, 'Wrong MFA code')
        })
    }
  }

  render() {
    const { user, logo } = this.props
    const alertText = {
      [this.mfaTypes.SMS]: ', that you received in the text message.',
      [this.mfaTypes.TOTP]: ', from your authenticator app.'
    }

    const additionalSmsInfo = (
      <div>
        <p>In case you have not received the SMS message, please check the following before contacting customer support:</p>
        <ul className="mfa-options-list">
          <li>Wait at least 10 minutes.</li>
          <li>Check if your phone{'\''}s memory is not full.</li>
          <li>Check if your network coverage is good.</li>
          <li>If using roaming check if your carrier is not blocking the SMS message delivery. Also, if on pre-paid, check if you have sufficient funds on your account (some carrier charge a fee for delivering an SMS message while using roaming).</li>
        </ul>
      </div>
    )

    if (!user.user || !user.user.cognitoUser || user.loggedIn) {
      return <Redirect to="/" />
    }

    if ((user && user.user && user.user.requiresMfaSetup) || (user && user.user && !user.user.mfaType)) {
      return <Redirect to="/requires-mfa-setup" />
    }

    if (user && user.user && user.user.requiresPasswordUpdate) {
      return <Redirect to="/requires-password-update" />
    }

    return (
      <div id="login">
        <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
          <Image src={logo} alt="logo" className="img-centered login-logo" />

          <Alert bsStyle="warning">
            <p>Please input your MFA code{alertText[user && user.user && user.user.mfaType]}</p>
            {user && user.user && user.user.mfaType === this.mfaTypes.SMS ? additionalSmsInfo : ''}
          </Alert>

          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup controlId="formHorizontalMfaCode">
              <FormControl
                type="input"
                value={this.state.mfaCode}
                placeholder="MFA Code"
                onChange={this.handleChange}
                name="mfaCode"
              />
            </FormGroup>

            <FormGroup>
              <Button type="submit" bsSize="lg" className="fullwidth">
                Sign in
              </Button>
            </FormGroup>
            <div className="forgot-pwd-btn text-center">
              <span className="sign-in-problems">
                Problems with signing in? Contact: <a href="mailto:support.adonis@seachefs.com">support.adonis@seachefs.com</a>
              </span>
            </div>
          </Form>
        </Col>
      </div>
    )
  }
}

Mfa.propTypes = {
  userActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired
}

export default Mfa
