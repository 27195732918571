import React from 'react'
import PropTypes from 'prop-types'
import {Route, Switch, useLocation, useParams} from 'react-router-dom'
import { createFilter } from 'react-select'
import shortId from 'shortid'
import AsyncSelect from 'react-select/async'
import { Col, ControlLabel } from 'react-bootstrap'
import { ProtectedRoute, extendWithProps } from '../../router'
import List from './list'
import New from './new'
import Edit from './edit'
import { AUDIENCE_TYPES } from '../../constants'
import ApiRequest from '../../helpers/apiHelper'
import {Routes, useNavigate} from "react-router";
import TeamsList from "../shared/teams/list";
import TeamShow from "../shared/teams/teamShow";
import CrewShow from "../shared/teams/crewShow";

/**
 * Shared methods
 */

export const renderCustomersSelect = (state, setState) => {
  if (state.audience !== AUDIENCE_TYPES.EXTERNAL) {
    return null
  }

  const loadCustomers = (params) =>
    ApiRequest(`/api/customers?filter=${params}`).then((res) => {
      return res.json.entities.map((entity) => ({ value: entity.id, label: entity.name }))
    })

  return (
    <Col xs={12} sm={6}>
      <ControlLabel>Customers</ControlLabel>
      <AsyncSelect
        name="form-field-customers"
        loadOptions={loadCustomers}
        isMulti
        filterOption={createFilter()}
        defaultOptions
        cacheOptions
        value={state.selectedCustomers}
        onChange={(selectedCustomers) => setState({ selectedCustomers })}
      />
      <br />
    </Col>
  )
}

export const renderAgenciesSelect = (state, setState) => {
  if (state.audience !== AUDIENCE_TYPES.EXTERNAL) {
    return null
  }

  const loadAgencies = (params) =>
    ApiRequest(`/api/agencies?filter=${params}`).then((res) => {
      return res.json.entities.map((entity) => ({ value: entity.id, label: entity.name }))
    })

  return (
    <Col xs={12} sm={6}>
      <ControlLabel>Agencies</ControlLabel>
      <AsyncSelect
        name="form-field-agencies"
        loadOptions={loadAgencies}
        isMulti
        filterOption={createFilter()}
        defaultOptions
        cacheOptions
        value={state.selectedAgencies}
        onChange={(selectedAgencies) => setState({ selectedAgencies })}
      />
      <br />
    </Col>
  )
}

export const renderTeamsSelect = (state, setState) => {
  if (state.audience !== AUDIENCE_TYPES.INTERNAL) {
    return null
  }

  const loadTeams = (params) =>
    ApiRequest(`/api/teamsbasic?filter=${params}`).then((res) => {
      const results = res.json.entities.map((entity) => ({
        value: entity.id,
        label: entity.name
      }))

      return results
    })

  return (
    <Col xs={12} sm={4}>
      <ControlLabel>Teams</ControlLabel>
      <AsyncSelect
        name="form-field-teams"
        loadOptions={loadTeams}
        isMulti
        filterOption={createFilter()}
        defaultOptions
        cacheOptions
        value={state.selectedTeams}
        onChange={(selectedTeams) => setState({ selectedTeams })}
      />
      <br />
    </Col>
  )
}

export const renderCrewsSelect = (state, setState) => {
  if (
    state.audience !== AUDIENCE_TYPES.INTERNAL ||
    !state.selectedTeams || !state.selectedTeams.length
  ) {
    return null
  }

  const selectedTeamIds = state.selectedTeams.map((t) => t.value)

  const loadCrews = (params) =>
    ApiRequest(`/api/crewsbasic?filter=${params}&teams=${selectedTeamIds.join(',')}`).then((res) => {
      const results = res.json.entities.map((entity) => ({
        value: entity.id,
        label: entity.name
      }))

      return results
    })

  return (
    <Col xs={12} sm={4}>
      <ControlLabel>Crews</ControlLabel>
      <AsyncSelect
        name="form-field-crews"
        key={shortId.generate()}
        loadOptions={loadCrews}
        isMulti
        filterOption={createFilter()}
        defaultOptions
        cacheOptions
        value={state.selectedCrews}
        onChange={(selectedCrews) => setState({ selectedCrews })}
      />
      <br />
    </Col>
  )
}

export const renderVesselsSelect = (state, setState) => {
  if (state.audience !== AUDIENCE_TYPES.INTERNAL) {
    return null
  }

  const loadVessels = (params) =>
    ApiRequest(`/api/vessels?filter=${params}`).then((res) => {
      return res.json.entities.map((entity) => ({ value: entity.id, label: entity.name }))
    })

  return (
    <Col xs={12} sm={4}>
      <ControlLabel>Vessels</ControlLabel>
      <AsyncSelect
        name="form-field-vessels"
        loadOptions={loadVessels}
        isMulti
        filterOption={createFilter()}
        defaultOptions
        cacheOptions
        value={state.selectedVessels}
        onChange={(selectedVessels) => setState({ selectedVessels })}
      />
      <br />
    </Col>
  )
}

/**
 * Content form
 */

const Contents = (props) => {

    if (!props.user.user.Right) {
      return null
    }
    const { privileges } = props.user.user.Right
      let location = useLocation();
      let navigate = useNavigate();
      const match = useParams();

    return (
      <div id="contents">
          <Routes>
              <Route exact path="/"  element={<List {...props} location={location} history={navigate}/>}/>
              <Route path="/new"  element={<New {...props} location={location} history={navigate} match={match}/>}/>
              <Route path="/:id/edit" element={<Edit {...props} location={location} history={navigate} match={match}/>} />
          </Routes>
      </div>
    )
}

Contents.propTypes = {
  history: PropTypes.func,
  contentsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default Contents

