import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

export const users = new schema.Entity('users', {}, { idAttribute: 'id' })

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export function login(username, password) {
  const endpoint = '/api/me/backoffice/login'

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      body: { username, password },
      types: [USER_LOGIN, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE]
    }
  }
}

export const SET_USER = 'SET_USER'

export function setUser(user, loggedIn = null) {
  return { type: 'SET_USER', user, loggedIn }
}

export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export function logout() {
  const endpoint = '/api/me/logout'

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      types: [USER_LOGOUT, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE]
    }
  }
}

export const USER_CHANGE_PWD = 'USER_CHANGE_PWD'
export const USER_CHANGE_PWD_SUCCESS = 'USER_CHANGE_PWD_SUCCESS'
export const USER_CHANGE_PWD_FAILURE = 'USER_CHANGE_PWD_FAILURE'

export function changePassword(oldPassword, newPassword) {
  const endpoint = '/api/me/change-password'

  return {
    [CALL_API]: {
      endpoint,
      method: 'put',
      body: { oldPassword, newPassword },
      types: [USER_CHANGE_PWD, USER_CHANGE_PWD_SUCCESS, USER_CHANGE_PWD_FAILURE]
    }
  }
}

export const USER_COMPARE_PASS = 'USER_COMPARE_PASS'
export const USER_COMPARE_PASS_SUCCESS = 'USER_COMPARE_PASS_SUCCESS'
export const USER_COMPARE_PASS_FAILURE = 'USER_COMPARE_PASS_FAILURE'

export function comparePass(id, password) {
  const endpoint = `/api/users/${id}/comparePass`

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      body: {
        password
      },
      types: [
        USER_COMPARE_PASS,
        USER_COMPARE_PASS_SUCCESS,
        USER_COMPARE_PASS_FAILURE
      ]
    }
  }
}

export const UPDATE_USER_MFA = 'UPDATE_USER_MFA'
export const UPDATE_USER_MFA_SUCCESS = 'UPDATE_USER_MFA_SUCCESS'
export const UPDATE_USER_MFA_FAILURE = 'UPDATE_USER_MFA_FAILURE'

export function updateUserMfa(id, body) {
  const endpoint = `/api/users/${id}/mfa`

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'put',
      schema: users,
      types: [UPDATE_USER_MFA, UPDATE_USER_MFA_SUCCESS, UPDATE_USER_MFA_FAILURE]
    }
  }
}

export const USER_SET_SESSION = 'USER_SET_SESSION'
export const USER_SET_SESSION_SUCCESS = 'USER_SET_SESSION_SUCCESS'
export const USER_SET_SESSION_FAILURE = 'USER_SET_SESSION_FAILURE'

export function setSession() {
  const endpoint = '/api/me/set-session'

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      types: [USER_SET_SESSION, USER_SET_SESSION_SUCCESS, USER_SET_SESSION_FAILURE]
    }
  }
}
