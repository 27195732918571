import fetch from 'isomorphic-fetch'

const defaultHeaders = {
  json: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  formData: {
    'Content-Type': 'multipart/form-data'
  }
}

export default function apiRequest(endpoint, method = 'get', body, contentType = 'json') {
  const init = { method }

  if (contentType === 'json') {
    init.body = JSON.stringify(body) || null
    init.headers = defaultHeaders[contentType]
  } else {
    init.body = body
    init.headers = {}
  }

  let token = localStorage.getItem('token')
  try {
    token = JSON.parse(token)
  } catch (error) { }

  if (token) {
    init.headers.Authorization = `Bearer ${token}`
  }

  return fetch(endpoint, init).then(async (res) => {
    if (!res.ok) {
      let message
      const responseText = await res.text()
      try {
        message = JSON.parse(responseText).message
      } catch (err) {
        message = responseText
      }

      return Promise.reject({
        status: res.status,
        statusText: res.statusText,
        message: message
      })
    }
    return res.json().then(json => ({ json, res }))
  });
}