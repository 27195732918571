import { normalize } from 'normalizr'
import apiRequest from '../helpers/apiHelper'
import ProgressBar from '../components/progressBar'
import configureStore from '../store'
import * as UserActions from '../actions/user'

export const CALL_API = Symbol('Call API')

export default function apiMiddleware() {
  return (next) => (action) => {
    const callApi = action[CALL_API]

    // skip middleware if its not a api call
    if (typeof callApi === 'undefined') {
      return next(action)
    }

    const { endpoint, method, body, types, normalizeKey, schema, contentType } = callApi

    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.')
    }

    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.')
    }

    if (!types.every((type) => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.')
    }

    function actionWith(data) {
      const finalAction = Object.assign({}, action, data)
      delete finalAction[CALL_API]
      return finalAction
    }

    const [requestType, successType, failureType] = types

    next(actionWith({ type: requestType }))
    ProgressBar.start()

    return apiRequest(endpoint, method, body, contentType)
      .then(({ json }) => {
        const normalizeData = normalizeKey ? json[normalizeKey] : json
        const result = {
          response: json,
          normalized: schema ? normalize(normalizeData, schema) : undefined
        }

        ProgressBar.done()
        return Promise.resolve(next(actionWith({ type: successType, ...result })))
      })
      .catch((err) => {
        if (err.status === 401 && !action[CALL_API].types.includes(UserActions.USER_LOGIN)) {
          const store = configureStore()
          store.dispatch(UserActions.logout()).then(() => {
            window.location.href = '/'
          })
        } else {
          const error = err.message || 'Api call failed.'
          ProgressBar.done()
          return Promise.reject(next(actionWith({ type: failureType, error })))
        }
      })
  }
}
