import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Col, PageHeader, Panel, Row } from 'react-bootstrap'
import { showNotificationFailed } from '../../helpers/shared/notification'
import { parseQuery } from '../../helpers/shared/dataTable/dataTableHelper'
import DateCell from '../../components/shared/dataTable/cells/dateCell'
import DataTable from '../../components/shared/dataTable/dataTable'

class VesselShow extends React.Component {
  static usersColumns = [
    {
      Header: 'Username',
      accessor: 'username',
      sortable: false
    }, {
      Header: 'Created at',
      id: 'createdAt',
      accessor: row => <DateCell date={row.createdAt} />,
      sortable: false
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      users: {
        loading: true,
        data: [],
        pages: 1,
        orderBy: 'name',
        sort: 'asc'
      },
      crewMembers: {
        loading: true,
        data: [],
        pages: null
      }
    }
  }

  componentDidMount() {
    this.fetchVesselData()
    this.fetchVesselCrew()
  }

  fetchVesselData = () => {
    const { users } = this.state
    const { vesselsActions, match } = this.props

    this.setState({
      users: {
        ...users,
        loading: true
      }
    }, () => {
      vesselsActions.fetchVesselById(match['*'])
        .then(({ response }) => {
          this.setState({
            data: response,
            users: {
              ...users,
              data: response.Users,
              loading: false
            }
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  fetchVesselCrew = (query = {}) => {
    const { crewMembers } = this.state
    const { vesselsActions, match } = this.props
    const { page, limit, sort, orderBy } = this.props.vesselCrewMembers
    const parsedQuery = parseQuery(query)

    this.setState({
      crewMembers: {
        ...crewMembers,
        loading: true
      }
    }, () => {
      vesselsActions.fetchVesselCrewById(match['*'], {
        page,
        limit,
        sort,
        orderBy,
        ...parsedQuery
      })
        .then(({ response }) => {
          this.setState({
            crewMembers: {
              ...crewMembers,
              data: response.entities,
              pages: Math.ceil(response.totalCount / limit),
              loading: false
            }
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  handleUserRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/users/${rowInfo.original.id}`, { from: this.props.location.pathname })
      }
    }
  }

  handleCrewMemberRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/profiles/${rowInfo.original.PIN}`, { from: this.props.location.pathname })
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch vessel data')
    const { users, crewMembers } = this.state
    this.setState({
      users: {
        ...users,
        loading: false
      },
      crewMembers: {
        ...crewMembers,
        loading: false
      }
    })
  }

  renderTeamCell = (row, hasAccessToViewTeams) => {
    return hasAccessToViewTeams ? <Link to={`/teams/${row.TeamId}`}>{row.Team.name}</Link> : row.Team.name
  }
  renderCrewCell = (row, hasAccessToViewTeams) => {
    return hasAccessToViewTeams ? <Link to={`/teams/${row.Crew.TeamId}/${row.Crew.id}`}>{row.Crew.name}</Link> : row.Crew.name
  }

  render() {
    const { data, users, crewMembers } = this.state
    const { orderBy, sort, page } = this.props.vesselCrewMembers
    const hasAccessToViewTeams = this.props.user.user.Right && this.props.user.user.Right.privileges.teams !== 0
    const crewMembersColumns = [
      {
        Header: 'PIN',
        accessor: 'PIN'
      }, {
        Header: 'First name',
        accessor: 'firstname'
      }, {
        Header: 'Last name',
        accessor: 'lastname'
      }, {
        Header: 'Position',
        accessor: 'position'
      }, {
        Header: 'Crew',
        id: 'CrewId',
        accessor: row => (row.Crew ? this.renderCrewCell(row, hasAccessToViewTeams) : '-')
      }, {
        Header: 'Team',
        id: 'TeamId',
        accessor: row => (row.Team ? this.renderTeamCell(row, hasAccessToViewTeams) : '-')
      }
    ]

    return (
      <div id="vessels-show">
        <PageHeader>
          <Link to="/vessels">Vessels</Link> / {data.name}
        </PageHeader>
        <hr className="visible-xs-block" />
        <Row>
          <Col xs={12}>
            <Panel>
              <h4>Users</h4>
              <div className="details-table">
                <DataTable
                  loading={users.loading}
                  data={users.data}
                  columns={VesselShow.usersColumns}
                  tableState={{
                    pages: users.pages,
                    page: 1,
                    orderBy: users.orderBy,
                    sort: users.sort
                  }}
                  onFetch={this.fetchVesselData}
                  onRowClick={this.handleUserRowClick}
                  customEmptyResultsMsg="No users for this vessel"
                />
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Panel>
              <h4>Crew Members</h4>
              <div className="details-table">
                <DataTable
                  loading={crewMembers.loading}
                  data={crewMembers.data}
                  columns={crewMembersColumns}
                  tableState={{
                    pages: crewMembers.pages,
                    page,
                    orderBy,
                    sort
                  }}
                  onFetch={this.fetchVesselCrew}
                  onRowClick={this.handleCrewMemberRowClick}
                  customEmptyResultsMsg="No crew members for this vessel"
                />
              </div>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}

VesselShow.propTypes = {
  vesselCrewMembers: PropTypes.object.isRequired,
  vesselsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default VesselShow
