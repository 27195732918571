import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  SET_USER,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_CHANGE_PWD,
  USER_CHANGE_PWD_FAILURE,
  USER_CHANGE_PWD_SUCCESS,
  USER_COMPARE_PASS,
  USER_COMPARE_PASS_SUCCESS,
  USER_COMPARE_PASS_FAILURE,
  UPDATE_USER_MFA,
  UPDATE_USER_MFA_FAILURE,
  UPDATE_USER_MFA_SUCCESS,
  USER_SET_SESSION,
  USER_SET_SESSION_SUCCESS,
  USER_SET_SESSION_FAILURE
} from '../actions/user'
import { USER_TOKEN } from '../constants'

const defaultState = {
  user: null,
  loggedIn: false,
  isFetching: false,
  error: null
}

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case USER_LOGIN:
    case UPDATE_USER_MFA:
    case USER_CHANGE_PWD:
    case USER_COMPARE_PASS:
    case USER_SET_SESSION: {
      return { ...state, isFetching: true }
    }
    case USER_LOGIN_SUCCESS: {
      const user = action.response
      // Make sure the token is set in localStorage before the state change is
      // triggered: the destination route will get rendered and possibily
      // and API request will be made which requires the token to be set in
      // localStorage
      const { token } = user
      if (token) {
        localStorage.setItem(USER_TOKEN, token)
      }

      return {
        ...state,
        user,
        isFetching: false,
        loggedIn: true
      }
    }
    case USER_LOGIN_FAILURE: {
      return {
        ...state,
        isFetching: false,
        loggedIn: false,
        error: action.error
      }
    }
    case USER_CHANGE_PWD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        user: action.response
      }
    }
    case USER_CHANGE_PWD_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    case UPDATE_USER_MFA_SUCCESS: {
      return {
        ...state,
        didFetch: !action.response.id,
        isFetching: false,
        error: null,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }
    case UPDATE_USER_MFA_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }
    case SET_USER: {
      // Make sure the token is set in localStorage before the state change is
      // triggered: the destination route will get rendered and possibily
      // and API request will be made which requires the token to be set in
      // localStorage
      const { token } = action.user
      if (token) {
        localStorage.setItem(USER_TOKEN, token)
      }

      return {
        ...state,
        user: action.user,
        loggedIn: action.loggedIn || state.loggedIn
      }
    }
    case USER_LOGOUT: {
      return { ...state, isFetching: true }
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        loggedIn: false,
        user: null
      }
    }
    case USER_LOGOUT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    case USER_SET_SESSION_SUCCESS:
    case USER_SET_SESSION_FAILURE:
    case USER_COMPARE_PASS_SUCCESS:
    case USER_COMPARE_PASS_FAILURE: {
      return {
        ...state,
        isFetching: false
      }
    }
    default: {
      return state
    }
  }
}
