import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

export const users = new schema.Entity('users', {}, { idAttribute: 'id' })
export const usersList = new schema.Array(users)

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export function fetchUsers(body) {
  const endpoint = '/api/users/filtered'

  if (body.orderBy === 'loggedInAt') {
    delete body.orderBy
    delete body.desc
  } 

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'post',
      normalizeKey: 'entities',
      schema: usersList,
      types: [FETCH_USERS, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE]
    }
  }
}

export const FETCH_LOGGED_IN_USERS = 'FETCH_LOGGED_IN_USERS'
export const FETCH_LOGGED_IN_USERS_SUCCESS = 'FETCH_LOGGED_IN_USERS_SUCCESS'
export const FETCH_LOGGED_IN_USERS_FAILURE = 'FETCH_LOGGED_IN_USERS_FAILURE'

export function fetchLoggedInUsers(queryParams) {
  let endpoint = '/api/users/logged-in/filtered'

  if (queryParams.orderBy === 'dateOfBirth' || queryParams.orderBy === 'role') {
    delete queryParams.orderBy
  } 
  
  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      normalizeKey: 'entities',
      schema: usersList,
      types: [FETCH_LOGGED_IN_USERS, FETCH_LOGGED_IN_USERS_SUCCESS, FETCH_LOGGED_IN_USERS_FAILURE]
    }
  }
}

export function fetchUsersIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.users.isFetching && !state.users.didFetch) {
      return dispatch(fetchUsers(queryParams))
    }
  }
}

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export function fetchUserById(id) {
  const endpoint = `/api/users/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: users,
      types: [FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURE]
    }
  }
}

export const CREATE_CONTENT_EDITOR_USER = 'CREATE_CONTENT_EDITOR_USER'
export const CREATE_CONTENT_EDITOR_USER_SUCCESS = 'CREATE_CONTENT_EDITOR_USER_SUCCESS'
export const CREATE_CONTENT_EDITOR_USER_FAILURE = 'CREATE_CONTENT_EDITOR_USER_FAILURE'

export function createContentEditorUser(body) {
  const endpoint = '/api/users/content-editor/create'

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'post',
      schema: users,
      types: [
        CREATE_CONTENT_EDITOR_USER,
        CREATE_CONTENT_EDITOR_USER_SUCCESS,
        CREATE_CONTENT_EDITOR_USER_FAILURE
      ]
    }
  }
}

export const CREATE_ADMIN_USER = 'CREATE_ADMIN_USER'
export const CREATE_ADMIN_USER_SUCCESS = 'CREATE_ADMIN_USER_SUCCESS'
export const CREATE_ADMIN_USER_FAILURE = 'CREATE_ADMIN_USER_FAILURE'

export function createAdminUser(body) {
  const endpoint = '/api/users/admin/create'

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'post',
      schema: users,
      types: [
        CREATE_ADMIN_USER,
        CREATE_ADMIN_USER_SUCCESS,
        CREATE_ADMIN_USER_FAILURE
      ]
    }
  }
}

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export function createUser(body) {
  const endpoint = '/api/users/create'

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'post',
      schema: users,
      types: [
        CREATE_USER,
        CREATE_USER_SUCCESS,
        CREATE_USER_FAILURE
      ]
    }
  }
}

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export function updateUserById(id, body) {
  const endpoint = `/api/users/${id}`

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'put',
      schema: users,
      types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE]
    }
  }
}

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

export function deleteUser(id) {
  const endpoint = `/api/users/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE]
    },
    id
  }
}

export const RESET_USER = 'RESET_USER'
export const RESET_USER_SUCCESS = 'RESET_USER_SUCCESS'
export const RESET_USER_FAILURE = 'RESET_USER_FAILURE'

export function resetUser(id, body) {
  const endpoint = `/api/users/${id}/reset`

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'post',
      types: [RESET_USER, RESET_USER_SUCCESS, RESET_USER_FAILURE]
    },
    id
  }
}
