import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'
import { getUserRole } from '../../../../helpers/usersHelpers'

class UserRoleBadge extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { user } = this.props
    return user ? <Label bsStyle="success">{getUserRole(user.role)}</Label> : ''
  }
}

UserRoleBadge.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserRoleBadge
