import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class DateCell extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { date, withTime } = this.props
    return date ? moment(date).format(withTime ? 'DD MMM YYYY HH:mm:ss' : 'DD MMM YYYY') : '-'
  }
}

DateCell.defaultProps = {
  date: '',
  withTime: false
}

DateCell.propTypes = {
  date: PropTypes.string,
  withTime: PropTypes.bool
}

export default DateCell
