import {Amplify  } from 'aws-amplify'
import {
    signIn,
    resetPassword,
    confirmSignIn,
    updatePassword,
    updateMFAPreference,
    verifyTOTPSetup,
    setUpTOTP,
    confirmResetPassword,
    deleteUser,
    confirmUserAttribute,
    updateUserAttributes
} from 'aws-amplify/auth'
import * as config from './config/aws.config'

const environment = process.env.NODE_ENV || 'development';

Amplify.configure(config[environment]);

export const login = (username, password) => signIn({username, password}).catch((err) => {console.log('signIn ERROR', err)});

//Other Code has to be fixed
export const changeInitialPassword =
    (challengeResponse) => confirmSignIn({challengeResponse}).catch((err) => {console.log('confirmSignIn ERROR', err)});

export const changePassword = (oldPassword, newPassword) => updatePassword({oldPassword, newPassword}).catch((err) => {console.log('updatePassword ERROR', err)});

//look if Answerhas to be given -> no Answer in V6
export const setMfaType =
    (type) => updateMFAPreference(type).catch((err) => {console.log('updateMFAPreference ERROR', err)});


//remove User
export const completeTotp = (code) => verifyTOTPSetup({code}).catch((err) => {console.log('verifyTOTPSetup ERROR', err)});

export const setupTotp = () => setUpTOTP().catch((err) => {console.log('setUpTOTP ERROR', err)});


export const setAttributes = (user, attributes) => updateUserAttributes(attributes).catch((err) => {console.log('updateUserAttributes ERROR', err)})

//Replace imports
export const verifyAttribute =
    (userAttributeKey, confirmationCode) => confirmUserAttribute({userAttributeKey, confirmationCode}).catch((err) => {console.log('confirmUserAttribute ERROR', err)})


//Check that USer is no longer needed -> allso Attribute KEY & replace with verifyAttribute
export const completeAttributeVerification =
    (userAttributeKey, confirmationCode) => confirmUserAttribute( {userAttributeKey, confirmationCode}).catch((err) => {console.log('confirmUserAttribute ERROR', err)})


//checkForUsages and update -> can be replaced with changeInitialPassword above
export const completeMfaChallenge   = async ({ challengeResponse }) => {
    const {
        isSignedIn,
        nextStep
    } = await confirmSignIn({challengeResponse}).catch((err) => {console.log('confirmSignIn ERROR', err)});
}

export const deleteCurrentUser = () => deleteUser().catch((err) => {console.log('deleteUser ERROR', err)});

export const resetPasswordUser = username => resetPassword(username).catch((err) => {console.log('resetPassword ERROR', err)})

export const completeResetPassword =
    (username, confirmationCode, newPassword) => confirmResetPassword({username, newPassword, confirmationCode}).catch((err) => {console.log('confirmResetPassword ERROR', err)});
