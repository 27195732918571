import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

class Medicals extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { medicals } = this.props

    return (
      <div id="travel-documents">
        <h4>Medicals</h4>
        <Table responsive>
          <thead>
            <tr>
              <th>Code</th>
              <th>Issued by</th>
              <th>Issue Date</th>
              <th>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            {medicals.length === 0 ? (
              <tr>
                <td>
                  <i>No attached files</i>
                </td>
              </tr>
            ) : (
              <tr>
                <td>{medicals.code}</td>
                <td>{medicals.issuedBy}</td>
                <td>{medicals.issued}</td>
                <td>{medicals.expiryDate}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

Medicals.propTypes = {
  medicals: PropTypes.array
}

Medicals.defaultProps = {
  medicals: []
}

export default Medicals
