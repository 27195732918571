import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

const content = new schema.Entity('contents', {}, { idAttribute: 'id' })
const contentsList = new schema.Array(content)

/**
 * Async Action Creators
 */

export const FETCH_CONTENTS = 'FETCH_CONTENTS'
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS'
export const FETCH_CONTENTS_FAILURE = 'FETCH_CONTENTS_FAILURE'

export function fetchContents(queryParams) {
  try{
    let endpoint = '/api/contents'

    Object.keys(queryParams).forEach((key, i) => {
      if (!queryParams[key]) return
      if (i === 0) {
        endpoint = `${endpoint}?${key}=${queryParams[key]}`
      } else {
        endpoint = `${endpoint}&${key}=${queryParams[key]}`
      }
    })

    return {
      [CALL_API]: {
        endpoint,
        method: 'get',
        schema: contentsList,
        normalizeKey: 'entities',
        types: [FETCH_CONTENTS, FETCH_CONTENTS_SUCCESS, FETCH_CONTENTS_FAILURE]
      }
    }
  }catch (err){
    console.log('FETCH CONTENT ERROR', err);
    return null;
  }

}

export function fetchContentsIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.contents.isFetching && !state.contents.didFetch) {
      return dispatch(fetchContents(queryParams))
    }
  }
}

export const FETCH_CONTENT = 'FETCH_CONTENT'
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS'
export const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE'

export function fetchContentById(id) {
  try {
    const endpoint = `/api/contents/${id}`

    return {
      [CALL_API]: {
        endpoint,
        method: 'get',
        schema: content,
        types: [FETCH_CONTENT, FETCH_CONTENT_SUCCESS, FETCH_CONTENT_FAILURE]
      }
    }
  } catch (err){
    console.log('ERROR', err);
  }

}

export const CREATE_CONTENT = 'CREATE_CONTENT'
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS'
export const CREATE_CONTENT_FAILURE = 'CREATE_CONTENT_FAILURE'

export function createContent(contentData) {
  const endpoint = '/api/contents'

  return {
    [CALL_API]: {
      endpoint,
      body: contentData,
      contentType: 'formData',
      method: 'post',
      schema: content,
      types: [CREATE_CONTENT, CREATE_CONTENT_SUCCESS, CREATE_CONTENT_FAILURE]
    }
  }
}

export const UPDATE_CONTENT = 'UPDATE_CONTENT'
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS'
export const UPDATE_CONTENT_FAILURE = 'UPDATE_CONTENT_FAILURE'

export function updateContent(id, contentData) {
  const endpoint = `/api/contents/${id}`

  return {
    [CALL_API]: {
      endpoint,
      body: contentData,
      contentType: 'formData',
      method: 'put',
      schema: content,
      types: [UPDATE_CONTENT, UPDATE_CONTENT_SUCCESS, UPDATE_CONTENT_FAILURE]
    },
    id,
    contentData
  }
}

export const DELETE_CONTENT = 'DELETE_CONTENT'
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS'
export const DELETE_CONTENT_FAILURE = 'DELETE_CONTENT_FAILURE'

export function deleteContent(id) {
  const endpoint = `/api/contents/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      types: [DELETE_CONTENT, DELETE_CONTENT_SUCCESS, DELETE_CONTENT_FAILURE]
    },
    id
  }
}
