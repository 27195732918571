import { CALL_API } from '../middleware/apiMiddleware'

/**
 * Async Action Creators
 */

export const MIGRATE_REQUESTED = 'MIGRATE_REQUESTED'
export const MIGRATE_SUCCEEDED = 'MIGRATE_SUCCEEDED'
export const MIGRATE_FAILED = 'MIGRATE_FAILED'

export function migrate(cmd) {
  const endpoint = `/api/migrate/${cmd}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      types: [MIGRATE_REQUESTED, MIGRATE_SUCCEEDED, MIGRATE_FAILED]
    }
  }
}

export const RUN_TESTS_REQUESTED = 'RUN_TESTS_REQUESTED'
export const RUN_TESTS_SUCCEEDED = 'RUN_TESTS_SUCCEEDED'
export const RUN_TESTS_FAILED = 'RUN_TESTS_FAILED'

export function runTests() {
  const endpoint = '/api/tests/run'

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      types: [RUN_TESTS_REQUESTED, RUN_TESTS_SUCCEEDED, RUN_TESTS_FAILED]
    }
  }
}
