import React from 'react'
import PropTypes from 'prop-types'
import { Col, ControlLabel, Row, Button, FormGroup, HelpBlock } from 'react-bootstrap'
import MaterialIcon from 'material-icons-react'
import AsyncSelect from 'react-select/async'
import ApiRequest from '../helpers/apiHelper'


class VesselAssignment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedVessel: null,
      vessels: {
        value: this.props.initialVessels || [],
        touched: null
      }
    }
  }

  onSelect = (value) => {
    this.setState({ selectedVessel: value })
  }

  handleVesselChange = () => {
    this.props.onVesselsChange(this.state.vessels.value.map(el => parseInt(el.value, 10)))
  }

  touchAllFields = () => {
    this.setState({
      vessels: {
        value: this.state.vessels.value,
        touched: true
      }
    })
  }

  onRemoveVessel = (id) => {
    this.setState({
      selectedVessel: null,
      vessels: {
        ...this.state.vessels,
        value: this.state.vessels.value.filter(el => el.value !== id)
      }
    }, () => this.handleVesselChange())
  }

  onAddVessel = () => {
    this.setState({
      selectedVessel: null,
      vessels: {
        ...this.state.vessels,
        value: [...this.state.vessels.value, this.state.selectedVessel]
      }
    }, () => this.handleVesselChange())
  }

  onVesselBlur = () => {
    this.setState({
      ...this.state.vessels,
      vessels: {
        ...this.state.vessels,
        touched: true
      }
    })
  }

  promiseOptions = inputValue => {
    return new Promise(async (resolve) => {
      const vessels = await ApiRequest(`/api/vessels?orderBy=name&sort=asc&filter=${inputValue}`).then(res => res.json.entities)
      const options = vessels.map(el => ({ label: el.name, value: el.id }))
      resolve(options)
    })
  }

  renderNoSelectedVessels = () => (
    <HelpBlock className={!this.isVesselValid() && 'has-error'}>
      <MaterialIcon size="16" icon="error" />
      <span style={{ color: !this.isVesselValid() && '#a94442' }}>At least one vessel is required</span>
    </HelpBlock>
  )

  isVesselValid = () => (this.state.vessels.touched ? this.state.vessels.value.length > 0 : true)

  renderSelectedVesselsList = () => {
    return this.state.vessels.value.length ? this.state.vessels.value.map((el) => (
      <Row key={el.value} className="selected-vessel">
        <Col sm={8}>
          <div>{ el.label }</div>
        </Col>
        <Col sm={4}>
          <Button className="button-with-icon" type="button" bsSize="lg" onClick={() => this.onRemoveVessel(el.value)}>
            <span>REMOVE</span>
          </Button>
        </Col>
      </Row>
    )) : this.renderNoSelectedVessels()
  }

  filterOption = ({ value }) => {
    return !this.state.vessels.value.map(el => el.value).includes(value)
  }

  render() {
    const { selectedVessel } = this.state

    return (
      <div>    
        <FormGroup controlId="vessel">
          <ControlLabel>Vessel</ControlLabel>
          <Row>
            <Col sm={9}>
              <AsyncSelect
                defaultOptions
                value={selectedVessel}
                loadOptions={this.promiseOptions}
                onChange={(value) => this.onSelect(value)}
                filterOption={this.filterOption}
                onBlur={this.onVesselBlur}
              />
            </Col>
            <Col className="vessel-assignment-button" sm={3}>
              <Button type="button" bsSize="lg" className="button-with-icon" disabled={!selectedVessel} onClick={this.onAddVessel}>
                <span>ADD</span>
              </Button>
            </Col>
          </Row>
        </FormGroup>
        <Row className="vessels-list">
          <Col sm={12}>
            <h5>Linked to</h5>
            <hr />
            { this.renderSelectedVesselsList() }
          </Col>
        </Row>
      </div>)
  }
}

VesselAssignment.propTypes = {
  onVesselsChange: PropTypes.func.isRequired,
  initialVessels: PropTypes.array
}

VesselAssignment.defaultProps = {
  initialVessels: []
}

export default VesselAssignment
