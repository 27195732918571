import React from 'react'
import {Route, useLocation, useParams} from 'react-router-dom'
import UsersTabs from './tabs'
import Show from './user-edition'
import RolePrivileges from './rolePrivileges'
import ManageRestrictions from './manageRestrictions'
import {Navigate, Routes, useNavigate} from "react-router";

const Users = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const match = useParams();

    return (
        <div id="users">
            <Routes>
                <Route exact path="/"
                       element={<Navigate to="/users/list"/>}/>
                <Route exact path="/list"
                       element={<UsersTabs {...props} location={location} history={navigate}/>}/>
                <Route exact path="/logged-in"
                       element={<UsersTabs {...props} location={location} history={navigate}/>}/>
                <Route exact path="/role-privileges"
                       element={<RolePrivileges {...props} location={location} history={navigate}/>}/>
                <Route exact path="/manage-restrictions"
                       element={<ManageRestrictions {...props} location={location} history={navigate}/>}/>
                <Route exact path="/:id"
                       element={<Show {...props} location={location} history={navigate} match={match}/>}/>
            </Routes>
        </div>
    )
}


export default Users
