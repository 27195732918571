import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Col, Form, FormControl, FormGroup, Image, Alert } from 'react-bootstrap'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import * as Cognito from '../../../helpers/shared/cognito/cognito'
import MfaSms from '../../../components/shared/mfa/mfaSms'
import MfaTotp from '../../../components/shared/mfa/mfaTotp'

class RequiresMfaSetup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mfaType: '',
      showTotp: false,
      showSms: false
    }

    this.mfaTypes = {
      SMS: 'SMS',
      TOTP: 'TOTP'
    }
  }

  handleMfaChange = ({ target }) => {
    const { value } = target

    this.setState({
      mfaType: value
    }, () => {
      const { mfaType } = this.state
      this.setState({
        showSms: mfaType === this.mfaTypes.SMS,
        showTotp: mfaType === this.mfaTypes.TOTP
      })
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  setMfaType = (mfaType) => {
    const {
      user: {
        user,
        user: { cognitoUser }
      },
      userActions,
      userUpdateMfaSuccessAction
    } = this.props

    return userActions.updateUserMfa(user.id, { mfaType }).then((res) => {
      if (res.type !== userUpdateMfaSuccessAction) {
        showNotificationFailed(this.props.notificationsActions, 'Error while saving MFA type. Please try again later.')
        return false
      }
      return Cognito.setMfaType(cognitoUser, mfaType)
    })
  }

  renderSms = () => {
    const { showSms } = this.state
    const { userActions, user, notificationsActions } = this.props

    if (showSms) {
      return (
        <MfaSms
          userActions={userActions}
          user={user}
          notificationsActions={notificationsActions}
          setMfaType={this.setMfaType}
          mfaTypes={this.mfaTypes}
        />
      )
    }
  }

  renderTotp = () => {
    const { showTotp } = this.state
    const { userActions, user, notificationsActions } = this.props

    if (showTotp) {
      return (
        <MfaTotp
          userActions={userActions}
          user={user}
          notificationsActions={notificationsActions}
          setMfaType={this.setMfaType}
          mfaTypes={this.mfaTypes}
        />
      )
    }
  }

  render() {
    const { user, logo } = this.props

    if ((!user.user || !user.user.cognitoUser || user.loggedIn) && !user.user.requiresMfaSetup) {
      return <Redirect to="/" />
    }

    if (user && user.user && user.user.requiresPasswordUpdate) {
      return <Redirect to="/requires-password-update" />
    }

    return (
      <div id="login">
        <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
          <Image src={logo} alt="logo" className="img-centered login-logo" />

          <Alert bsStyle="info">
            Please select the second authentication factor type:
            <ul className="mfa-options-list">
              <li><small>SMS - You will receive an authentication code via SMS</small></li>
              <li><small>TOTP - You will have to provide a time-based one time password from an external application (i.e. Google Authenticator, 1Password)</small></li>
            </ul>
          </Alert>

          <Form horizontal onSubmit={this.handleSubmit}>
            <FormGroup controlId="formHorizontalMfaType">
              <FormControl
                componentClass="select"
                value={this.state.mfaType}
                placeholder="Choose MFA method"
                onChange={this.handleMfaChange}
                name="mfaType"
                className="light"
              >
                <option value="">...</option>
                <option value={this.mfaTypes.SMS}>SMS</option>
                <option value={this.mfaTypes.TOTP}>TOTP</option>
              </FormControl>
            </FormGroup>

            {this.renderSms()}
            {this.renderTotp()}

            <div className="forgot-pwd-btn text-center">
              <span className="sign-in-problems">
                Problems with signing in? Contact: <a href="mailto:support.adonis@seachefs.com">support.adonis@seachefs.com</a>
              </span>
            </div>
          </Form>
        </Col>
      </div>
    )
  }
}

RequiresMfaSetup.propTypes = {
  userActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  userUpdateMfaSuccessAction: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
}

export default RequiresMfaSetup
