import React from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs } from 'react-bootstrap'
import UsersList from './list'
import UsersLoggedInList from './loggedInList'

class UsersTabs extends React.Component {
  constructor() {
    super()
  }

  handleTabSelect = key => {
    const { history } = this.props
    history(`/users/${key}`)
  }

  render() {
    const {
      agenciesActions,
      customersActions,
      notificationsActions,
      vesselsActions,
      users,
      usersActions,
      loggedInUsers,
      history,
      location,
      modalsActions
    } = this.props
    const defaultTab = location.pathname === '/users/logged-in' ? 'logged-in' : 'list'

    return (
      <Tabs defaultActiveKey={defaultTab} id="users-tabs" onSelect={this.handleTabSelect}>
        <Tab eventKey="list" title="Users list">
          <UsersList
            notificationsActions={notificationsActions}
            agenciesActions={agenciesActions}
            customersActions={customersActions}
            vesselsActions={vesselsActions}
            users={users}
            usersActions={usersActions}
            history={history}
            location={location}
            modalsActions={modalsActions}
          />
        </Tab>
        {
          <Tab eventKey="logged-in" title="Logged in users list">
            <UsersLoggedInList
              notificationsActions={notificationsActions}
              loggedInUsers={loggedInUsers}
              usersActions={usersActions}
              history={history}
              location={location}
            />
          </Tab>
        }
      </Tabs>
    )
  }
}

UsersTabs.propTypes = {
  notificationsActions: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  loggedInUsers: PropTypes.object.isRequired,
  usersActions: PropTypes.object.isRequired,
  history: PropTypes.func,
  location: PropTypes.object.isRequired,
  modalsActions: PropTypes.object.isRequired,
  agenciesActions: PropTypes.object.isRequired,
  customersActions: PropTypes.object.isRequired,
  vesselsActions: PropTypes.object.isRequired
}

export default UsersTabs
