import React from 'react'
import MaterialIcon from 'material-icons-react'
import PropTypes from 'prop-types'
import {
  ButtonToolbar,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap'

class RolePrivilegesForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      roleNameTouched: false,
      formData: this.props.formData,
      name: this.props.name
    }
  }

  onValueChange = (name, target) => {
    const { formData } = this.state

    if (name === 'name') {
      this.setState({
        [name]: name === 'name' ? target.target.value : target
      })
    } else {
      this.setState({
        formData: {
          ...formData,
          [name]: name === 'name' ? target.target.value : target
        }
      })
    }
  }

  onTouched = (event) => {
    const { name } = event.target

    this.setState({
      [name]: {
        touched: true
      }
    })
  }

  isNameDuplicated = (roleName) => {
    const { rolesPrivilegesList } = this.props

    if (!rolesPrivilegesList) {
      return false
    }

    if (this.props.originalName && this.props.originalName === roleName) {
      return false
    }

    return rolesPrivilegesList.filter((role) => roleName.toLowerCase() === role.name.toLowerCase()).length > 0
  }

  isValid = () => {
    const { name } = this.state

    if ((name && name.length > 0) && !this.isNameDuplicated(name)) {
      return true
    }

    this.setState({ roleNameTouched: true })

    return false
  }

  isRoleNameValid = () => (this.state.roleNameTouched ? this.state.name && this.state.name.length && !this.isNameDuplicated(this.state.name) : true)

  render() {
    const {
      crewList,
      crewListStandardList,
      crewListEmbarkingCrewList,
      crewListOnboardCrewList,
      crewListDisembarkingCrewList,
      crewListUnplannedCrewList,
      content,
      contentNews,
      contentFormAndDocuments,
      contentTrainingMaterials,
      contentRulesAndRegulations,
      certificatesPage,
      documentsRequirement,
      teams,
      personalDetails,
      personalDetailsPersonalDetails,
      personalDetailsContact,
      personalDetailsAppearance,
      personalDetailsUniformsize,
      personalProtectiveEquipment,
      personalDetailsNextOfKin,
      personalDetailsNotes,
      personalBasicDetails,
      travelDocuments,
      activities,
      certificates,
      medicals,
      documents,
      payslips,
      contractsForSignature
    } = this.state.formData
    const { isPredefined } = this.props

    return (
      <Form>
        <FormGroup controlId="formHorizontalName" validationState={this.isRoleNameValid() ? null : 'error'}>
          <ControlLabel>Role name</ControlLabel>
          <FormControl
            type="text"
            placeholder="Role name"
            value={this.state.name}
            name="name"
            onChange={(event) => this.onValueChange('name', event)}
            onBlur={() => this.setState({ roleNameTouched: true })}
            ref={this.roleName}
            disabled={isPredefined}
          />
          <HelpBlock className={this.isRoleNameValid() ? 'hidden' : null}>
            <MaterialIcon size="16" icon="warning" />
            Role name is required and must be unique
          </HelpBlock>
        </FormGroup>

        <h5>Crew List</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="crewList"
            defaultValue={crewList}
            onChange={(event) => this.onValueChange('crewList', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Standard List</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="crewListStandardList"
            defaultValue={crewListStandardList}
            onChange={(event) => this.onValueChange('crewListStandardList', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Embarking Crew List</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="crewListEmbarkingCrewList"
            defaultValue={crewListEmbarkingCrewList}
            onChange={(event) => this.onValueChange('crewListEmbarkingCrewList', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Onboard Crew List</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="crewListOnboardCrewList"
            defaultValue={crewListOnboardCrewList}
            onChange={(event) => this.onValueChange('crewListOnboardCrewList', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Disembarking Crew List</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="crewListDisembarkingCrewList"
            defaultValue={crewListDisembarkingCrewList}
            onChange={(event) => this.onValueChange('crewListDisembarkingCrewList', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Unplanned Crew List</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="crewListUnplannedCrewList"
            defaultValue={crewListUnplannedCrewList}
            onChange={(event) => this.onValueChange('crewListUnplannedCrewList', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Content</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="content"
            defaultValue={content}
            onChange={(event) => this.onValueChange('content', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>News</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="contentNews"
            defaultValue={contentNews}
            onChange={(event) => this.onValueChange('contentNews', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Training Materials</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="contentTrainingMaterials"
            defaultValue={contentTrainingMaterials}
            onChange={(event) => this.onValueChange('contentTrainingMaterials', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Forms and documents</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="contentFormAndDocuments"
            defaultValue={contentFormAndDocuments}
            onChange={(event) => this.onValueChange('contentFormAndDocuments', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Rules and Regulations</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="contentRulesAndRegulations"
            defaultValue={contentRulesAndRegulations}
            onChange={(event) => this.onValueChange('contentRulesAndRegulations', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Certificates Page</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="certificatesPage"
            defaultValue={certificatesPage}
            onChange={(event) => this.onValueChange('certificatesPage', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Document Requirements</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="documentsRequirement"
            defaultValue={documentsRequirement}
            onChange={(event) => this.onValueChange('documentsRequirement', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Teams</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="teams"
            defaultValue={teams}
            onChange={(event) => this.onValueChange('teams', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Personal Details</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetails"
            defaultValue={personalDetails}
            onChange={(event) => this.onValueChange('personalDetails', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Personal Details</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetailsPersonalDetails"
            defaultValue={personalDetailsPersonalDetails}
            onChange={(event) => this.onValueChange('personalDetailsPersonalDetails', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Contact</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetailsContact"
            defaultValue={personalDetailsContact}
            onChange={(event) => this.onValueChange('personalDetailsContact', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Appearance</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetailsAppearance"
            defaultValue={personalDetailsAppearance}
            onChange={(event) => this.onValueChange('personalDetailsAppearance', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Uniformsize</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetailsUniformsize"
            defaultValue={personalDetailsUniformsize}
            onChange={(event) => this.onValueChange('personalDetailsUniformsize', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Protective equipment</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalProtectiveEquipment"
            defaultValue={personalProtectiveEquipment}
            onChange={(event) => this.onValueChange('personalProtectiveEquipment', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Next of kin</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetailsNextOfKin"
            defaultValue={personalDetailsNextOfKin}
            onChange={(event) => this.onValueChange('personalDetailsNextOfKin', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <h6>Notes</h6>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalDetailsNotes"
            defaultValue={personalDetailsNotes}
            onChange={(event) => this.onValueChange('personalDetailsNotes', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Personal Basic Details</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="personalBasicDetails"
            defaultValue={personalBasicDetails}
            onChange={(event) => this.onValueChange('personalBasicDetails', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Travel Documents</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="travelDocuments"
            defaultValue={travelDocuments}
            onChange={(event) => this.onValueChange('travelDocuments', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Activities</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="activities"
            defaultValue={activities}
            onChange={(event) => this.onValueChange('activities', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Certificates</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="certificates"
            defaultValue={certificates}
            onChange={(event) => this.onValueChange('certificates', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Medicals</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="medicals"
            defaultValue={medicals}
            onChange={(event) => this.onValueChange('medicals', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Documents</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="documents"
            defaultValue={documents}
            onChange={(event) => this.onValueChange('documents', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Payslips</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="payslips"
            defaultValue={payslips}
            onChange={(event) => this.onValueChange('payslips', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <hr />

        <h5>Contracts for Signature</h5>
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="contractsForSignature"
            defaultValue={contractsForSignature}
            onChange={(event) => this.onValueChange('contractsForSignature', event)}>
            <ToggleButton value={2}>upload</ToggleButton>
            <ToggleButton value={1}>view</ToggleButton>
            <ToggleButton value={0}>nothing</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </Form>
    )
  }
}

RolePrivilegesForm.propTypes = {
  formData: PropTypes.object,
  name: PropTypes.string,
  originalName: PropTypes.string,
  rolesPrivilegesList: PropTypes.array,
  isPredefined: PropTypes.bool
}

RolePrivilegesForm.defaultProps = {
  name: '',
  formData: {
    crewList: 0,
    crewListStandardList: 0,
    crewListEmbarkingCrewList: 0,
    crewListOnboardCrewList: 0,
    crewListDisembarkingCrewList: 0,
    crewListUnplannedCrewList: 0,
    content: 0,
    contentNews: 0,
    contentTrainingMaterials: 0,
    contentFormAndDocuments: 0,
    contentRulesAndRegulations: 0,
    certificates: 0,
    certificatesPage: 0,
    documentsRequirement: 0,
    teams: 0,
    personalDetails: 0,
    personalDetailsPersonalDetails: 0,
    personalDetailsContact: 0,
    personalDetailsAppearance: 0,
    personalDetailsUniformsize: 0,
    personalProtectiveEquipment: 0,
    personalDetailsNextOfKin: 0,
    personalDetailsNotes: 0,
    personalBasicDetails: 0,
    travelDocuments: 0,
    activities: 0,
    medicals: 0,
    documents: 0,
    payslips: 0,
    contractsForSignature: 0
  },
  originalName: '',
  rolesPrivilegesList: [],
  isPredefined: false
}

export default RolePrivilegesForm
