import { CALL_API } from '../middleware/apiMiddleware'

export const FETCH_ADONIS_TOKEN = 'FETCH_ADONIS_TOKEN'
export const FETCH_ADONIS_TOKEN_SUCCESS = 'FETCH_ADONIS_TOKEN_SUCCESS'
export const FETCH_ADONIS_TOKEN_FAILURE = 'FETCH_ADONIS_TOKEN_FAILURE'

export function fetchToken() {
  const endpoint = '/api/sync/token'

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [FETCH_ADONIS_TOKEN, FETCH_ADONIS_TOKEN_SUCCESS, FETCH_ADONIS_TOKEN_FAILURE]
    }
  }
}
