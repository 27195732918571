import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PageHeader } from 'react-bootstrap'
import CrewsList from '../../../components/shared/teams/crewsList'
import MembersList from '../../../components/shared/teams/membersList'

class TeamShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamName: null
    }
  }

  componentDidMount() {
    this.fetchTeam()
  }

  fetchTeam = () => {
    const { teamsActions, match } = this.props


    teamsActions.fetchTeamById(match['*'])
      .then(({ response }) => {
        this.setState({
          teamName: response.name
        })
      })
  }

  renderTeamLink = () => (
    <PageHeader>
      <Link to="/teams">Teams</Link>{this.state.teamName ? ` / ${this.state.teamName}` : ''}
    </PageHeader>
  )

  render() {
    const {
      crews, crewsActions, notificationsActions, history, teams, teamsActions, teamMembers, location
    } = this.props
    const teamIdProps = this.props.match['*']
    return (
      <div id="team-show">
        {this.renderTeamLink()}
        <CrewsList
          teamId={teamIdProps}
          teamsActions={teamsActions}
          crews={crews}
          crewsActions={crewsActions}
          notificationsActions={notificationsActions}
          history={history}
        />
        <MembersList
          teamId={teamIdProps}
          teams={teams}
          teamsActions={teamsActions}
          teamMembers={teamMembers}
          notificationsActions={notificationsActions}
          history={history}
          location={location}
        />
      </div>
    )
  }
}

TeamShow.propTypes = {
  teams: PropTypes.object.isRequired,
  crews: PropTypes.object.isRequired,
  teamsActions: PropTypes.object.isRequired,
  crewsActions: PropTypes.object.isRequired,
  teamMembers: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default TeamShow
