import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

class EnclosedDocuments extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { enclosedDocuments } = this.props

    return (
      <div id="enclosed-documents">
        <h4>Enclosed Documents</h4>

        <Table responsive>
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>Created</th>
              <th>Author</th>
            </tr>
          </thead>
          <tbody>
            {enclosedDocuments.length === 0 ? (
              <tr>
                <td>
                  <i>No attached files</i>
                </td>
              </tr>
            ) : (
              <tr>
                <td>{enclosedDocuments.type}</td>
                <td>{enclosedDocuments.number}</td>
                <td>{enclosedDocuments.issueDate}</td>
                <td>{enclosedDocuments.expiryDate}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

EnclosedDocuments.propTypes = {
  enclosedDocuments: PropTypes.array
}

EnclosedDocuments.defaultProps = {
  enclosedDocuments: []
}

export default EnclosedDocuments
