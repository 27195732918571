import { ROLES } from '../constants'

export const getUserRole = (role) => {
  switch (role) {
    case ROLES.ADMIN:
      return 'Admin'
    case ROLES.CREWMEMBER:
      return 'Crew Member'
    case ROLES.CUSTOMER:
      return 'Customer'
    case ROLES.AGENCY:
      return 'Manning Agent'
    case ROLES.CONTENT_EDITOR:
      return 'Content Editor'
    case ROLES.VESSEL:
      return 'Vessel'
    default:
      return null
  }
}

export const getUserType = (user) => {
  let typeData = {
    style: 'warning',
    link: null,
    name: 'not Linked'
  }

  if (user.Agency) {
    typeData = {
      style: 'info',
      link: `/agencies/${user.Agency.id}`,
      name: user.Agency.name
    }
  } else if (user.Customer) {
    typeData = {
      style: 'info',
      link: `/customers/${user.Customer.id}`,
      name: user.Customer.name
    }
  } else if (user.CrewMember) {
    typeData = {
      style: 'info',
      link: `/profiles/${user.CrewMember.PIN}`,
      name: user.CrewMember.PIN
    }
  } else if (user.Vessels) {
    typeData = {
      style: 'info',
      links: user.Vessels.map(vessel => `/vessels/${vessel.id}`),
      names: user.Vessels.map(vessel => vessel.name)
    }
  }
  return typeData
}
