import React from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from 'material-icons-react'

class FilterInput extends React.Component {
  constructor() {
    super()
    this.state = {
      text: ''
    }
  }

  onClear = () => {
    const { onChange } = this.props
    this.setState({ text: '' })
    onChange('')
  }

  handleInput = (event) => {
    this.setState({ text: event.target.value })
  }

  handleEnter = ({ keyCode, target }) => {
    if (keyCode === 13) {
      const { onChange } = this.props
      onChange(target.value)
    }
  }

  render() {
    const {
      placeholder,
      onChange,
      disabled
    } = this.props
    const { text } = this.state

    return (
      <div className="data-table-filter">
        <input
          type="text"
          className="filter-input"
          placeholder={placeholder}
          disabled={disabled}
          onChange={this.handleInput}
          onKeyUp={this.handleEnter}
          value={text}
        />
        <button
          type="button"
          className="filter-clear-button"
          disabled={disabled || text.length === 0}
          onClick={this.onClear}
        >
          <MaterialIcon icon="close" size="small" />
        </button>
        <button className="btn btn-primary load-button" onClick={() => onChange(text)}>
          Load
        </button>
      </div>
    )
  }
}

FilterInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

FilterInput.defaultProps = {
  value: '',
  disabled: false
}

export default FilterInput
