import React from 'react'
import PropTypes from 'prop-types'
import {Link, useParams} from 'react-router-dom'
import { PageHeader } from 'react-bootstrap'
import { getFetchQuery, parseQuery, setUrlQueryString } from '../../../helpers/shared/dataTable/dataTableHelper'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import ManningPoolBadge from '../../../components/shared/dataTable/cells/manningPoolBadge'
import DataTable from '../../../components/shared/dataTable/dataTable'

const params = () => {
  const {id} = useParams();
  return id;
}
class CrewShow extends React.Component {
  static columns = [
    {
      Header: 'PIN',
      accessor: 'PIN'
    }, {
      Header: 'First name',
      accessor: 'firstname'
    }, {
      Header: 'Last name',
      accessor: 'lastname'
    }, {
      Header: 'Position',
      accessor: 'position'
    }, {
      Header: 'Department',
      id: 'department',
      accessor: row => (row.Department ? row.Department.name : '-')
    }, {
      Header: 'Vessel',
      id: 'VesselId',
      accessor: row => (row.Vessel ? <Link to={`/vessels/${row.Vessel.id}`}>{row.Vessel.name}</Link> : '-')
    }, {
      Header: 'Nationality',
      id: 'nationality',
      accessor: row => (row.Nationality ? row.Nationality.label : '-'),
      sortable: false
    }, {
      Header: 'Manning pool',
      id: 'ManningPoolId',
      accessor: row => <ManningPoolBadge manningPool={row.ManningPool} />
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      crewDetails: {
        id: null,
        name: null
      },
      teamDetails: {
        id: null,
        name: null
      },
      pages: null,
      loading: true
    }
  }

  teamId = this.props.match['*'].split('/')[0];
  crewId = this.props.match['*'].split('/')[1];
  componentDidMount() {
    console.log('COMPONENT DID MOUNT CREW SHOW COMPONENT');
    const { teamsActions } = this.props;

    console.log('MATCH', this.teamId, params);
    teamsActions.fetchTeamById(this.teamId)
      .then(({ response }) => {
        this.setState({
          teamDetails: {
            id: response.id,
            name: response.name
          }
        })
        this.fetchCrew()
      })
  }

  fetchCrew = (query = {}) => {
    const { crewsActions, location, history } = this.props
    const { page, limit, sort, orderBy, filter } = this.props.crew

    this.setState({
      loading: true,
      parsedQuery: parseQuery(query)
    }, () => {
      crewsActions.fetchCrewById(this.crewId, {
        page,
        limit,
        sort,
        orderBy,
        filter,
        ...getFetchQuery(this.state.parsedQuery, location.search)
      })
        .then(({ response }) => {
          setUrlQueryString(history, response.CrewMembers)
          this.setState({
            data: response.CrewMembers.entities,
            crewDetails: {
              id: response.id,
              name: response.name
            },
            pages: Math.ceil(response.CrewMembers.totalCount / limit),
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  handleRowClick = (state, rowInfo) => {
    const { location } = this.props
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/profiles/${rowInfo.original.PIN}`, {
          from: location.pathname,
          teamId: rowInfo.original.TeamId
        })
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Crew')
    this.setState({
      loading: false
    })
  }

  render() {
    const { crew } = this.props
    const { data, crewDetails, teamDetails, pages, loading } = this.state

    return (
      <div id="crew-show">
        <PageHeader>
          <Link to="/teams">Teams</Link> / <Link to={`/teams/${teamDetails.id}`}>{teamDetails.name}</Link> / {crewDetails.name}
        </PageHeader>
        <DataTable
          loading={loading}
          data={data}
          columns={CrewShow.columns}
          tableState={{
            ...crew,
            pages
          }}
          onFetch={this.fetchCrew}
          onRowClick={this.handleRowClick}
          customEmptyResultsMsg="No crew members in this crew"
        />
      </div>
    )
  }
}

CrewShow.propTypes = {
  teamsActions: PropTypes.object.isRequired,
  crew: PropTypes.object.isRequired,
  crewsActions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default CrewShow
