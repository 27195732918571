export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'

export function showNotification(notification) {
  return { type: SHOW_NOTIFICATION, notification }
}

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'

export function resetNotifications() {
  return { type: RESET_NOTIFICATIONS }
}
