/**
 * Root Reducer
 */

import { combineReducers } from 'redux'
import modalsReducer from './shared/modals'
import userReducer from './user'
import crewMembersReducer from './crewMembers'
import crewsReducer from './crews'
import crewReducer from './crew'
import teamsReducer from './shared/teams'
import customersReducer from './customers'
import agenciesReducer from './agencies'
import contentsReducer from './contents'
import notificationReducer from './notifications'
import usersReducer from './users'
import userRolesReducer from './userRoles'
import docTypesReducer from './docTypes'
import loggedInUsersReducer from './loggedInUsers'
import vesselsReducer from './vessels'
import vesselsCrewMembersReducer from './vesselCrewMembers'
import teamMembersReducer from './shared/teamMembers'
import adonisSyncReducer from './adonisSync'

export default combineReducers({
  contents: contentsReducer,
  modal: modalsReducer,
  user: userReducer,
  users: usersReducer,
  docTypes: docTypesReducer,
  userRoles: userRolesReducer,
  loggedInUsers: loggedInUsersReducer,
  crewMembers: crewMembersReducer,
  crew: crewReducer,
  crews: crewsReducer,
  teams: teamsReducer,
  teamMembers: teamMembersReducer,
  customers: customersReducer,
  agencies: agenciesReducer,
  notifications: notificationReducer,
  vessels: vesselsReducer,
  vesselCrewMembers: vesselsCrewMembersReducer,
  adonisSync: adonisSyncReducer
})
