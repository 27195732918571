import PersonalDetails from './personalDetails'
import NextOfKin from './nextOfKin'
import Airports from './airports'
import Certificates from './certificates'
import Medicals from './medicals'
import TravelDocuments from './travelDocuments'
import EnclosedDocuments from './enclosedDocuments'
import Activities from './activities'
import Forms from './forms'

export default {
  PersonalDetails,
  NextOfKin,
  Airports,
  Certificates,
  Medicals,
  TravelDocuments,
  EnclosedDocuments,
  Activities,
  Forms
}
