import React from 'react'
import {Route, useLocation, useParams} from 'react-router-dom'
import { extendWithProps } from '../../router'
import List from './list'
import Show from './show'
import {Routes, useNavigate} from "react-router";
import Edit from "../contents/edit";

const CrewMembers = (props) => {

    let location = useLocation();
    let navigate = useNavigate();
    const match = useParams();

    return (
        <div id="crew-members">
            <Routes>
                <Route exact path="/" element={<List {...props} location={location} history={navigate}/>}/>
                <Route path="/:pin" element={<Show {...props} location={location} history={navigate} match={match}/>}/>
                <Route path="/:pin/personal-details" element={<Show {...props} location={location} history={navigate} match={match}/>}/>
            </Routes>
        </div>
    )

}

export default CrewMembers
