import '@babel/polyfill'
import {createRoot} from 'react-dom/client'
import 'react-table/react-table.css'
import apiRequest from '../common/helpers/apiHelper'
import routes from '../common/router'
import configureStore from '../common/store'
import './stylesheets/main.less'

document.addEventListener('DOMContentLoaded', async () => {
    const initialState = {}

    try {
        const res = await apiRequest('/api/me')
        initialState.user = {
            user: {
                ...res.json,
                requiresPasswordUpdate: false,
                requiresMfaSetup: false,
                requiresMfa: false
            },
            loggedIn: true,
            isFetching: false,
            token: localStorage.getItem('token')
        }
    } catch (e) {
        console.log('Could not fetch user', e)
    }

    const store = configureStore(initialState)
    const routesWithStore = routes(store);
    const root = createRoot(document.getElementById('content'));
    root.render(routesWithStore);
})
