import React from 'react'
import PropTypes from 'prop-types'
import shortId from 'shortid'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { CONTENT_TYPES } from '../../constants'

const ContentTypeSelector = (props) => {
  const contentTypes = [
    { value: CONTENT_TYPES.NEWS, label: 'News' },
    { value: CONTENT_TYPES.JOB_OFFER, label: 'Forms and documents' },
    { value: CONTENT_TYPES.TRAINING_MATERIALS, label: 'Training Materials' },
    { value: CONTENT_TYPES.RULES_REGULATIONS, label: 'Rules and Regulations' }
    // Disabled content type (kept for future use)
    // { value: CONTENT_TYPES.ITINERARIES, label: 'Itineraries' }
  ]

  return (
    <form>
      <FormGroup controlId="contentTypeSelect">
        <ControlLabel>Content Type</ControlLabel>
        <FormControl
          componentClass="select"
          placeholder="select"
          onChange={(evt) => props.onChange(evt.target.value)}
          value={props.value}>
          {contentTypes.map((type) => (
            <option key={shortId.generate()} value={type.value}>
              {type.label}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    </form>
  )
}

ContentTypeSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}

ContentTypeSelector.defaultProps = {
  value: CONTENT_TYPES.NEWS,
  onChange: () => null
}

export default ContentTypeSelector
