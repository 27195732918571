import React from 'react'
import { Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'

class CrewMemberUserForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: ''
    }
  }

  render() {
    return (
      <Form>
        <FormGroup controlId="formHorizontalEmail">
          <ControlLabel>Username</ControlLabel>
          <FormControl
            type="text"
            placeholder="Username"
            value={this.state.username}
            onChange={(evt) => this.setState({ username: evt.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="text"
            placeholder="Initial Password"
            value={this.state.password}
            onChange={(evt) => this.setState({ password: evt.target.value })}
          />
        </FormGroup>
      </Form>
    )
  }
}

export default CrewMemberUserForm
