import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PageHeader, Row, Col, Panel } from 'react-bootstrap'
import { showNotificationFailed } from '../../helpers/shared/notification'
import DateCell from '../../components/shared/dataTable/cells/dateCell'
import DataTable from '../../components/shared/dataTable/dataTable'

class CustomersShow extends React.Component {
  static columns = [
    {
      Header: 'Username',
      accessor: 'username',
      sortable: false
    }, {
      Header: 'Created at',
      id: 'createdAt',
      accessor: row => <DateCell date={row.createdAt} />,
      sortable: false
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pages: 1,
      loading: true,
      orderBy: 'name',
      sort: 'asc'
    }
  }

  componentDidMount() {
    this.fetchCustomerData()
  }

  fetchCustomerData = () => {
    const { customersActions, match } = this.props

    this.setState({
      loading: true
    }, () => {
      customersActions.fetchCustomerById(match['*'])
        .then(({ response }) => {
          this.setState({
            data: response,
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/users/${rowInfo.original.id}`, { from: this.props.location.pathname })
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch customer data')
    this.setState({
      loading: false
    })
  }

  renderInfo = () => {
    const { data } = this.state

    if (data.address) {
      return (
        <Row>
          <Col xs={12}>
            <Panel>
              <h4>Info</h4>
              <address>
                <div className="address-line">
                  <span className="address-label">Street:</span>
                  { data.address.street || <span className="not-available">not available</span> }
                </div>
                <div className="address-line">
                  <span className="address-label">Post code:</span>
                  { data.address.zip || <span className="not-available">not available</span> }
                </div>
                <div className="address-line">
                  <span className="address-label">City:</span>
                  { data.address.city || <span className="not-available">not available</span> }
                </div>
                <div className="address-line">
                  <span className="address-label">Country:</span>
                  { data.address.country || <span className="not-available">not available</span> }
                </div>
              </address>
            </Panel>
          </Col>
        </Row>
      )
    }
  }

  render() {
    console.log('RENDER CUSTOMER SHOW')
    const { data, pages, loading, orderBy, sort } = this.state
    const users = data.Users || []

    return (
      <div id="customers-show">
        <PageHeader>
          <Link to="/customers">Customers</Link> / {data.name}
        </PageHeader>
        { this.renderInfo() }
        <Row>
          <Col xs={12}>
            <Panel>
              <h4>Users</h4>
              <div className="details-table">
                <DataTable
                  loading={loading}
                  data={users}
                  columns={CustomersShow.columns}
                  tableState={{
                    pages, page: 1, orderBy, sort
                  }}
                  onFetch={this.fetchCustomerData}
                  onRowClick={this.handleRowClick}
                  customEmptyResultsMsg="No users for this customer"
                />
              </div>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}

CustomersShow.propTypes = {
  customersActions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  history: PropTypes.func,
  location: PropTypes.object.isRequired
}

export default CustomersShow
