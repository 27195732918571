import React from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter, Route, Redirect, useParams} from 'react-router-dom'
import { Provider } from 'react-redux'
import MainContainer from './container/main'
import {Navigate, Routes} from "react-router";

/*export const extendWithProps = (Comp, props) => routerProps => {
    console.log(Comp, props)
    return  <Comp {...props} {...routerProps} />
}*/
/*export const ExtendWithProps = (Comp, props) => routerProps => {
    console.log(Comp, props)
    return  <Comp {...props} {...routerProps} />
}*/

export const extendWithProps = (Comp, props) => routerProps => {
    return {...props, ...routerProps};
};

export const PrivateRoute = ({children} ) =>{
    function isAuthorized() {
        if (children.props.authorize) {
            return children.props.authorize.includes(children[1].props.userRole)
        }
        return true
    }

    const authed = children.props.user.loggedIn && isAuthorized();

    return authed ? children : <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  //render: PropTypes.func.isRequired,
  location: PropTypes.object // eslint-disable-line
}

function routes(store) {
  return (
    <Provider store={store}>
      <BrowserRouter>
          <Routes>
              <Route path="*" element={ <MainContainer />} />
          </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default routes
