import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, PageHeader, ListGroup, ListGroupItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {Link} from 'react-router-dom'
import { ROLES } from '../constants'
import { showNotificationFailed } from '../helpers/shared/notification'

class Home extends React.Component {
  constructor(props) {
    super(props)
  }

  showSyncError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Agencies')
  }

  render() {

    const { role } = this.props.user.user
    const { privileges } = this.props.user.user.Right

    return (
      <div id="home">
        <PageHeader>
          Backoffice
          {role === ROLES.ADMIN ? (
            <Link to="/adonis-sync">
              <Button className="actions pull-right" bsStyle="primary">Adonis Sync</Button>
            </Link>
          ) : null}
        </PageHeader>
        <Row>
          <Col xs={12}>
            <ListGroup>
              {privileges.content !== 0 && (
                <LinkContainer to="/content">
                  <ListGroupItem header="Content">Manage Content Posts</ListGroupItem>
                </LinkContainer>
              )}
              {role === ROLES.ADMIN && (
                <LinkContainer to="/profiles">
                  <ListGroupItem header="Crew Members">Manage Crew Members</ListGroupItem>
                </LinkContainer>
              )}
              {privileges.teams !== 0 && (
                <LinkContainer to="/teams">
                  <ListGroupItem header="Teams">Manage Teams</ListGroupItem>
                </LinkContainer>
              )}
              {role === ROLES.ADMIN && (
                <Fragment>
                  <LinkContainer to="/customers">
                    <ListGroupItem header="Customers">Manage Customers</ListGroupItem>
                  </LinkContainer>
                  <LinkContainer to="/agencies">
                    <ListGroupItem header="Agencies">Manage Agencies</ListGroupItem>
                  </LinkContainer>
                  <LinkContainer to="/vessels">
                    <ListGroupItem header="Vessels">Manage Vessels</ListGroupItem>
                  </LinkContainer>
                  <LinkContainer to="/users">
                    <ListGroupItem header="Users">Manage Users</ListGroupItem>
                  </LinkContainer>
                  <LinkContainer to="/academy">
                    <ListGroupItem header="sea chefs Academy">Manage Academy Settings</ListGroupItem>
                  </LinkContainer>
                </Fragment>
              )}
            </ListGroup>
          </Col>
        </Row>
      </div>
    )
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default Home
