import { USER_PASS_REGEXP } from '../constants'

export const checkIsEmail = (emailStr = '') => {
  const isEmail = !!(emailStr && emailStr.indexOf('@') !== -1 && emailStr.indexOf('.') !== -1)
  return isEmail
}

export const isValidPassword = (password = '') => {
  const validationRule = new RegExp(USER_PASS_REGEXP)
  return validationRule.test(password)
}
