import {
  FETCH_ADONIS_TOKEN,
  FETCH_ADONIS_TOKEN_SUCCESS,
  FETCH_ADONIS_TOKEN_FAILURE
} from '../actions/adonisSync'

const defaultState = {
  token: ''
}

export default function adonisSyncReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_ADONIS_TOKEN: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case FETCH_ADONIS_TOKEN_SUCCESS: {
      const { token } = action.response

      return {
        ...state,
        token
      }
    }
    case FETCH_ADONIS_TOKEN_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
