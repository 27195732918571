import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import shortId from 'shortid'
import 'react-quill/dist/quill.snow.css'
import { FormGroup, FormControl, Panel, ControlLabel, HelpBlock, Checkbox } from 'react-bootstrap'
import MaterialIcon from 'material-icons-react'
import ContentTypeSelector from './contentTypeSelector'
import AudienceSelector from './audienceSelector'
import { CONTENT_TYPES } from '../../constants'

export { AudienceSelector, ContentTypeSelector }

export const isValidFileSize = (file) => {
  if (!file) return true
  const { size } = file
  return size / 1024 / 1024 < 2.5
}

export const isValidFileType = (file) => {
  if (!file) return true
  const { type } = file
  const re = /^image\/(jpe?g|png)$|^application\/(pdf|msword|vnd.ms-excel|(vnd\.openxmlformats-officedocument.wordprocessingml.document|(vnd\.openxmlformats-officedocument.spreadsheetml.sheet)))$/i
  return type.match(re)
}

class ContentForm extends React.Component {
  constructor(props) {
    super(props)

    if (props.content) {
      this.state = {
        headline: props.content.headline || '',
        lead: props.content.lead || '',
        content: props.content.content || '',
        files: props.content.files || [],
        file: null,
        filesToRemove: []
      }
    } else {
      this.state = {
        headline: '',
        lead: '',
        content: {
          text: ''
        },
        files: [],
        file: null,
        filesToRemove: []
      }
    }
  }

  getContent = () => ({ ...this.state })

  resetFileState = (files) => (
    this.setState({
      files: files,
      file: null,
      filesToRemove: []
    })
  )

  getHumanReadableContentType = () => {
    switch (this.props.contentType) {
      case CONTENT_TYPES.JOB_OFFER:
        return 'Forms and documents'
      case CONTENT_TYPES.TRAINING_MATERIALS:
        return 'Training Materials'
      case CONTENT_TYPES.RULES_REGULATIONS:
        return 'Rules and Regulations'
      case CONTENT_TYPES.ITINERARIES:
        return 'Itineraries'
      default:
        return 'News'
    }
  }

  handleHeadlineChange = (evt) => this.setState({ headline: evt.target.value })

  handleLeadChange = (evt) => this.setState({ lead: evt.target.value })

  handleTextChange = (evt) => this.setState({ content: { text: evt } })

  handleFileChange = (evt) => this.setState({ file: evt.target.files[0] })

  handleFileRemove = (index) => {
    let { filesToRemove } = this.state

    if (filesToRemove.includes(index)) {
      filesToRemove = filesToRemove.filter(_index => _index !== index)
    } else {
      filesToRemove.push(index)
    }
    this.setState({ filesToRemove })
  }
  render() {
    const isValidSize = true// isValidFileSize(this.state.file)
    const isValidType = isValidFileType(this.state.file)

    return (
      <Panel>
        <h4>
          {this.props.operation} {this.getHumanReadableContentType()}
        </h4>
        <form id="news-form">
          <FormGroup>
            <ControlLabel>Headline</ControlLabel>
            <FormControl
              type="text"
              value={this.state.headline}
              onChange={this.handleHeadlineChange}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Excerpt</ControlLabel>
            <FormControl
              type="text"
              className="lead"
              value={this.state.lead}
              onChange={this.handleLeadChange}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Content</ControlLabel>
            <ReactQuill
              value={this.state.content.text}
              onChange={this.handleTextChange}
              modules={{
                toolbar: [['bold', 'italic', 'underline']]
              }}
            />
          </FormGroup>

          {this.state.files.map((file, i) => {
            const isChecked = this.state.filesToRemove.includes(i)
            return (
              <FormGroup
                key={shortId.generate()}>
                <Checkbox
                  style={{ display: 'block' }}
                  checked={isChecked}
                  onChange={() => this.handleFileRemove(i)}>
                  Remove file #{i + 1}
                </Checkbox>
                <div className="file-upload-info">
                  {file.path.replace(/content\/\d*\//, '')}
                </div>
              </FormGroup>
            )
          })}

          <FormGroup>
            <ControlLabel style={{ display: 'block' }}>
              Add new file
            </ControlLabel>
            <ControlLabel
              htmlFor="file-upload1"
              className="btn btn-default">
              <MaterialIcon icon="file_upload" size="20" /> Upload file
            </ControlLabel>
            <FormControl
              id="file-upload1"
              type="file"
              style={{ display: 'none' }}
              onChange={this.handleFileChange}
            />
            <div className="file-upload-info">{this.state.file ? this.state.file.name : ''}</div>
            <HelpBlock className={isValidSize ? 'hidden' : null}>
              <MaterialIcon icon="info_outline" size="16" />
              File size exceeds 2.5 MB
            </HelpBlock>
            <HelpBlock className={isValidType ? 'hidden' : null}>
              <MaterialIcon icon="info_outline" size="16" />
              Accepted file formats: PDF, JPG, PNG, DOC, DOCX
            </HelpBlock>
          </FormGroup>
        </form>
      </Panel>
    )
  }
}

ContentForm.propTypes = {
  content: PropTypes.object,
  contentType: PropTypes.string,
  operation: PropTypes.string
}

ContentForm.defaultProps = {
  content: null,
  contentType: 'NEWS',
  operation: 'New'
}

export default ContentForm
