import NProgress from 'nprogress'

function isClient() {
  return typeof document !== 'undefined'
}

const progressBar = {
  start: () => (isClient() ? NProgress.start() : null),
  set: (val) => (isClient() ? NProgress.set(val) : null),
  done: () => (isClient() ? NProgress.done() : null)
}

export default progressBar
