import React from 'react'
import PropTypes from 'prop-types'
import ConfirmModal, { confirmType } from './confirm'
import FormModal, { formType } from './form'
import PreviewModal, { previewType } from './preview'

export const ModalTypes = {
  confirm: confirmType,
  form: formType,
  preview: previewType
}

class Modals extends React.Component {
  constructor() {
    super()

    this.modalComponents = {
      [confirmType]: ConfirmModal,
      [formType]: FormModal,
      [previewType]: PreviewModal
    }
  }

  render() {
    const { modalType, modalProps, onClose } = this.props

    if (!modalType) return null

    const Modal = this.modalComponents[modalType]

    return <Modal {...modalProps} onExited={onClose} />
  }
}

Modals.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  onClose: PropTypes.func
}

Modals.defaultProps = {
  modalType: null,
  modalProps: {},
  onClose: null
}

export default Modals
