import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CREATE_CONTENT_EDITOR_USER,
  CREATE_CONTENT_EDITOR_USER_SUCCESS,
  CREATE_CONTENT_EDITOR_USER_FAILURE,
  CREATE_ADMIN_USER,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RESET_USER,
  RESET_USER_SUCCESS,
  RESET_USER_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE
} from '../actions/users'

const defaultState = {
  page: 1,
  limit: 10,
  offset: 0,
  orderBy: 'username',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function usersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_USERS:
    case FETCH_USER:
    case CREATE_CONTENT_EDITOR_USER:
    case CREATE_ADMIN_USER:
    case UPDATE_USER:
    case RESET_USER:
    case CREATE_USER: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case DELETE_USER: {
      const { entities } = state
      delete entities[action.id]
      return { ...state, entities, isFetching: true, didFetch: true }
    }
    case FETCH_USERS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        didFetch: false,
        error: null,
        entities: action.normalized.entities.users || {}
      }
    }
    case FETCH_USER_SUCCESS:
    case CREATE_CONTENT_EDITOR_USER_SUCCESS:
    case CREATE_ADMIN_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case RESET_USER_SUCCESS:
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        didFetch: !action.response.id,
        isFetching: false,
        error: null,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }
    case DELETE_USER_SUCCESS: {
      const { entities } = state
      delete entities[action.id]
      return { ...state, entities, isFetching: false, error: null }
    }
    case FETCH_USERS_FAILURE:
    case FETCH_USER_FAILURE:
    case CREATE_CONTENT_EDITOR_USER_FAILURE:
    case CREATE_ADMIN_USER_FAILURE:
    case UPDATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
    case RESET_USER_FAILURE:
    case CREATE_USER_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
