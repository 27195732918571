import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

export const docTypes = new schema.Entity('docTypes', {}, { idAttribute: 'id' })
export const docTypesList = new schema.Array(docTypes)

export const FETCH_DOC_TYPES = 'FETCH_DOC_TYPES'
export const FETCH_DOC_TYPES_SUCCESS = 'FETCH_DOC_TYPES_SUCCESS'
export const FETCH_DOC_TYPES_FAILURE = 'FETCH_DOC_TYPES_FAILURE'

export function fetchDocTypes(queryParams) {
  let endpoint = '/api/docTypes'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      normalizeKey: 'entities',
      schema: docTypesList,
      types: [FETCH_DOC_TYPES, FETCH_DOC_TYPES_SUCCESS, FETCH_DOC_TYPES_FAILURE]
    }
  }
}

export function fetchDocTypesIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.docTypes.isFetching && !state.docTypes.didFetch) {
      return dispatch(fetchDocTypes(queryParams))
    }
  }
}

export const UPDATE_RESTRICTIONS = 'UPDATE_RESTRICTIONS'
export const UPDATE_RESTRICTIONS_SUCCESS = 'UPDATE_RESTRICTIONS_SUCCESS'
export const UPDATE_RESTRICTIONS_FAILURE = 'UPDATE_RESTRICTIONS_FAILURE'

export function updateRestrictions(id, body) {
  const endpoint = `/api/docTypes/${id}/restrictions`

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'put',
      schema: docTypes,
      types: [UPDATE_RESTRICTIONS, UPDATE_RESTRICTIONS_SUCCESS, UPDATE_RESTRICTIONS_FAILURE]
    }
  }
}
