import {
  FETCH_TEAMS,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAM,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  FETCH_ADONIS_TEAMS,
  FETCH_ADONIS_TEAMS_SUCCESS,
  FETCH_ADONIS_TEAMS_FAILURE,
  FETCH_BASIC_TEAMS,
  FETCH_BASIC_TEAMS_SUCCESS,
  FETCH_BASIC_TEAMS_FAILURE,
  SET_TEAM_MEMBER_PAGE,
  CLEAR_TEAM_DETAIL,
  SELECTED_TEAM,
  INCLUDE_DIRECT_CREW,
  EXCLUDE_DIRECT_CREW
} from '../../actions/shared/teams'

const defaultState = {
  page: 1,
  limit: 20,
  offset: 0,
  orderBy: 'name',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function teamsReducer(state = defaultState, action) {
  switch (action.type) {
    // Is Fetching

    case FETCH_TEAMS:
    case FETCH_BASIC_TEAMS:
    case FETCH_TEAM: {
      return { ...state, isFetching: true, didFetch: true }
    }

    // Is Success

    case FETCH_BASIC_TEAMS_SUCCESS: {
      const {
        page, limit, orderBy, sort, totalCount, filter
      } = action.response
      return {
        ...state,
        page,
        limit,
        orderBy,
        sort,
        filter,
        totalCount,
        entities: action.normalized.entities.team || {}
      }
    }
    case FETCH_TEAMS_SUCCESS: {
      const {
        page, limit, offset, orderBy, sort, totalCount, filter
      } = action.response
      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        entities: action.normalized.entities.team || {}
      }
    }
    case FETCH_TEAM_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }
    // Is Failure
    case FETCH_TEAMS_FAILURE:
    case FETCH_BASIC_TEAMS_FAILURE:
    case FETCH_TEAM_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }

    case FETCH_ADONIS_TEAMS: {
      return { ...state, isFetching: true }
    }

    case FETCH_ADONIS_TEAMS_SUCCESS: {
      // TODO: Unused?
      return { ...state, isFetching: false }
    }

    case FETCH_ADONIS_TEAMS_FAILURE: {
      return { ...state, isFetching: false, error: action.error }
    }

    case SET_TEAM_MEMBER_PAGE: {
      const TmPage = action.page
      return { ...state, TmPage }
    }

    case CLEAR_TEAM_DETAIL: {
      return {
        ...state,
        TmPage: 1,
        entities: {
          ...state.entities,
          [action.teamId]: {
            ...state.entities[action.teamId],
            CrewMembers: [],
            Crews: []
          }
        }
      }
    }

    case SELECTED_TEAM: {
      return { ...state, selectedTeam: action.selectedTeam }
    }

    default:
      return state
  }
}
