import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

const agency = new schema.Entity('agencies', { idAttribute: 'id' })
const agenciesList = new schema.Array(agency)

/**
 * Async Action Creators
 */

export const FETCH_AGENCIES = 'FETCH_AGENCIES'
export const FETCH_AGENCIES_SUCCESS = 'FETCH_AGENCIES_SUCCESS'
export const FETCH_AGENCIES_FAILURE = 'FETCH_AGENCIES_FAILURE'

export function fetchAgencies(queryParams) {
  let endpoint = '/api/agencies'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: agenciesList,
      normalizeKey: 'entities',
      types: [FETCH_AGENCIES, FETCH_AGENCIES_SUCCESS, FETCH_AGENCIES_FAILURE]
    }
  }
}

export function fetchAgenciesIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.agencies.isFetching && !state.agencies.didFetch) {
      return dispatch(fetchAgencies(queryParams))
    }
  }
}

export const FETCH_AGENCY = 'FETCH_AGENCY'
export const FETCH_AGENCY_SUCCESS = 'FETCH_AGENCY_SUCCESS'
export const FETCH_AGENCY_FAILURE = 'FETCH_AGENCY_FAILURE'

export function fetchAgencyById(id) {
  const endpoint = `/api/agencies/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: agency,
      types: [FETCH_AGENCY, FETCH_AGENCY_SUCCESS, FETCH_AGENCY_FAILURE]
    }
  }
}

export const SET_AGENCY_USERS_PAGE = 'SET_AGENCY_USERS_PAGE'

export function setAgencyUsersPage(page) {
  return { type: SET_AGENCY_USERS_PAGE, page }
}

export const CREATE_AGENCY_USER = 'CREATE_AGENCY_USER'
export const CREATE_AGENCY_USER_SUCCESS = 'CREATE_AGENCY_USER_SUCCESS'
export const CREATE_AGENCY_USER_FAILURE = 'CREATE_AGENCY_USER_FAILURE'

export function createAgencyUser(agencyId, credentials) {
  const endpoint = `/api/agencies/${agencyId}/users`

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      body: credentials,
      types: [CREATE_AGENCY_USER, CREATE_AGENCY_USER_SUCCESS, CREATE_AGENCY_USER_FAILURE]
    }
  }
}

export const UPDATE_AGENCY = 'UPDATE_AGENCY'
export const UPDATE_AGENCY_SUCCESS = 'UPDATE_AGENCY_SUCCESS'
export const UPDATE_AGENCY_FAILURE = 'UPDATE_AGENCY_FAILURE'

export function updateAgency(agencyId, body) {
  const endpoint = `/api/agencies/${agencyId}`

  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'put',
      types: [UPDATE_AGENCY, UPDATE_AGENCY_SUCCESS, UPDATE_AGENCY_FAILURE]
    }
  }
}

export const RESET_AGENCIES_STATUS = 'RESET_AGENCY_STATUS';
