import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

class Certificates extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { certificates } = this.props
    return (
      <div id="travel-documents">
        <h4>Certificates</h4>
        <Table responsive>
          <thead>
            <tr>
              <th>Training Type</th>
              <th>Document Number</th>
              <th>Training Start</th>
              <th>Training End</th>
              <th>Expiry Date</th>
              <th>Issued By</th>
              <th>Issued In</th>
            </tr>
          </thead>
          <tbody>
            {certificates.length === 0 ? (
              <tr>
                <td>
                  <i>No attached files</i>
                </td>
              </tr>
            ) : (
              <tr>
                <td>{certificates.trainingTypes}</td>
                <td>{certificates.number}</td>
                <td>{certificates.trainingStart}</td>
                <td>{certificates.trainingEnd}</td>
                <td>{certificates.expiryDate}</td>
                <td>{certificates.issuedBy}</td>
                <td>{certificates.issuedWhere}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

Certificates.propTypes = {
  certificates: PropTypes.array
}

Certificates.defaultProps = {
  certificates: []
}

export default Certificates
