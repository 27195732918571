import React from 'react'
import MaterialIcon from 'material-icons-react'

export function showNotificationSuccess(notificationsActions, msg) {
  return (
    notificationsActions.showNotification({
      message: msg,
      level: 'success',
      children: (
        <div className="pull-left">
          <MaterialIcon icon="check" color="white" />
        </div>
      )
    })
  )
}

export function showNotificationFailed(notificationsActions, msg) {
  return (
    notificationsActions.showNotification({
      message: msg,
      level: 'error',
      children: (
        <div className="pull-left">
          <MaterialIcon icon="error" color="white" />
        </div>
      )
    })
  )
}
