import React from 'react'
import QueryString from 'query-string'
import moment from 'moment'

export const parseQuery = (query) => {
  const parsedQuery = {}

  if (Object.keys(query).length > 0) {
    parsedQuery.page = query.page + 1 || 1

    if (query.sorted && query.sorted.length > 0) {
      parsedQuery.sort = query.sorted[0].desc ? 'DESC' : 'ASC'
      parsedQuery.orderBy = query.sorted[0].id
    }

    parsedQuery.filter = query.filtered && query.filtered.length > 0 ? query.filtered[0].value : ''
  }
  return parsedQuery
}

export const composeQuery = (filter) => {
  const composedQuery = {
    filtered: []
  }

  if (filter !== '') {
    composedQuery.filtered.push({
      value: filter
    })
  }
  return composedQuery
}

export const getFetchQuery = (parsedQuery, search) =>
  (Object.keys(parsedQuery).length === 0 ? QueryString.parse(search) : parsedQuery)

export const setUrlQueryString = (navigate, response) => {

  const queryString = {
    page: response.page,
    sort: response.sort,
    orderBy: response.orderBy
  }

  if (response.filter) {
    queryString.filter = response.filter
  }

  navigate({
    search: QueryString.stringify(queryString)
  })
}

export const setSerializedSettings = (navigate, settings) => {
  navigate({
    search: QueryString.stringify({
      settings: btoa(JSON.stringify(settings))
    })
  })
}

export const getDeserializedSettings = (search) => {
  if (search) {
    const deserializedSettings = JSON.parse(atob(QueryString.parse(search).settings))
    const {
      tableColumns, tableSettings, additionalFilters, documentChecksList, allDocuments
    } = deserializedSettings

    return {
      tableColumns,
      tableSettings,
      documentChecksList,
      allDocuments,
      additionalFilters: {
        ...additionalFilters,
        fromEmbarkationDate: additionalFilters.fromEmbarkationDate ? moment.utc(additionalFilters.fromEmbarkationDate, 'YYYY-MM-DD') : null,
        toEmbarkationDate: additionalFilters.toEmbarkationDate ? moment.utc(additionalFilters.toEmbarkationDate, 'YYYY-MM-DD') : null,
        fromDisembarkationDate: additionalFilters.fromDisembarkationDate ? moment.utc(additionalFilters.fromDisembarkationDate, 'YYYY-MM-DD') : null,
        toDisembarkationDate: additionalFilters.toDisembarkationDate ? moment.utc(additionalFilters.toDisembarkationDate, 'YYYY-MM-DD') : null
      }
    }
  }
  return {
    tableSettings: {},
    additionalFilters: {}
  }
}
