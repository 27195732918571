import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'
import { getUserType } from '../../../../helpers/usersHelpers'


class UserTypeBadge extends React.Component {
  constructor(props) {
    super(props)
  }

  labelStyles = {
    display: 'block',
    margin: '2px',
    width: 'fit-content'
  }

  render() {
    const { user } = this.props
    const badgeData = getUserType(user)

    let componentToRender = (
      <Label bsStyle={badgeData.style}>{badgeData.name}</Label>
    )

    if (badgeData.links) {
      componentToRender = badgeData.links.map((link, i) => (
        <Label style={this.labelStyles} key={link} bsStyle={badgeData.style}>
          <Link to={link}>{badgeData.names[i]}</Link>
        </Label>
      ))
    }
    
    if (badgeData.link) {
      componentToRender = (
        <Label bsStyle={badgeData.style}>
          <Link to={badgeData.link}>{badgeData.name}</Link>
        </Label>
      )
    }

    return componentToRender
  }
}

UserTypeBadge.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserTypeBadge
