import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, PageHeader, Button, ButtonGroup } from 'react-bootstrap'
import { showNotificationFailed } from '../../helpers/shared/notification'
import LoadingSpinner from '../../components/shared/loadingSpinner'

class Admin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      runningTests: false,
      runTestsFinished: false
    }
  }

  showSyncError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to run tests')
  }

  migrate = (cmd) => {
    this.props.syncActions.migrate(cmd)
      .then((res) => console.log('Migrate result:', res.response))
      .catch((err) => console.error('Migrate error:', err))
  }

  runTests = () => {
    this.setState({
      runningTests: true,
      runTestsFinished: false
    })
    this.props.syncActions.runTests()
      .then((res) => {
        // console.log('Test results:', res.response)
        this.setState({
          runningTests: false,
          runTestsFinished: true
        })
      })
      .catch((err) => {
        this.showSyncError()
        this.setState({
          runningTests: false,
          runTestsFinished: false
        })
        console.error('Test error:', err)
      })
  }

  render() {
    const userId = this.props.user.user.id

    let token = localStorage.getItem('token')
    try {
      token = JSON.parse(token)
    } catch (error) { }

    return (
      <div id="home">
        <PageHeader>
          Administration
          <ButtonGroup className="pull-right hidden-xs">
            <Button bsStyle="info" disabled={this.state.runningTests} onClick={() => this.runTests()}>
              {!this.state.runningTests ? 'Run API tests' : 'Running API tests'}
            </Button>
          </ButtonGroup>
        </PageHeader>
        {this.state.runningTests && (
          <LoadingSpinner text="Running API tests" />
        )}
        {this.state.runTestsFinished && (
          <iframe src={`./test-results/index.html?token=${token}`} frameBorder="0" id="tests-results" title="Test results" />
        )}
        {userId === 1 && (
          <Row>
            <Col xs={12}>
              <ButtonGroup className="pull-right hidden-xs">
                <Button bsStyle="info" onClick={() => this.migrate('status')}>
                  Migrate status
                </Button>
                <Button bsStyle="info" onClick={() => this.migrate('up')}>
                  Migrate db up
                </Button>
                <Button bsStyle="info" onClick={() => this.migrate('down')}>
                  Migrate db down
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

Admin.propTypes = {
  user: PropTypes.object.isRequired,
  syncActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default Admin
