import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

export const userRoles = new schema.Entity('userRoles', {}, { idAttribute: 'id' })
export const userRolesList = new schema.Array(userRoles)

export const FETCH_USER_ROLES = 'FETCH_USER_ROLES'
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS'
export const FETCH_USER_ROLES_FAILURE = 'FETCH_USER_ROLES_FAILURE'

export function fetchUserRoles(queryParams) {
  let endpoint = '/api/rights'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      normalizeKey: 'entities',
      schema: userRolesList,
      types: [FETCH_USER_ROLES, FETCH_USER_ROLES_SUCCESS, FETCH_USER_ROLES_FAILURE]
    }
  }
}

export const CREATE_NEW_USER_ROLE = 'CREATE_NEW_USER_ROLE'
export const CREATE_NEW_USER_ROLE_SUCCESS = 'CREATE_NEW_USER_ROLE_SUCCESS'
export const CREATE_NEW_USER_ROLE_FAILURE = 'CREATE_NEW_USER_ROLE_FAILURE'

export function createNewRole(body) {
  const endpoint = '/api/rights'
  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'post',
      schema: userRoles,
      types: [
        CREATE_NEW_USER_ROLE,
        CREATE_NEW_USER_ROLE_SUCCESS,
        CREATE_NEW_USER_ROLE_FAILURE
      ]
    }
  }
}
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE'
export const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS'
export const DELETE_USER_ROLE_FAILURE = 'DELETE_USER_ROLE_FAILURE'

export function deleteUserRole(id) {
  const endpoint = `/api/rights/${id}`

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      types: [DELETE_USER_ROLE, DELETE_USER_ROLE_SUCCESS, DELETE_USER_ROLE_FAILURE]
    },
    id
  }
}

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS'
export const UPDATE_USER_ROLE_FAILURE = 'UPDATE_USER_ROLE_FAILURE'

export function updateUserRole(id, body) {
  const endpoint = `/api/rights/${id}`
  
  return {
    [CALL_API]: {
      endpoint,
      body,
      method: 'put',
      schema: userRoles,
      types: [UPDATE_USER_ROLE, UPDATE_USER_ROLE_SUCCESS, UPDATE_USER_ROLE_FAILURE]
    }
  }
}
