import { schema } from 'normalizr'
import { CALL_API } from '../middleware/apiMiddleware'

const crew = new schema.Entity('crew', {}, { idAttribute: 'id' })
const crewList = new schema.Array(crew)

/**
 * Async Action Creators
 */

export const FETCH_CREWS = 'FETCH_CREWS'
export const FETCH_CREWS_SUCCESS = 'FETCH_CREWS_SUCCESS'
export const FETCH_CREWS_FAILURE = 'FETCH_CREWS_FAILURE'

export function fetchCrews(queryParams) {
  let endpoint = '/api/crews'

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: crewList,
      normalizeKey: 'entities',
      types: [FETCH_CREWS, FETCH_CREWS_SUCCESS, FETCH_CREWS_FAILURE]
    }
  }
}

export function fetchCrewsIfNeeded(queryParams) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.crews.isFetching && !state.crews.didFetch) {
      return dispatch(fetchCrews(queryParams))
    }
  }
}

export const FETCH_CREW = 'FETCH_CREW'
export const FETCH_CREW_SUCCESS = 'FETCH_CREW_SUCCESS'
export const FETCH_CREW_FAILURE = 'FETCH_CREW_FAILURE'

export function fetchCrewById(id, queryParams) {
  let endpoint = `/api/crews/${id}`

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: crew,
      types: [FETCH_CREW, FETCH_CREW_SUCCESS, FETCH_CREW_FAILURE]
    }
  }
}

export const FETCH_CREW_BY_PARENT = 'FETCH_CREW_BY_PARENT'
export const FETCH_CREW_BY_PARENT_SUCCESS = 'FETCH_CREW_BY_PARENT_SUCCESS'
export const FETCH_CREW_BY_PARENT_FAILURE = 'FETCH_CREW_BY_PARENT_FAILURE'

export function fetchCrewByParentId(id, queryParams) {
  let endpoint = `/api/crewsbasic/${id}`

  Object.keys(queryParams).forEach((key, i) => {
    if (!queryParams[key]) return
    if (i === 0) {
      endpoint = `${endpoint}?${key}=${queryParams[key]}`
    } else {
      endpoint = `${endpoint}&${key}=${queryParams[key]}`
    }
  })

  return {
    ParentId: id,
    [CALL_API]: {
      endpoint,
      method: 'get',
      schema: crew,
      types: [FETCH_CREW_BY_PARENT, FETCH_CREW_BY_PARENT_SUCCESS, FETCH_CREW_BY_PARENT_FAILURE]
    }
  }
}

export const FETCH_ADONIS_CREWS = 'FETCH_ADONIS_CREWS'
export const FETCH_ADONIS_CREWS_SUCCESS = 'FETCH_ADONIS_CREWS_SUCCESS'
export const FETCH_ADONIS_CREWS_FAILURE = 'FETCH_ADONIS_CREWS_FAILURE'

export function fetchCrewsFromAdonis() {
  const endpoint = '/api/sync/crews'

  return {
    [CALL_API]: {
      endpoint,
      method: 'put',
      types: [FETCH_ADONIS_CREWS, FETCH_ADONIS_CREWS_SUCCESS, FETCH_ADONIS_CREWS_FAILURE]
    }
  }
}
