import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode-react'
import { Alert, Button, FormControl, FormGroup } from 'react-bootstrap'
import * as Cognito from '../../../helpers/shared/cognito/cognito'
import { showNotificationSuccess, showNotificationFailed } from '../../../helpers/shared/notification'

class MfaTotp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      totpCode: '',
      totpPassword: '',
      isSubmitting: false
    }
  }

  componentDidMount() {
    const { user: { user: { cognitoUser } } } = this.props

    Cognito.setupTotp(cognitoUser)
      .then((response) => {
        this.setState({
          totpCode: response
        })
      })
      .catch(() => {})
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  renderQrCode = () => {
    const { totpCode } = this.state
    const qrValue = `otpauth://totp/CrewCheckPortal?secret=${totpCode}`
    return <div style={{ textAlign: 'center', margin: '10px' }}><QRCode value={qrValue} /></div>
  }

  completeTotpVerification = () => {
    const { totpPassword } = this.state
    const {
      setMfaType,
      userActions,
      user: {
        user,
        user: { cognitoUser }
      },
      mfaTypes
    } = this.props

    this.setState({
      isSubmitting: true
    })

    if (totpPassword) {
      Cognito.completeTotp(cognitoUser, totpPassword)
        .then(() => {
          setMfaType(mfaTypes.TOTP)
            .then((response) => {
              const success = !!response
              userActions.setSession()
                .then(() => {
                  userActions.setUser({
                    ...user,
                    requiresMfaSetup: !success
                  }, success)
                })
            })
        })
        .catch(() => {
          showNotificationFailed(this.props.notificationsActions, 'Invalid TOTP code')
        })
        .finally(() => {
          this.setState({
            isSubmitting: false
          })
        })
    } else {
      showNotificationFailed(this.props.notificationsActions, 'You need to input the TOTP code')
      this.setState({
        isSubmitting: false
      })
    }
  }

  render() {
    const { totpCode, isSubmitting } = this.state

    return (
      <div>
        <Alert bsStyle="success">
          <p>Your authenticator code is:</p>
          <p style={{ wordWrap: 'break-word' }} >{totpCode}</p>
          { this.renderQrCode() }
          <p>Please copy it (or scan the QR code) into your authenticator application (i.e. Google Authenticator, 1Password)</p>
          <p>Please input the code supplied by the authenticator application below</p>
        </Alert>
        <FormGroup>
          <FormControl
            type="input"
            value={this.state.totpPassword}
            placeholder="TOTP Code"
            onChange={this.handleChange}
            name="totpPassword"
          />
        </FormGroup>
        <FormGroup>
          <Button type="button" bsSize="lg" className="fullwidth" onClick={this.completeTotpVerification} disabled={isSubmitting}>
            Save
          </Button>
        </FormGroup>
      </div>
    )
  }
}

MfaTotp.propTypes = {
  userActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  setMfaType: PropTypes.func.isRequired,
  mfaTypes: PropTypes.object.isRequired
}

export default MfaTotp
