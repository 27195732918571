import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, ControlLabel } from 'react-bootstrap'
import { renderValue, renderAddress } from '../shared/formHelper'

class NextOfKin extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { crewMember } = this.props
    let { nextOfKin } = crewMember

    if (!nextOfKin) {
      nextOfKin = {}
    }

    return (
      <div id="next-of-kin">
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Name</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(nextOfKin.name)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Relationship</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(crewMember.Relationship ? crewMember.Relationship.label : null)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Address</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderAddress(nextOfKin.address)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Phone</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(nextOfKin.phone)}
          </Col>
        </Row>
        <br className="visible-xs-block" />
        <Row>
          <Col xs={12} sm={3}>
            <ControlLabel>Email</ControlLabel>
          </Col>
          <Col xs={12} sm={9}>
            {renderValue(nextOfKin.email)}
          </Col>
        </Row>
      </div>
    )
  }
}

NextOfKin.propTypes = {
  crewMember: PropTypes.object.isRequired
}

export default NextOfKin
