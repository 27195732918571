import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  composeQuery,
  getFetchQuery,
  parseQuery, setUrlQueryString
} from '../../../helpers/shared/dataTable/dataTableHelper'
import { showNotificationFailed } from '../../../helpers/shared/notification'
import ManningPoolBadge from '../../../components/shared/dataTable/cells/manningPoolBadge'
import DataTable from '../../../components/shared/dataTable/dataTable'

class MembersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      totalCount: 0,
      pages: null,
      loading: true,
      parsedQuery: {}
    }
  }

  componentDidMount() {
    this.fetchMembers()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.compareSelectedCrews(prevProps.teamMembers.selectedCrews, this.props.teamMembers.selectedCrews)) {
      this.handleCrewsSelection()
    }
  }

  fetchMembers = (query = {}) => {
    const { teamsActions, teamId, location, history } = this.props
    const { page, limit, sort, orderBy, filter, selectedCrews } = this.props.teamMembers
    const { parsedQuery } = this.state

    this.setState({
      loading: true,
      parsedQuery: {
        ...parsedQuery,
        ...parseQuery(query)
      }
    }, () => {
      teamsActions.fetchTeamMembersById(teamId, {
        page,
        limit,
        sort,
        orderBy,
        filter,
        selectedCrews,
        ...getFetchQuery(this.state.parsedQuery, location.search)
      })
        .then(({ response }) => {
          setUrlQueryString(history, response.CrewMembers)
          this.setState({
            data: response.CrewMembers.entities,
            pages: Math.ceil(response.CrewMembers.totalCount / limit),
            totalCount: response.CrewMembers.totalCount,
            loading: false
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  compareSelectedCrews = (a, b) => {
    const oldCrews = Array.isArray(a) ? a : []
    const newCrews = Array.isArray(b) ? b : []

    return oldCrews.length === newCrews.length && oldCrews.every(crew => newCrews.includes(crew))
  }

  handleCrewsSelection = () => {
    this.fetchMembers({
      page: 0
    })
  }

  handleFilter = (text) => {
    this.fetchMembers(composeQuery(text))
  }

  handleClearFilter = () => {
    const { parsedQuery } = this.state
    this.setState({
      parsedQuery: {
        ...parsedQuery,
        filter: ''
      }
    }, () => this.fetchMembers())
  }

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/profiles/${rowInfo.original.PIN}`)
      }
    }
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch Contents')
    this.setState({
      loading: false
    })
  }

  render() {
    const { teamMembers } = this.props
    const { data, pages, loading, totalCount, parsedQuery: { filter } } = this.state
    const columns = [
      {
        Header: 'PIN',
        accessor: 'PIN'
      }, {
        Header: 'First name',
        accessor: 'firstname'
      }, {
        Header: 'Last name',
        accessor: 'lastname'
      }, {
        Header: 'Personal ID',
        accessor: 'personalId'
      }, {
        Header: 'Position',
        accessor: 'position'
      }, {
        Header: 'Nationality',
        id: 'nationality',
        accessor: row => (row.Nationality ? row.Nationality.label : '-'),
        sortable: false
      }, {
        Header: 'Crew',
        id: 'CrewId',
        accessor: row => (row.Crew ? <Link to={`/teams/${row.Crew.TeamId}/${row.Crew.id}`}>{row.Crew.name}</Link> : '-')
      }, {
        Header: 'Manning pool',
        id: 'ManningPoolId',
        accessor: row => <ManningPoolBadge manningPool={row.ManningPool} />
      }, {
        Header: 'Vessel',
        id: 'VesselId',
        accessor: row => (row.Vessel ? <Link to={`/vessels/${row.Vessel.id}`}>{row.Vessel.name}</Link> : '-')
      }
    ]

    return (
      <div id="contents-list">
        <h4>Members ({totalCount})</h4>
        <DataTable
          filter={filter}
          loading={loading}
          data={data}
          columns={columns}
          name="Crew Member"
          tableState={{
            ...teamMembers,
            pages
          }}
          isFiltered
          onFetch={this.fetchMembers}
          onFilter={this.handleFilter}
          onClearFilter={this.handleClearFilter}
          onRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

MembersList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired,
  teamMembers: PropTypes.object.isRequired,
  teamsActions: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired
}

export default MembersList
