import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { PageHeader, Row, Col, Panel, Button, ControlLabel, ButtonGroup } from 'react-bootstrap'
import { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import ApiRequest from '../../helpers/apiHelper'
import { UPDATE_AGENCY_SUCCESS } from '../../actions/agencies'
import { showNotificationSuccess, showNotificationFailed } from '../../helpers/shared/notification'
import DateCell from '../../components/shared/dataTable/cells/dateCell'
import DataTable from '../../components/shared/dataTable/dataTable'

class AgenciesShow extends React.Component {
  static columns = [
    {
      Header: 'Username',
      accessor: 'username',
      sortable: false
    }, {
      Header: 'Created at',
      id: 'createdAt',
      accessor: row => <DateCell date={row.createdAt} />,
      sortable: false
    }
  ]

  constructor(props) {
    super(props)
    this.state = {
      selectedManningPools: [],
      data: [],
      pages: 1,
      loading: true,
      orderBy: 'name',
      sort: 'asc'
    }
  }

  componentDidMount() {
    this.fetchAgencyData()
  }

  fetchAgencyData = () => {
    const { agenciesActions, match } = this.props

    this.setState({
      loading: true
    }, () => {
      agenciesActions.fetchAgencyById(match['*'])
        .then(({ response }) => {
          this.setState({
            data: response,
            loading: false,
            selectedManningPools: this.parseManningPools(response.ManningPools)
          })
        })
        .catch(() => this.showFetchError())
    })
  }

  parseManningPools = (manningPools = []) =>
    manningPools.map((manningPool) => ({
      value: manningPool.id,
      label: manningPool.name
    }))

  handleRowClick = (state, rowInfo) => {
    return {
      onClick: (event) => {
        if (event.target.href) {
          return
        }
        this.props.history(`/users/${rowInfo.original.id}`, { from: this.props.location.pathname })
      }
    }
  }

  handleAgencyUpdate = () => {
    const { match, agenciesActions, notificationsActions } = this.props
    const { selectedManningPools } = this.state

    const agencyId = match['*']
    const manningPoolsIds = selectedManningPools.map((c) => c.value)

    agenciesActions.updateAgency(agencyId, { manningPoolsIds }).then((res) => {
      if (res.type === UPDATE_AGENCY_SUCCESS) {
        showNotificationSuccess(notificationsActions, 'Updated Agency')
        this.fetchAgencyData()
      } else {
        showNotificationFailed(notificationsActions, 'Unable to update Agency')
      }
    })
  }

  showFetchError = () => {
    showNotificationFailed(this.props.notificationsActions, 'Unable to fetch agency data')
    this.setState({
      loading: false
    })
  }

  renderManningPoolResponsibilitySelect = () => {
    let allManningPools = []
    const loadManningPools = (params) =>
      ApiRequest(`/api/manning-pools?filter=${params}`).then((response) => {
        allManningPools = this.parseManningPools(response.json)
        return allManningPools
      })

    return (
      <div>
        <ControlLabel>Responsible for Manning pools </ControlLabel>
        <Row>
          <Col xs={12} sm={8}>
            <AsyncSelect
              name="form-field-crews"
              loadOptions={loadManningPools}
              isMulti
              filterOption={createFilter()}
              defaultOptions
              cacheOptions
              value={this.state.selectedManningPools}
              onChange={(selected) => this.setState({ selectedManningPools: selected })}
            />
          </Col>
          <Col xs={12} sm={4}>
            <ButtonGroup className="pull-right hidden-xs">
              <Button onClick={() => this.setState({ selectedManningPools: allManningPools })}>
                Select All
              </Button>
              <Button bsStyle="primary" onClick={this.handleAgencyUpdate}>
                Update
              </Button>
            </ButtonGroup>
            <ButtonGroup block vertical className="visible-xs-block m-t-25">
              <Button onClick={() => this.setState({ selectedManningPools: allManningPools })}>
                Select All
              </Button>
              <Button bsStyle="primary" onClick={this.handleAgencyUpdate}>
                Update
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <br />
      </div>
    )
  }

  renderInfo = () => {
    const { data } = this.state

    if (data.address) {
      return (
        <Row>
          <Col xs={12}>
            <Panel>
              <h4>Info</h4>
              <address>
                <div className="address-line">
                  <span className="address-label">Street:</span>
                  { data.address.street || <span className="not-available">not available</span> }
                </div>
                <div className="address-line">
                  <span className="address-label">Post code:</span>
                  { data.address.zip || <span className="not-available">not available</span> }
                </div>
                <div className="address-line">
                  <span className="address-label">City:</span>
                  { data.address.city || <span className="not-available">not available</span> }
                </div>
                <div className="address-line">
                  <span className="address-label">Country:</span>
                  { data.address.country || <span className="not-available">not available</span> }
                </div>
              </address>
              <hr />
              {this.renderManningPoolResponsibilitySelect()}
            </Panel>
          </Col>
        </Row>
      )
    }
  }

  render() {
    const { data, pages, loading, orderBy, sort } = this.state
    const users = data.Users || []

    return (
      <div id="agencies-show">
        <PageHeader>
          <Link to="/agencies">Agencies</Link> / {data.name}
        </PageHeader>
        { this.renderInfo() }
        <Row>
          <Col xs={12}>
            <Panel>
              <h4>Users</h4>
              <div className="details-table">
                <DataTable
                  loading={loading}
                  data={users}
                  columns={AgenciesShow.columns}
                  tableState={{
                    pages, page: 1, orderBy, sort
                  }}
                  onFetch={this.fetchAgencyData}
                  onRowClick={this.handleRowClick}
                  customEmptyResultsMsg="No users for this agency"
                />
              </div>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}

AgenciesShow.propTypes = {
  agenciesActions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  notificationsActions: PropTypes.object.isRequired,
  history: PropTypes.func,
  location: PropTypes.object.isRequired
}

export default AgenciesShow
