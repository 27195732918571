import {
  CREATE_NEW_USER_ROLE,
  CREATE_NEW_USER_ROLE_SUCCESS,
  CREATE_NEW_USER_ROLE_FAILURE,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_FAILURE,
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_USER_ROLES_FAILURE,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE
} from '../actions/userRoles'

const defaultState = {
  page: 1,
  limit: 40,
  offset: 0,
  orderBy: 'code',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null
}

export default function userRolesReducer(state = defaultState, action) {
  switch (action.type) {
    case CREATE_NEW_USER_ROLE:
    case FETCH_USER_ROLES:
    case UPDATE_USER_ROLE: {
      return { ...state, isFetching: true, didFetch: true }
    }
    case DELETE_USER_ROLE: {
      const { entities } = state
      delete entities[action.id]
      return { ...state, entities, isFetching: true, didFetch: true }
    }
    case CREATE_NEW_USER_ROLE_SUCCESS:
    case FETCH_USER_ROLES_SUCCESS:
    case UPDATE_USER_ROLE_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        error: null,
        entities: action.normalized.entities.userRoles || {}
      }
    }

    case CREATE_NEW_USER_ROLE_FAILURE:
    case DELETE_USER_ROLE_FAILURE:
    case FETCH_USER_ROLES_FAILURE:
    case UPDATE_USER_ROLE_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}
