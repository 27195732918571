import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_USER,
  CREATE_CUSTOMER_USER_SUCCESS,
  CREATE_CUSTOMER_USER_FAILURE,
  SET_CUSTOMER_USERS_PAGE,
  FETCH_ADONIS_CUSTOMERS,
  FETCH_ADONIS_CUSTOMERS_SUCCESS,
  FETCH_ADONIS_CUSTOMERS_FAILURE,
  RESET_CUSTOMERS_STATUS
} from '../actions/customers'

const defaultState = {
  page: 1,
  limit: 10,
  offset: 0,
  orderBy: 'name',
  sort: 'ASC',
  totalCount: 0,
  entities: {},
  isFetching: false,
  didFetch: false,
  error: null,
  // Customer Users Pagination
  customerUsersPage: 1,
  customerUsersLimit: 20
}

export default function customersReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CUSTOMERS:
    case FETCH_CUSTOMER:
    case CREATE_CUSTOMER_USER: {
      return { ...state, isFetching: true, didFetch: true }
    }

    case FETCH_CUSTOMER_SUCCESS:
    case CREATE_CUSTOMER_USER_SUCCESS: {
      return {
        ...state,
        didFetch: false,
        isFetching: false,
        error: null,
        entities: { ...state.entities, [action.response.id]: action.response }
      }
    }

    case FETCH_CUSTOMERS_SUCCESS: {
      const { page, limit, offset, orderBy, sort, totalCount, filter } = action.response

      return {
        ...state,
        page,
        limit,
        offset,
        orderBy,
        sort,
        totalCount,
        filter,
        isFetching: false,
        entities: action.normalized.entities.customers || {}
      }
    }

    case FETCH_CUSTOMERS_FAILURE:
    case FETCH_CUSTOMER_FAILURE:
    case CREATE_CUSTOMER_USER_FAILURE: {
      return {
        ...state,
        isFetching: false,
        didFetch: true,
        error: action.error
      }
    }

    case SET_CUSTOMER_USERS_PAGE: {
      const customerUsersPage = action.page
      return { ...state, customerUsersPage }
    }

    case FETCH_ADONIS_CUSTOMERS: {
      return { ...state, isFetching: true }
    }

    case FETCH_ADONIS_CUSTOMERS_SUCCESS: {
      return { ...state, isFetching: false }
    }

    case FETCH_ADONIS_CUSTOMERS_FAILURE: {
      return { ...state, isFetching: false, error: action.error }
    }

    case RESET_CUSTOMERS_STATUS: {
      return { ...state, didFetch: false, isFetching: false, error: null, entities: {} }
    }

    default: {
      return state
    }
  }
}
