import React from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from 'material-icons-react'
import { Modal, Button, Image } from 'react-bootstrap'

export const previewType = 'PREVIEW_MODAL'

class PreviewModal extends React.Component {
  constructor() {
    super()
    this.state = {
      show: true
    }
  }

  close = () => {
    this.setState({ show: false })
    this.props.onExited()
  }

  show = () => this.setState({ show: true })

  render() {
    const { blob } = this.props
    const src = window.URL.createObjectURL(blob)

    return (
      <Modal show={this.state.show} onHide={this.close} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{this.props.headline}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { src ? <Image src={src} style={{ width: '100%' }} /> : <span>Error: Can not preview Image</span> }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close} className="pull-left">Close</Button>
          <a href={src} download>
            <Button className="btn-download" bsStyle="primary">
              <MaterialIcon icon="publish" size="20" />
              Download
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    )
  }
}

PreviewModal.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  blob: PropTypes.object.isRequired,
  onExited: PropTypes.func.isRequired
}

PreviewModal.defaultProps = {
  headline: 'Preview'
}

export default PreviewModal
