import React from 'react'
import PropTypes from 'prop-types'
import NotificationSystem from 'react-notification-system'

class Notification extends React.Component {
  constructor() {
    super()
  }

  addNotification = () => {
    const { notification, resetNotifications } = this.props
    const actualNotification = notification

    delete actualNotification.showNotification

    if (this.notificationSystem) {
      this.notificationSystem.addNotification(actualNotification)
    }
    resetNotifications()
  }

  render() {
    const style = {
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '3rem 0',
          color: 'white',
          borderRadius: '5px',
          fontSize: '1rem',
          borderTop: 'none'
        },
        success: {
          background: '#90CC4E'
        },
        error: {
          background: '#FF4000'
        },
        warning: {
          background: '#FFBF42'
        },
        info: {
          background: '#528CC5'
        }
      },
      MessageWrapper: {
        DefaultStyle: {
          margin: '0 0 0 1rem',
          display: 'inline-block'
        }
      }
    }
    if (this.props.notification.showNotification) {
      this.addNotification()
    }

    return <NotificationSystem style={style} ref={el => (this.notificationSystem = el)} />
  }
}

Notification.propTypes = {
  resetNotifications: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired
}

export default Notification
